import React from "react";
import styled from "styled-components";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div<{
  started?: boolean;
  ended?: boolean;
  detail?: boolean;
  landscape?: boolean;
}>`
  width: 60px;
  height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2px;
  padding: 12px;
  font-size: 10px;
  ${p => p.ended ? `
    background: url(/images/tournament_status_gray_bg.svg);
  ` : `
    background: url(/images/${p.started ? 'tournament_status_blue_bg' : 'tournament_status_green_bg'}.svg);
  `}
  background-size: contain;
  background-repeat: no-repeat;

  ${p => p.detail && `
    width: 68px;
    height: 68px;
    
    @media ${MEDIA_DESKTOP} {
      width: 80px;
      height: 80px;
      font-size: 12px
    }
  `};

  ${p => p.landscape && `
    width: auto;
    height: auto;
    background: none;
    flex-direction: row;
    font-size: 12px;
    justify-content: flex-start;
    padding: 0;
    
    
    > div {
      color: ${p.ended ? 'rgba(255, 255, 255, 0.50)' : (p.started ? '#85C4FF' : '#7FEB4C')} !important;
      padding: 0 4px;
      
      &.status-text {
        font-weight: 600 !important;
        padding-right: 8px;
      }
      
      &:not(:last-child) {
        border-right: 1px solid #2D2D2D;
      }
    }
  `};

  > div {
    color: #FFF;

    &.status-text {
      font-weight: 400;
    }

    &.status-ing {
      background: linear-gradient(180deg, #FFF 0%, #85C4FF 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &.status-register {
      background: linear-gradient(180deg, #FFF 0%, #7FEB4C 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    }
    
    &.border {
      width: 36px;
      min-height: 1px;
      height: 1px;
      background-color: #2D2D2D;
      margin: 2px 0 4px 0;
    }

    &.clock {
      font-weight: 600;

      @media ${MEDIA_DESKTOP} {
        font-size: 12px;
      }

      > .label {
        color: #FFF;
        transform: scale(0.8);
        white-space: nowrap;

        @media ${MEDIA_DESKTOP} {
          font-size: 12px;
          font-weight: 400;
          transform: none;
        }
      }
    }
  }
  
`;

function TournamentStatus(
  {
    detail,
    started,
    ended,
    timerText,
    landscape,
  }: {
    detail?: boolean;
    started?: boolean;
    ended?: boolean;
    timerText?: string;
    landscape?: boolean;
  }
) {
  const {t} = useTranslation();


  return <Wrapper ended={ended} className="tournament-status" started={started} detail={detail} landscape={landscape}>
    <div className={"status-text "+(ended ? '' : (started ? 'status-ing' : 'status-register'))}>
      {ended ? t('종료됨') : (started ? t('진행중') : t('등록중'))}
    </div>
    {
      (timerText && detail && !ended) && <>
        <div className="border"/>
      </>
    }
    {
      timerText && (
        <div className="clock">
          {
            detail && !ended && (
              <div className="label">
                {
                  started ? t('등록마감까지') : t('시작까지')
                }
              </div>
            )
          }
          {!ended && timerText}
        </div>
      )
    }
  </Wrapper>;
}

export default TournamentStatus;
