import styled from "styled-components";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import Game from "./Game";
import {useEffect, useMemo, useRef, useState} from "react";
import {produce} from "immer";
import useScreenOrientation, {MEDIA_DESKTOP, MEDIA_MOBILE_MINI} from "../hooks/useScreenOrientation";
import {ROOM_TYPE, RoomInfo} from "../dataset";
import MultiTableButton, {MultiTableAddButton} from "../components/game/MultiTableButton";
import useLoadGame from "../hooks/useLoadGame";
import {useTranslation} from "react-i18next";
import Const from "../Const";
import {useHistory} from "react-router-dom";
import Flex from "../components/common/Flex";
import {requestJoinRoom, requestRoomInfo} from "../api";
import RightDrawer from "../components/common/RightDrawer";
import GameHistory from "../components/game/GameHistory";
import ModalContainer from "../components/common/ModalContainer";
import ProfileModal from "../components/ProfileModal";
import SettingModal from "../components/SettingModal";
import EmoticonSelector from "../components/game/EmoticonSelector";
import BuyInModal from "../components/BuyInModal";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {userIdSelector} from "../recoil/MyInfo";
import VaultBuyInModal from "../components/VaultBuyInModal";
import {setAckListener as setRoomPotUpdateListener} from "../api/from_server_game_roomPotUpdate";
import {setAckListener as setMyRoomBetInfoListener} from "../api/from_server_game_myRoomBetInfo";
import {emoticonState} from "../recoil/Game";
import {globalLoadingState} from "../recoil/Loading";
import {t} from "i18next";

const GameTransitionWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  div.wrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .route-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    transition: all 0.3s ease-in-out;
  }

  .route-section.transition-wrap-enter-done {
    position: relative;
  }

  .transition-wrap {
    position: absolute;
    left: 15px;
    right: 15px;
    transition: all 0.3s ease-in-out;
  }

  .route-section {
    height: 100svh;
  }

  .transition-group.left .transition-wrap-enter {
    position: absolute;
    opacity: 0;
    transform: translate3d(0px, 0, 0);
  }

  .left .transition-wrap-enter-active {
    opacity: 1;
    transform: translate3d(100%, 0, 0);
    transition: opacity 150ms, transform 1.5s;
  }

  .left .transition-wrap-exit {
    position: absolute;
    opacity: 1;
    transform: translate3d(-100%, 0, 0);
  }

  .left .transition-wrap-exit-active {
    position: absolute;
    opacity: 0;
    //transform: scale(0.9);
    transform: translate3d(-100%, 0, 0);
  }

  // right

  .transition-group.right .transition-wrap-enter {
    position: absolute;
    opacity: 0;
    transform: translate3d(0px, 0, 0);
  }

  .right .transition-wrap-enter-active {
    opacity: 1;
    transform: translate3d(-100%, 0, 0);
    transition: opacity 150ms, transform 1.5s;
  }

  .right .transition-wrap-exit {
    position: absolute;
    opacity: 1;
    transform: translate3d(100%, 0, 0);
  }

  .right .transition-wrap-exit-active {
    position: absolute;
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  > .section {
    width: 100%;
    height: 100%;
  }

  .page-wrap {
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 5;
    width: 100%;
    height: 100%;
  }

`

const Header = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  padding: 20px;
  z-index: 2;
  gap: 8px;
  @media ${MEDIA_MOBILE_MINI} {
    padding: 8px 20px;
  }

  @media ${MEDIA_DESKTOP} {
    padding: 30px 40px;
  }

  #roomInfo {
    > div {
      margin-left: 16px;
      margin-top: 0;
      position: initial;
      top: 0;
      left: 0;
    }
  }
`;

const LeaveButton = styled.button`
  color: white;
  font-size: 12px;
  padding: 2px 8px 2px 2px;
  border-radius: 30px;
  background: #181A1D;
  mix-blend-mode: darken;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;

  @media ${MEDIA_DESKTOP} {
    font-size: 12px;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }
`;

const OptionButton = styled.button`
  color: white;
  width: 28px;
  height: 28px;

  font-size: 10px;
  padding: 8px 13px;
  border-radius: 15px;
  background: #181A1D;
  mix-blend-mode: darken;
  cursor: pointer;
  position: relative;

  @media ${MEDIA_DESKTOP} {
    width: 36px;
    height: 36px;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export interface RoomExtraInfoInterface {
  roomId: number;
  handleLeave: () => void;
  StatusBoardComponent: JSX.Element;
  handleRealTimePrize: () => void;
  handleShowOption: () => void;
  handleSendEmoticon: (emoticonId?: number) => void;
  handleBuyIn: (amount: number, vaultId:number) => void;
  handleVaultBuyInOpen: () => void;
  handleVaultBuyIn: (vaultId: number) => void;
  handleRebuyin: () => void;
}

interface MyRoomBetInfoInterface {
  roomInfo: RoomInfo;
  leftSec: number;
  hands: number[];
  isPlaying: boolean;
  leftSecInterval?: NodeJS.Timer;
}

const GameTransition = () => {
  const history = useHistory();
  const orientation = useScreenOrientation();
  const {t} = useTranslation()
  const setGlobalLoading = useSetRecoilState(globalLoadingState);
  const [gameId, setGameId] = useState(-1)
  const [myRoomsInfoList, setMyRoomsInfoList] = useState<MyRoomBetInfoInterface[]>([]);
  const [roomExtraInfo, setRoomExtraInfo] = useState<RoomExtraInfoInterface>({
    roomId: -1,
    handleLeave: () => {
    },
    StatusBoardComponent: <></>,
    handleRealTimePrize: () => {
    },
    handleShowOption: () => {
    },
    handleSendEmoticon: () => {
    },
    handleBuyIn: () => {
    },
    handleVaultBuyInOpen: () => {
    },
    handleVaultBuyIn: () => {
    },
    handleRebuyin: () => {
    }
  });
  const searchParams = new URLSearchParams(window.location.search);
  const currentRoomId = useMemo<number>(() => {
    return Number(searchParams.get("id")!);
  }, [searchParams]);
  const userId = useRecoilValue(userIdSelector);
  const loadGameData = useLoadGame()
  const {
    room,
    players,
    myInfo,
    blind,
    myRooms,
    refreshMyRooms
  } = loadGameData;

  //Game State
  const setEmoticon = useSetRecoilState(emoticonState);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [profileUserId, setProfileUserId] = useState<number>(-1);
  const myWaitBuyin = room && myInfo ? room.waitBuyins.find(v => v.userId == myInfo.userId) : null;
  const [showOptionModal, setShowOptionModal] = useState<boolean>(false);
  const [showEmoticonSelector, setShowEmoticonSelector] = useState<boolean>(false);
  let totalBuyin = 0;
  if (myInfo && room) {
    totalBuyin += myInfo.stackSize;
    totalBuyin += room.waitBuyins.find(v => v.userId == myInfo.userId)?.amount || 0;
  }
  const [buyInSeat, setBuyInSeat] = useState<number>(-1);
  const [openVaultBuyIn, setOpenVaultBuyIn] = useState<boolean>(false);

  const [transitionDirection, setTransitionDirection] = useState<'left' | 'right'>('left');

  const onClickMoveRoom = async (roomId: number) => {
    if (!room) return;
    console.log('onClickMoveRoom', roomId, room.roomId)
    if (roomId == room.roomId) {
      return;
    }
    //emoticon init
    setEmoticon({})

    //myRoomsInfoList에서, 현재 방보다 오른쪽에 있으면 right, 왼쪽에 있으면 left
    const currentIdx = myRoomsInfoList.findIndex(x => x.roomInfo.roomId === room.roomId);
    const targetIdx = myRoomsInfoList.findIndex(x => x.roomInfo.roomId === roomId);
    if (currentIdx < targetIdx) {
      setTransitionDirection('right')
    } else {
      setTransitionDirection('left')
    }

    setGameId(roomId);
    await requestJoinRoom(roomId)

    history.push(`/game?id=${roomId}`)
    //await new Promise(r=>setTimeout(r, 300))

  }

  useEffect(() => {
    (async () => {
      if(currentRoomId > 0){
        setGlobalLoading(true);
        await requestRoomInfo(currentRoomId);
        setGlobalLoading(false);
      }
    })();
  }, [currentRoomId]);

  useEffect(() => {
    setMyRoomsInfoList(prev => {
      return myRooms ? myRooms.map(room => {
        const idx = prev.findIndex(x => x.roomInfo.roomId === room.roomId);
        if (idx >= 0) {
          return {
            roomInfo: room,
            leftSec: prev[idx].leftSec,
            hands: room.hands,
            isPlaying: (room as any).data.isStarted
          }
        } else {
          return {
            roomInfo: room,
            leftSec: 0,
            hands: room.hands,
            isPlaying: (room as any).data.isStarted
          }
        }
      }) : [];
    })
  }, [myRooms]);

  useEffect(() => {
    return () => {
      //0보다 작은 leftSec의 인터벌 삭제 및 undefined 처리
      setMyRoomsInfoList(produce((d) => {
        d.forEach(v => {
          if (v.leftSecInterval && v.leftSec <= 0) {
            clearInterval(v.leftSecInterval);
            v.leftSecInterval = undefined;
          }
        })
      }));
    }
  }, [myRoomsInfoList]);

  useEffect(() => {
    setRoomPotUpdateListener(async (data: {
      roomId: number; pots: number[]; isPlaying: boolean; currentUserId: number;
    }) => {
      console.log('setRoomPotUpdateListener', data)
      const idx = myRoomsInfoList.findIndex(x => x.roomInfo.roomId === data.roomId);
      setMyRoomsInfoList(produce((d) => {
        if (idx >= 0) {
          d[idx].roomInfo.pots = data.pots;
          d[idx].isPlaying = data.isPlaying;
          if(data.currentUserId !== userId) {
            d[idx].leftSec = 0;
          }
          if(!data.isPlaying){
            //게임이 끝나면 핸드 초기화
            console.log(' >>>>  hands clear', data.roomId)
            d[idx].hands = [];
          }
        }
      }))
    })
    setMyRoomBetInfoListener(async (data: {
      roomId: number,
      leftSec: number,
      hands: number[]
    }) => {
      //해당 room으로 새로운 leftSec을 받았을때, 기존 interval 삭제 후 새로운 interval 생성
      const idx = myRoomsInfoList.findIndex(x => x.roomInfo.roomId === data.roomId);

      if (idx >= 0) {

      /*  let newInterval = myRoomsInfoList[idx].leftSecInterval;
        if (!newInterval) {
          //1초씩 감산
          newInterval = setInterval(() => {
            setMyRoomsInfoList(produce((d) => {
              if(d[idx].leftSec <= 0){
                clearInterval(d[idx].leftSecInterval);
              }else{
                d[idx].leftSec -= 1;
              }
            }))
          }, 1000)
        }*/
        setMyRoomsInfoList(produce((d) => {
          d[idx].leftSec = data.leftSec;
          d[idx].hands = data.hands;
          //d[idx].leftSecInterval = newInterval;
          d[idx].leftSecInterval = undefined;
        }))
      }
    })
    return () => {
      setRoomPotUpdateListener(null)
      setMyRoomBetInfoListener(null)
    }
  }, [myRoomsInfoList]);

  if (!room || room.roomId !== currentRoomId) {
    return <></>
  }


  return <GameTransitionWrapper>
    <Header>
      <LeaveButton onClick={() => {
        roomExtraInfo?.handleLeave()
      }}><img src="/images/ic_game_exit.svg"/>{t('나가기')}</LeaveButton>

      <>
        {myRoomsInfoList.map((v: MyRoomBetInfoInterface, i) => {
          const totalPot = v.roomInfo.pots ? v.roomInfo.pots.reduce((a, b) => a + b, 0) : 0
          //console.log('myRoomsInfoList',v,  v.leftSec, v.roomInfo.roomId, v.hands)
          const me = (v.roomInfo as any).data.players.find((v: any) => {
            return v !== null && v.id === userId.toString();
          })
          let isPlaying = v.isPlaying;
          if(me){
            if(me.blindWait){
              isPlaying = false;
            }
          }
          console.log('me', me.blindWait, v.roomInfo.roomId)
          return <MultiTableButton
            key={i}
            isPlaying={isPlaying}
            selected={v.roomInfo.roomId === currentRoomId}
            showPot={room ? room?.currentRound !== 'pre-flop' && v.roomInfo.pots && v.roomInfo.pots.length > 0 && totalPot > 0 : false}
            pot={v.roomInfo.pots ? v.roomInfo.pots.reduce((a, b) => a + b, 0) : undefined}
            hand={v.hands} //서버에서 본인 핸드 내려줘야함
            showProgress={v.leftSec > 0} //본인차례인지 체크해서 boolean
            progress={(v.leftSec / Const.ACTION_LIMIT_TIME) * 100} //0~100까지 퍼센트 내려줌
            onClick={() => onClickMoveRoom(v.roomInfo.roomId)}/>
        })}
      </>
      <MultiTableAddButton isLighting={false} selected={false} onClick={()=>{
        history.replace("/");
      }}>
        <img src='/images/plus.svg'/>
      </MultiTableAddButton>
      {
        <Flex>
          {
            orientation === 'landscape' && (
              roomExtraInfo.StatusBoardComponent
            )
          }
        </Flex>
      }
      {(room && room.type == ROOM_TYPE.TOURNAMENT) ? <OptionButton onClick={() => {
        roomExtraInfo?.handleRealTimePrize()
      }}><img src="/images/ic_prize_chip.svg" style={{width: "21px"}}/></OptionButton> : null}
      <OptionButton onClick={() => {
        setShowHistory(true)
      }}><img src="/images/ic_history.svg"/></OptionButton>
      <OptionButton onClick={() => {
        roomExtraInfo?.handleShowOption()
      }}><img src="/images/ic_option.svg"/></OptionButton>
    </Header>
    <div className="wrap">
      <TransitionGroup className={`transition-group ${transitionDirection}`}>
        <CSSTransition
          key={gameId}
          timeout={{
            enter: 150,
            exit: 150,
          }}
          classNames={"transition-wrap"}
        >
          <div className="route-section fade">
            <Game
              gameData={loadGameData}
              setShowOptionModal={setShowOptionModal}
              setBuyInSeat={setBuyInSeat}
              buyInSeat={buyInSeat}
              setOpenVaultBuyIn={setOpenVaultBuyIn}
              setProfileUserId={setProfileUserId}
              setShowEmoticonSelector={setShowEmoticonSelector}
              showEmoticonSelector={showEmoticonSelector}
              onInit={
                (
                  {
                    roomId,
                    handleLeave,
                    handleRealTimePrize,
                    StatusBoardComponent: statusBoard,
                    handleShowOption,
                    handleSendEmoticon,
                    handleBuyIn,
                    handleVaultBuyInOpen,
                    handleVaultBuyIn,
                    handleRebuyin
                  }
                ) => {
                  setRoomExtraInfo({
                    roomId,
                    handleLeave,
                    handleRealTimePrize,
                    handleShowOption,
                    StatusBoardComponent: statusBoard,
                    handleSendEmoticon,
                    handleBuyIn,
                    handleVaultBuyInOpen,
                    handleVaultBuyIn,
                    handleRebuyin
                  })
                }}
              onRefreshMyRooms={()=>{
                refreshMyRooms();
              }}
                />
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
    <RightDrawer
      opened={showHistory}
      onOpen={() => setShowHistory(true)}
      onClose={() => setShowHistory(false)}
    >
      <GameHistory
        groupId={room.groupId}
        roomId={currentRoomId}
        currentRound={room?.currentRound}
        handNumber={room?.handNumber}
        maxTableMember={room?.groupData.maxTableMember}
        onClose={() => setShowHistory(false)}
        isOpen={showHistory}
      />
    </RightDrawer>
    <ModalContainer show={profileUserId !== -1} onBackdropClick={() => setProfileUserId(-1)}>
      <ProfileModal
        roomType={room.type}
        bb={blind.big}
        groupId={room.groupId}
        userId={profileUserId}
        onClose={() => setProfileUserId(-1)}
        onClickRebuyin={(room.type === ROOM_TYPE.TOURNAMENT) ? undefined : () => roomExtraInfo.handleRebuyin()}
        waitRebuyin={myWaitBuyin ? myWaitBuyin.amount : 0}
      />
    </ModalContainer>
    <ModalContainer show={showOptionModal} onBackdropClick={() => setShowOptionModal(false)}>
      <SettingModal roomType={room.type} onClose={() => setShowOptionModal(false)}/>
    </ModalContainer>
    <ModalContainer show={showEmoticonSelector} noDim onBackdropClick={() => setShowEmoticonSelector(false)}>
      <EmoticonSelector onClose={roomExtraInfo.handleSendEmoticon}/>
    </ModalContainer>
    {
      room.type === ROOM_TYPE.RING && (
        <ModalContainer show={buyInSeat !== -1} onBackdropClick={() => setBuyInSeat(-1)}>
          <BuyInModal
            waitBuyin={!!(players && players.find((v: any) => v && v.userId === userId))}
            currentStack={totalBuyin}
            groupId={room.groupId}
            roomId={room.roomId}
            bigBlind={blind.big}
            minBuyIn={room.groupData.minBuyin}
            maxBuyIn={room.groupData.maxBuyin - totalBuyin}
            onClickBuyIn={roomExtraInfo.handleBuyIn}
            onClickReentryOpen={roomExtraInfo.handleVaultBuyInOpen}
          />
        </ModalContainer>
      )
    }
    {
      <ModalContainer show={openVaultBuyIn} onBackdropClick={() => setOpenVaultBuyIn(false)}>
        <VaultBuyInModal
          groupId={room.groupId}
          roomId={room.roomId}
          onClickBuyIn={roomExtraInfo.handleVaultBuyIn}
        />
      </ModalContainer>
    }

  </GameTransitionWrapper>
}
export default GameTransition;
