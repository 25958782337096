import styled from "styled-components";
import {Menu} from "./index";

const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0  20px 38px;


  > .top-menu-wrapper {
    margin-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > .title {
      color: #FFF;
      text-align: center;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;
    }

    > .wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;

      > .like {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }

      > .exit {
        cursor: pointer;
        padding: 6px 16px;
        border-radius: 8px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(116deg, #2F99FB 23.2%, #2464C3 98.42%);
        color: #FFF;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.2px;
      }
    }
  }
  >.menu-grid {
    margin-top: 12px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    >.item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 4px;
      padding: 8px;
      cursor: pointer;
      border-radius: 8px;
      background: #1F1F1F;
      >img {
        width: 48px;
        height: 48px;
      }
      >.title {
        color: #FFF;
        text-align: center;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.2px;
      }
    }
  }
  >.bottom-menu-row {
    margin-top: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    >.item {
      padding: 4px 0;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 2px;
      > img {
        width: 24px;
        height: 24px;
      }
      >.title {
        color: #FFF;
        text-align: center;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.2px;
      }
    }
  }
`

interface MainProps {
 title: string;
  onSelectMenu: (menu: Menu) => void;
}
const Main = ({title, onSelectMenu}:MainProps) => {
  return <MainWrapper>
    <div className='top-menu-wrapper'>
      <div className='title'>{title}</div>
      <div className='wrapper'>
        <img className='like' src='/images/baccarat/Heart.svg'/>
        <div className='exit'>나가기</div>
      </div>
    </div>
    <div className='menu-grid'>
      <div className='item' onClick={()=>onSelectMenu('lobby')}>
        <img src='/images/baccarat/Menu/Lobby.svg'/>
        <div className='title'>로비</div>
      </div>
      <div className='item' onClick={() => onSelectMenu('chat')}>
        <img src='/images/baccarat/Menu/Chat.svg'/>
        <div className='title'>채팅</div>
      </div>
      <div className='item' onClick={() => onSelectMenu('support')}>
        <img src='/images/baccarat/Menu/Support.svg'/>
        <div className='title'>지원</div>
      </div>
      <div className='item' onClick={() => onSelectMenu('history')}>
        <img src='/images/baccarat/Menu/History.svg'/>
        <div className='title'>게임 기록</div>
      </div>
      <div className='item' onClick={() => onSelectMenu('settings')}>
        <img src='/images/baccarat/Menu/Settings.svg'/>
        <div className='title'>설정</div>
      </div>
      <div className='item' onClick={() => onSelectMenu('howto')}>
        <img src='/images/baccarat/Menu/How%20to.svg'/>
        <div className='title'>플레이 방법</div>
      </div>
    </div>
    <div className='bottom-menu-row'>
      <div className='item' onClick={() => onSelectMenu('payout')}>
        <img src='/images/baccarat/Payout.svg'/>
        <div className='title'>지급금 & 한도</div>
      </div>
      <div className='item' onClick={() => onSelectMenu('gameverified')}>
        <img src='/images/baccarat/Game%20verified.svg'/>
        <div className='title'>임의 게임 검증</div>
      </div>
    </div>
  </MainWrapper>
}

export default Main;
