import {atom} from "recoil";

export interface DialogProps {
  title?: string;
  text?: string;
  component?: any;
  confirm?: boolean;
  onConfirm?: (closeFn?: () => void) => void;
  onCancel?: () => void;
  confirmText?: string;
  cancelText?: string;
  disableBackdropClick?: boolean;
  disableCloseButton?: boolean;
  disableAutoClose?: boolean;
  confirmDisable?: boolean;
  popupId?: string;
}

export interface DialogState extends DialogProps {
  id: number;
  visibility: boolean;
  onBackdropClick?: () => void;
  disableCloseButton?: boolean;
}

export const dialogState = atom<DialogState[]>({
  key: 'dialogState',
  default: []
});
