import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import produce from 'immer';
import moment from "moment";
import styled, {keyframes} from 'styled-components';
import { numberToCardData } from "../cardUtils"
import {
  requestBuyin,
  requestGetHandCuttingList,
  requestGetMyTournamentRank,
  requestGetTournamentBuyinCount,
  requestLeaveRoom,
  requestRequestCardOpen,
  requestMyCards,
  requestMyGroupVaultList,
  requestMyInfo,
  requestMyStatusInRoom,
  requestRebuyinTournament,
  requestRequestEmoji,
  requestRoomInfo,
  requestSetBlindWait,
  requestTryBet,
  requestGetTournamentPrize,
  requestToggleEmoticonBan,
  requestJoinRoom,
  requestVaultBuyin,
  requestRequestExchangeRate
} from '../api';
import {useHistory} from 'react-router-dom';
import {setAckListener as setCardOpenEndListener} from "../api/from_server_game_cardOpenEnd";
import {setAckListener as setCardOpenListener} from "../api/from_server_game_cardOpen";
import {setAckListener as setStartGameShuffleListener} from "../api/from_server_game_startGameShuffle";
import {setAckListener as setRequestBetListener} from "../api/from_server_game_requestBet";
import {setAckListener as setGameWinnerListener} from "../api/from_server_game_gameWinner";
import {setAckListener as setChangeRoundListener} from '../api/from_server_game_changeRound';
import {setAckListener as setEmitAfterBetListener} from "../api/from_server_game_emitAfterBet";
import {setAckListener as setOpenCommunityCardsListener} from "../api/from_server_game_openCommunityCards";
import {setAckListener as setLeaveJoinRoomSocketListener} from "../api/from_server_game_leaveJoinRoomSocket";
import {setAckListener as setRefreshPlayersInfoListener} from "../api/from_server_game_refreshPlayersInfo";
import {setAckListener as setAllInShowdownListener} from "../api/from_server_game_allInShowdown";
import {setAckListener as setTableCleanUpListener} from "../api/from_server_game_tableCleanUp";
import {setAckListener as setCollectAnteListener} from "../api/from_server_game_collectAnte";
import {setAckListener as setCollectRakeListener} from "../api/from_server_game_collectRake";
import {setAckListener as setSendEmojiListener} from "../api/from_server_game_sendEmoji";
import {
  BET_TYPE,
  CARD_INFO,
  emitAfterBetModel,
  GamePlayer,
  PlayersBettings,
  RAKE_TYPE,
  requestBetModel,
  ROOM_JOIN_STATUS,
  ROOM_STATUS,
  ROOM_TYPE,
  RoomInfo,
  winnerModel
} from '../dataset';
import InGameButton from "../components/common/InGameButton";
import ModalContainer from "../components/common/ModalContainer";
import BuyInModal from "../components/BuyInModal";
import useGameLayout from "../hooks/useGameLayout";
import useLoadGame, { MyRoomInfoInterface } from "../hooks/useLoadGame";
import Player from "../components/game/Player";
import useDialog from "../hooks/useDialog";
import {numCardsByRank, toCardString, wait} from "../utils/common";
import PokerCard from "../components/game/PokerCard";
import FieldPots from "../components/game/FieldPots";
import PlayerPot from "../components/game/PlayerPot";
import ActionButtons from "../components/game/ActionButtons";
import ProfileModal from "../components/ProfileModal";
import GameHistory from "../components/game/GameHistory";
import RightDrawer from "../components/common/RightDrawer";
import useScreenOrientation, {MEDIA_DESKTOP, MEDIA_MOBILE_MINI} from "../hooks/useScreenOrientation";
import {parseDatetime} from "../constants/moment";
import {calcBlindUpTime, calcElapsedForRound, calcLevel, calcPlayTime, determineRestTime} from "../utils/tournament";
import SettingModal from "../components/SettingModal";
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {
  myInfoState,
  userIdSelector
} from "../recoil/MyInfo";
import Flex from "../components/common/Flex";
import StatusBoard from "../components/game/StatusBoard";
import useWinningRate from "../hooks/useWinningRate";
import {globalLoadingState} from "../recoil/Loading";
import {connectionState} from "../recoil/Connection";
import {playSFX, Sounds, stopSound} from "../utils/sound";
import useOnResume from "../hooks/useOnResume";
import LeaveSettlement from "../components/game/LeaveSettlementInRoom";
import EmoticonSelector from "../components/game/EmoticonSelector";
import {
  betLeftSecState,
  ceremonyCardsState,
  ceremonyRankingState,
  communityCardsState,
  emoticonState,
  myCardsState,
  shiningCardsState
} from "../recoil/Game";

// @ts-ignore
import {Hand} from "pokersolver";
import {useTranslation} from "react-i18next";
import useBGM from "../hooks/useBGM";
import {moveChipsSound} from "../utils/chip";
import TournamentRebuyinDialog from '../components/game/TournamentRebuyinDialog';
import { gameOptionState } from '../recoil/GameOption';
import MultiTableButton from "../components/game/MultiTableButton";
import VaultBuyInModal from '../components/VaultBuyInModal';
import Const from "../Const";
import { currencyToChip, UserCurrencyMark, UserToChip, UserToCurrency } from '../conversionChip';
import {RoomExtraInfoInterface} from "./GameTransition";
import { getShortLang } from './Lobby';

const OpenCardButton = styled.div<{ all: boolean, disabled: boolean }>`
  //disabled일때 dim, black opacity 0.6
  ${p => p.disabled ? `
  cursor: not-allowed;
  ` : `
  cursor: pointer;
  `}
  position: relative;

  > .dim {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
    border-radius: 8px;
    transition: all 0.3s ease-out;
    ${p => p.disabled ? `
      opacity: 1;
    ` : `
     opacity: 0;
    `}
  }

  flex-shrink: 0;
  flex-basis: 32%;
  flex-grow: 0;
  ${p => p.all ? `
    padding: 8px 4px;
  ` : `
    padding: 8px 12px;
  `};
  height: 51px;
  @media ${MEDIA_DESKTOP} {
    padding: 8px 20px;
    height: 60px;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-color: white;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  background-image: -moz-linear-gradient(90deg, rgb(31, 32, 33) 0%, rgb(41, 42, 42) 59%, rgb(47, 49, 48) 61%, rgb(53, 55, 53) 71%, rgb(67, 69, 69) 92%, rgb(53, 55, 53) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(31, 32, 33) 0%, rgb(41, 42, 42) 59%, rgb(47, 49, 48) 61%, rgb(53, 55, 53) 71%, rgb(67, 69, 69) 92%, rgb(53, 55, 53) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(31, 32, 33) 0%, rgb(41, 42, 42) 59%, rgb(47, 49, 48) 61%, rgb(53, 55, 53) 71%, rgb(67, 69, 69) 92%, rgb(53, 55, 53) 100%);


  > .text {
    color: white;
    font-weight: 800;
    ${p => p.all ? `
      font-size: 18px;
      @media screen and (max-width: 389px) {
        font-size: 14px;
      }  
    ` : `
      font-size: 18px;  
    `} @media ${MEDIA_DESKTOP} {
    font-size: 24px;
  } position: relative;

    > img {
      ${p => p.disabled ? `` : `display: none;`}
      left: 50%;
      transform: translateX(-50%);
      top: -10px;
      position: absolute;
      width: 12px;
      object-fit: contain;
    }
  }

  > .card-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2px;
    @media ${MEDIA_DESKTOP} {
      gap: 4px;
    }

    > .card-wrapper {
      display: flex;
      flex-direction: row;
      background: white;
      border-radius: 4px;
      padding: 2px 4px;
      align-items: center;
      justify-content: center;
      ${p => p.all ? `
      gap: 0px;
      ` : `
      gap: 2px;
      `} @media ${MEDIA_DESKTOP} {
      gap: 2px;
    }

      > img {
        object-fit: contain;
        width: 12px;
        ${p => p.all ? `
          @media screen and (max-width: 389px) {
            width: 9px;
          }
        ` : `
          width: 12px;
        `} @media ${MEDIA_DESKTOP} {
        width: 20px;
      }
      }

      > span {
        color: black;
        font-weight: 700;
        ${p => p.all ? `
        font-size: 14px;
        @media screen and (max-width: 389px) {
            font-size: 12px;
        }
        ` : `
        font-size: 14px;
        `} @media ${MEDIA_DESKTOP} {
        font-size: 22px;
      }
      }
    }
  }
`

const EmojiButtonWrapper = styled.div<{ drawerOpen: boolean }>`
  position: fixed;
  width: fit-content;
  left: 8px;
  bottom: 64px;
  ${p => p.drawerOpen ? `
  
  ` : `
  z-index: 2;
  `} @media ${MEDIA_DESKTOP} {
  left: 24px;
  bottom: 100px;
}

  .button-style {
    border-color: white;
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;
    background-image: -moz-linear-gradient(90deg, rgb(31, 32, 33) 0%, rgb(41, 42, 42) 59%, rgb(47, 49, 48) 61%, rgb(53, 55, 53) 71%, rgb(67, 69, 69) 92%, rgb(53, 55, 53) 100%);
    background-image: -webkit-linear-gradient(90deg, rgb(31, 32, 33) 0%, rgb(41, 42, 42) 59%, rgb(47, 49, 48) 61%, rgb(53, 55, 53) 71%, rgb(67, 69, 69) 92%, rgb(53, 55, 53) 100%);
    background-image: -ms-linear-gradient(90deg, rgb(31, 32, 33) 0%, rgb(41, 42, 42) 59%, rgb(47, 49, 48) 61%, rgb(53, 55, 53) 71%, rgb(67, 69, 69) 92%, rgb(53, 55, 53) 100%);

  }

  > .button {
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media ${MEDIA_DESKTOP} {
      width: 60px;
      height: 60px;
    }

    > img {
      width: 28px;
      height: 28px;
      @media ${MEDIA_DESKTOP} {
        width: 40px;
        height: 40px;
      }
    }
  }

  > .emoji-wrapper {
    background: black;
    position: absolute;
    z-index: 2;
    bottom: 46px;
    left: 0px;
    border: 1px solid white;
    border-radius: 6px;
    padding: 4px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 4px;
    @media ${MEDIA_DESKTOP} {
      bottom: 80px;
    }

    > .emoji {
      border-color: rgb(117, 119, 117);
      cursor: pointer;
      width: 60px;
      height: 60px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      @media ${MEDIA_DESKTOP} {
        width: 80px;
        height: 80px;
      }

      > img {
        width: 56px;
        height: 56px;
        @media ${MEDIA_DESKTOP} {
          width: 68px;
          height: 68px;
        }
      }
    }
  }
`

const NoticeBar = styled.div`
  position: fixed;
  left: 10px;
  top: 50px;
  right: 10px;
  padding: 10px;
  background-color:black;
  color: white;
  display: flex;
  align-items: flex-start;
  z-index: 99999;
  cursor: pointer;

  .ic{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px 7px 5px 5px;
    background-color: #2D9BF9;
    border-radius: 50%;
  }

  &:hover{
    opacity: 0.8;
  }

  &:active{
    opacity: 0.6;
  }
  
  >div.txt{
    flex:1;
    text-align: left;
    color: white;
    margin: 0 20px;
  }
  img{
    height: 30px;
  }
`

const GameBackground = styled.div<{
  gameStyle: number
}>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 0;

  background-position: top center;
  background-size: cover;
  background-image: url(/images/game/bg_portrait_type_${p => p.gameStyle || 1}@1x.jpg);

  @media (min-width: 750px) {
    background-image: url(/images/game/bg_portrait_type_${p => p.gameStyle || 1}@2x.jpg);
  }
  @media (min-width: 1080px) {
    background-image: url(/images/game/bg_landscape_type_${p => p.gameStyle || 1}@1x.jpg);
  }
  @media (min-width: 2160px) {
    background-image: url(/images/game/bg_landscape_type_${p => p.gameStyle || 1}@2x.jpg);
  }
  @media (min-width: 3240px) {
    background-image: url(/images/game/bg_landscape_type_${p => p.gameStyle || 1}@3x.jpg);
  }

  &::after {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    content: ' ';
    background: radial-gradient(74.72% 74.72% at 50% 50%, rgba(34, 34, 34, 0.00) 55.21%, rgba(0, 0, 0, 0.90) 100%);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  padding: 20px;
  z-index: 1;
  gap: 8px;
  @media ${MEDIA_MOBILE_MINI} {
    padding: 8px 20px;
  }

  @media ${MEDIA_DESKTOP} {
    padding: 30px 40px;
  }

  #roomInfo {
    > div {
      margin-left: 16px;
      margin-top: 0;
      position: initial;
      top: 0;
      left: 0;
    }
  }
`;

const LeaveButton = styled.button`
  color: white;
  font-size: 12px;
  padding: 2px 8px 2px 2px;
  border-radius: 30px;
  background: #181A1D;
  mix-blend-mode: darken;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;

  @media ${MEDIA_DESKTOP} {
    font-size: 12px;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }
`;

const OptionButton = styled.button`
  color: white;
  width: 28px;
  height: 28px;

  font-size: 10px;
  padding: 8px 13px;
  border-radius: 15px;
  background: #181A1D;
  mix-blend-mode: darken;
  cursor: pointer;
  position: relative;

  @media ${MEDIA_DESKTOP} {
    width: 36px;
    height: 36px;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const RoomName = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
  font-size: 10px;
`;

const FlopCardStartAnimation = keyframes`
  from {
    transform: scale(2);
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const FlopCardOpenAnimation = keyframes`
  to {
    transform: translateX(0);
  }
`;

const CommunityCards = styled.div<{
  noAnimation: boolean
}>`
  width: 100%;
  display: flex;
  gap: 4px;
  justify-content: center;
  position: absolute;
  z-index: 6;
  align-items: flex-end;
  pointer-events: none;

  &:not([data-cards="0"]) {
    animation: ${FlopCardStartAnimation} 0.2s linear;
    ${p => p.noAnimation && `
      animation-duration: 0s !important;
    `};
  }

  > * {
    width: 42px;
    height: 56px;
    opacity: 0;

    @media ${MEDIA_DESKTOP} {
      width: 60px;
      height: 80px;
    }

    &[data-open="true"] {
      opacity: 1;
      animation-delay: 0.3s;
      animation-duration: 0.2s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: both;

      ${p => p.noAnimation && `
        animation-duration: 0s !important;
        animation-delay: 0s !important;
      `};

      &:nth-of-type(1) {

      }

      &:nth-of-type(2) {
        transform: translateX(calc(-100% - 2px));
        animation-name: ${FlopCardOpenAnimation};
      }

      &:nth-of-type(3) {
        transform: translateX(calc(-200% - 4px));
        animation-name: ${FlopCardOpenAnimation};
      }

      &:nth-of-type(4) {
        animation: ${FlopCardStartAnimation} 0.2s linear 0s;
      }

      &:nth-of-type(5) {
        animation: ${FlopCardStartAnimation} 0.2s linear 0s;
      }
    }
  }
`;

const WinnerHandRanking = styled.div`
  width: auto;
  height: auto;
  min-width: 158px;
  opacity: 1;
  position: absolute;
  bottom: -11px;
  transform: translateY(100%);
  padding: 4px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%) 1;
  background: linear-gradient(90deg, rgba(24, 24, 24, 0.00) -2.1%, #181818 19.17%, #181818 84.58%, rgba(24, 24, 24, 0.00) 100%);

  @media ${MEDIA_DESKTOP} {
    height: 32px;
  }

  > div {
    backdrop-filter: drop-shadow(0 2px 4px #000);
    font-size: 14px;
    font-weight: 600;
    background: linear-gradient(180deg, #FFF 0%, #FFC92C 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media ${MEDIA_DESKTOP} {
      font-size: 18px;
    }
  }
`;

const DealerButton = styled.div`
  width: 20px;
  height: 13px;
  background-image: url(/images/dealer_button.svg);
  background-size: 100% 100%;
  position: absolute;
  left: 50%;
  top: 50%;
`;

const CoinMoveWrapper = styled.div`
  .move-coin {
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.5s ease-in-out;
    background-size: contain;
    opacity: 1;
    z-index: 1;

    * {
      animation: none !important;
    }
  }
`;

const CeremonyDimmer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.4);
  pointer-events: none;
`;

const GameTable = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
  padding-top: 30px;
  @media ${MEDIA_MOBILE_MINI} {
    padding-top: 0;
  }
  @media ${MEDIA_DESKTOP} {
    padding-top: 0;
  }
  > img.game-table {
    width: 100%;
    height: 100%;
    pointer-events: none;
    user-select: none;
  }

  > div.table-hole {
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 2px;
  }
`;

const JoinButtonWrapper = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: fit-content;

  > div {
    width: auto;
  }

  @media ${MEDIA_DESKTOP} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 20px;
    padding: 4px 8px;
  }
`;

type WinnerCards = {
  [userId: number]: number[]
}
type PlayerAct = {
  [userId: number]: number
}

const RestTimePopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 480px;
  background: linear-gradient(90deg, rgba(24, 26, 29, 0.00) 0%, rgba(24, 26, 29, 0.50) 17.71%, rgba(24, 26, 29, 0.50) 82.32%, rgba(24, 26, 29, 0.00) 100%);
  padding: 8px;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  z-index: 99;
`;

const InGameButtonWrapper = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
  padding: 20px 24px;
`;

const Loading = styled.div<{opacity:number}>`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  color:White;
  transition: all 0.5s;
  opacity: ${p => p.opacity};
`;

const WaitButtonWrapper = styled.div<{ isCenter?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 5px;
  z-index: 2;

  > * {
    min-width: 106px;
    max-width: 106px;
  }

  ${p => p.isCenter ? `
  justify-content: space-around;
  ` : `
  justify-content: flex-end;
  `}

  @media ${MEDIA_DESKTOP} {
    width: auto;
    left: unset;
    right: 0;
    gap: 8px;

    > * {
      min-width: initial;
      width: 188px;
      height: 60px;
      max-width: initial;
    }
  }
`;

const WaitBuyinText = styled.div`
  position: absolute;
  /* color: white; */
  text-align: center;
  font-size:13px;
  transform: translate(0, 25px);
  color: yellow;
  left:0;
  font-weight: bold;
  right: 0;
  text-shadow: -1px 0px black, 0px 1px black, 1px 0px black, 0px -1px black;
`


interface GameProps {
  gameData: {
    roomId: number,
    room: RoomInfo | undefined,
    isWait: boolean,
    players: (GamePlayer | undefined)[],
    myInfo: GamePlayer | undefined,
    blind: {
      small: number, big: number, ante: number
    },
    myRooms: MyRoomInfoInterface[] | undefined
    gameStyle: number;
    updateStackSize: (userId: number, stackSize: number) => void;
    updatePlayer: (seat: number, player: GamePlayer) => Promise<void>;
    maxTableMember: any;
    setRoom: any;
  };
  onInit: (
    data: RoomExtraInfoInterface
  ) => void;
  setShowOptionModal: (show: boolean) => void;
  setBuyInSeat: (seat: number) => void;
  setOpenVaultBuyIn: (open: boolean) => void;
  buyInSeat: number;
  setProfileUserId: (userId: number) => void;
  setShowEmoticonSelector: (show: boolean) => void;
  showEmoticonSelector:boolean;
  onRefreshMyRooms: () => void;
}
// eslint-disable-next-line import/no-anonymous-default-export
export default ({
                  gameData,
                  onInit,
                  setBuyInSeat,
                  buyInSeat,
                  setOpenVaultBuyIn,
                  setShowOptionModal,
                  setShowEmoticonSelector,
                  showEmoticonSelector,
                  setProfileUserId,
                  onRefreshMyRooms
}: GameProps): JSX.Element => {
  const {t} = useTranslation();
  const history = useHistory();
  const {openDialog, closeDialogByPopupId} = useDialog();
  const {
    roomId,
    room,
    players,
    myInfo,
    blind,
    gameStyle,
    isWait,
    updatePlayer,
    updateStackSize,
    maxTableMember,
    myRooms,
    setRoom
  } = gameData;

  // 대기 BGM 재생
  useBGM(Sounds.BGM_WAIT, isWait);

  const curLang = getShortLang(localStorage.getItem('i18nextLng') || "ko-KR");

  const myProfileInfo = useRecoilValue(myInfoState);
  const connected = useRecoilValue(connectionState);
  const userId = useRecoilValue(userIdSelector);
  const setGlobalLoading = useSetRecoilState(globalLoadingState);
  const setEmoticon = useSetRecoilState(emoticonState);
  const setShiningCards = useSetRecoilState(shiningCardsState);
  const setCeremonyCards = useSetRecoilState(ceremonyCardsState);
  const [myCards, setMyCards] = useRecoilState(myCardsState);
  const [betLeftSec, setBetLeftSec] = useRecoilState(betLeftSecState);
  const [communityCards, setCommunityCards] = useRecoilState(communityCardsState);
  const [ceremonyRanking, setCeremonyRanking] = useRecoilState(ceremonyRankingState);
  const setMyInfo = useSetRecoilState(myInfoState);

  const initialized = useRef<boolean>(false);
  const isPlaying = useRef<boolean>(false);
  const [noRoundAnim, setNoRoundAnim] = useState<boolean>(false);
  const [betData, setBetData] = useState<requestBetModel | null>(null);
  const [winners, setWinners] = useState<winnerModel[]>([]);
  const [winnerCards, setWinnerCards] = useState<WinnerCards>({});
  const [pots, setPots] = useState<number[]>([]);
  //const [buyInSeat, setBuyInSeat] = useState<number>(-1);
  const [sentAct, setSentAct] = useState<boolean>(false);
  const [playersBetting, setPlayersBetting] = useState<PlayersBettings[]>([]);
  const [playersAct, setPlayersAct] = useState<PlayerAct>({});
  const [timerText, setTimerText] = useState<string>('');
  const [blindTimerText, setBlindTimerText] = useState<string>('');
  const [isRestTime, setRestTime] = useState<boolean>(false);
  const [myRank, setMyRank] = useState<number>(0);
  const [totalMember, setTotalMember] = useState<number>(0);
  const [currentMember, setCurrentMember] = useState<number>(0);
  //const [showHistory, setShowHistory] = useState<boolean>(false);
  //const [profileUserId, setProfileUserId] = useState<number>(-1);
  //const [showOptionModal, setShowOptionModal] = useState<boolean>(false);
  const orientation = useScreenOrientation();
  const [autoCheckFold, setAutoCheckFold] = useState<boolean>(false);
  const [autoCall, setAutoCall] = useState<boolean>(false);
  const [autoAction, setAutoAction] = useState<'check/fold' | 'autocall' | null>(null);
  const [lastBet, setLastBet] = useState<number>(0);
  const [showAutoAction, setShowAutoAction] = useState<boolean>(false);
  const [showAutoActionForRequest, setShowAutoActionForRequest] = useState<boolean>(false);
  //const [showEmoticonSelector, setShowEmoticonSelector] = useState<boolean>(false);
  const showingWinner = useRef<boolean>(false);
  const [notice, setNotice] = useState<{
    KR:string,
    JP:string,
    EN:string,
    THAI:string,
  }|null>(null);
  const [closeNotice, setCloseNotice] = useState<boolean>(true);
  const [isHideCard, setIsHideCard] = useState<boolean>(true);
  const showOpenButton = useRef<boolean>(false);
  const [setting, setSetting] = useRecoilState(gameOptionState);
  const [showMoveRoomLoading, setShowMoveRoomLoading] = useState<boolean>(false);
  const [showMoveRoomLoadingOpacity, setShowMoveRoomLoadingOpacity] = useState<number>(0);
  //const [openVaultBuyIn, setOpenVaultBuyIn] = useState<boolean>(false);
  const [cardOpenStatus, setCardOpenStatus] = useState<{
    left: boolean;
    right: boolean;
  }>({
    left: false,
    right: false
  });

  useEffect(() => {
    if (showOpenButton.current) {
      setCardOpenStatus({
        left: false,
        right: false
      })
    } else {
      setTimeout(() => {
        setCardOpenStatus({
          left: false,
          right: false
        })
      }, 3300)
    }
  }, [showOpenButton.current]);

  useEffect(function(){
    console.log(JSON.stringify(room), notice, closeNotice)
    if(room && JSON.stringify(room.noticeText) != JSON.stringify(notice)){
      setNotice(room.noticeText)
      if(room.noticeText == null || room.noticeText.KR == "" ){
        setCloseNotice(true);
      }else{
        setCloseNotice(false);
      }
    }
  },[room?.noticeText, notice, closeNotice])

  useEffect(() => {
    const interval = setInterval(() => {
      if (room) {
        if (room.type === ROOM_TYPE.TOURNAMENT) {
          // 휴식시간 세팅
          const startedAt = parseDatetime(room.groupData.startedAt);
          const {
            playTimeSeconds,
            restTimeSeconds
          } = room.groupData.timeStructure;
          if (determineRestTime(startedAt, playTimeSeconds, restTimeSeconds)) {
            if (room?.currentRound === null || room?.currentRound === undefined) {
              setRestTime(true);
            } else {
              setRestTime(false);
            }
          }
        }
      }
    }, 1000)
    return () => {
      clearInterval(interval);
    }
  }, [room])

  const gameLayout = useGameLayout({
    dealerIndex: players.findIndex(p => p?.seat === room?.buttonPosition),
    maxTableMember: maxTableMember,
    pots: pots,
  });

  const calculator = useWinningRate();

  const showActions = useMemo<boolean>(() => {
    if (sentAct) {
      return false;
    } else if (betData?.userId !== myInfo?.userId) {
      return false;
    } else if (!betData?.legalAct) {
      return false;
    }

    return true;
  }, [betData?.userId, myInfo?.userId, sentAct]);

  const showJoinButtonRing = useMemo<boolean>(() => {
    const rr = room?.type === ROOM_TYPE.RING && myInfo?.status === ROOM_JOIN_STATUS.BUYIN_READY && myInfo?.waitGame;
    return room?.type === ROOM_TYPE.RING && myInfo?.status === ROOM_JOIN_STATUS.BUYIN_READY && myInfo?.waitGame;
  }, [room, myInfo?.status, myInfo?.waitGame, roomId]);

  const showJoinButtonTournament = useMemo<boolean>(() => {
    return room?.type === ROOM_TYPE.TOURNAMENT && myInfo?.status === ROOM_JOIN_STATUS.BUYIN_READY && myInfo?.waitGame;
  }, [room, myInfo?.status, myInfo?.waitGame, roomId]);

  const isRebuyInAvailable = useMemo<boolean>(() => {
    if (myInfo?.blindWait === true) {
      if (room?.type === ROOM_TYPE.RING) {
        const rake = room?.groupData?.rake;
        const rakeType = room?.groupData?.rakeType;

        if (rakeType === RAKE_TYPE.HAND_RAKE) {
          if (myInfo?.stackSize < rake + blind.big + blind.ante) {
            return true;
          }
        } else if (rakeType === RAKE_TYPE.POT_RAKE) {
          if (myInfo?.stackSize < blind.big + blind.ante) {
            return true;
          }
        }
      } else {
        if (myInfo?.stackSize > 0) {
          return true;
        }
      }
    }

    return false;
  }, [room?.groupData, myInfo?.blindWait, myInfo?.stackSize]);

  const totalPotSize = useMemo<number>(() => {
    const totalBet = playersBetting.reduce((a, v) => a + v.bet, 0);
    const collectedPotSize = pots.reduce((a, v) => a + v, 0);

    return totalBet + collectedPotSize;
  }, [playersBetting, pots]);

  const resetGame = useCallback(() => {
    setMyCards([]);
    setCommunityCards([]);
    setBetData(null);
    setWinners([]);
    setWinnerCards([]);
    setPots([]);
    setPlayersBetting([]);
    setSentAct(false);
    setPlayersAct({});
    setShiningCards('');
    setCeremonyCards([]);
    setCardOpenStatus({
      left: false,
      right: false
    });
    setCeremonyRanking('');
    showOpenButton.current = false;
    calculator.resetWinningRates();

    // 카드 요소 전부 감추기
    gameLayout.hideAllCards();
  }, []);

  const refreshGame = useCallback(() => {
    if (roomId >= 0) {
      (async () => {
        initialized.current = false;
        setGlobalLoading(true);
        await requestRoomInfo(roomId);
        await requestMyStatusInRoom(roomId);
        showOpenButton.current = false;
        const {cards} = await requestMyCards(roomId);
        if (cards) {
          setMyCards(cards);
        }
        setGlobalLoading(false);
      })();
    }
  }, [roomId]);

  useEffect(() => {
    resetGame();
  }, [roomId]);

  useEffect(() => {
    if (connected) {
      refreshGame();
    }
  }, [connected, refreshGame]);

  useOnResume(() => {
    resetGame();
    refreshGame();
  }, [refreshGame]);

  // 진행중인 게임 데이터 구성
  useEffect(() => {
    if (!room || initialized.current) {
      return;
    }

    showOpenButton.current = false;
    const acts: PlayerAct = {};
    const betting: PlayersBettings[] = [];
    for (let player of room.players) {
      const isFolded = player.status === ROOM_JOIN_STATUS.FOLD
      acts[player.userId] = isFolded ? BET_TYPE.FOLD : player.lastAction;
      betting.push({
        id: player.userId,
        bet: player.bet,
        ante: player.ante,
        rake: player.rake,
        stackSize: player.stackSize,
        folded: isFolded,
        prevStackSize: player.prevStackSize,
      });
    }
    setPlayersAct(acts);
    setPlayersBetting(betting);

    setCommunityCards(room.cards || []);

    let pots = [...room.pots];
    // ante가 남아있는 경우 팟에 포함시키기
    const totalAnte = betting.reduce((a, v) => a + v.ante, 0);
    if (totalAnte > 0 && pots.length === 1 && pots[0] === 0) {
      pots[0] = pots[0] + totalAnte
    }
    setPots(pots);

    if (room.roomStatus === ROOM_STATUS.INGAME) {
      isPlaying.current = true;
      gameLayout.showAllCards();
    }

    if (room.type === ROOM_TYPE.TOURNAMENT) {
      // 휴식시간 세팅
      const startedAt = parseDatetime(room.groupData.startedAt);
      const {
        playTimeSeconds,
        restTimeSeconds
      } = room.groupData.timeStructure;
      if (determineRestTime(startedAt, playTimeSeconds, restTimeSeconds)) {
        if (room?.currentRound === null || room?.currentRound === undefined) {
          if (!showingWinner.current) {
            setRestTime(true);
            gameLayout.hideAllCards();
          }
        } else {
          setRestTime(false);
        }

      }
    }

    initialized.current = true;
  }, [room]);


  // 커뮤니티 카드 플립 사운드 재생
  useEffect(() => {
    if (communityCards.length == 5) {
      playSFX(Sounds.SFX_RIVER_CARD_OPEN);
    } else if (communityCards.length >= 3) {
      playSFX(Sounds.SFX_CARD_FLOP);
    }
  }, [communityCards]);

  // 타이머 사운드 재생
  useEffect(() => {
    if (betLeftSec !== undefined && betData?.userId === myInfo?.userId) {
      if (betLeftSec <= 10) {
        playSFX(Sounds.SFX_TIMER_ALERT_10);
      }
    }
  }, [betData, myInfo?.userId, betLeftSec])

  // 내 차례 사운드 재생, 내 차레 끝나면 타이머 사운드 중지
  useEffect(() => {
    if (betData && myInfo && betData?.userId === myInfo?.userId) {
      playSFX(Sounds.SFX_MYTURN);
    } else {
      stopSound(Sounds.SFX_TIMER_ALERT_10);
    }
  }, [betData?.userId, myInfo?.userId]);

  // 안온 데이터가 있을 수 있으니 데이터 요청한닷.
  useEffect(() => {
    if(room && roomId == room.roomId){
      // console.log('>>>>>>>> communityCards D', room, roomId, betData?.userId, communityCards, myCards, room.currentRound)
      if( room.currentRound ){
        let isChange:boolean = false;
        //room.cards와 communityCards를 1:1로 비교해서, 하나라도 다르면 isChange = true
        if(room.cards.length != communityCards.length) {
          isChange = true;
        }
        if(room.cards.join(',') != communityCards.join(',')){
          isChange = true;
        }
        if(isChange){
          setCommunityCards(room.cards);
        }
      }
      if( room.currentRound && room.currentRound != 'pre-flop' && myCards.length == 0){
        requestMyCards(roomId).then((data)=>{
          setMyCards(data.cards);
        });
      }
    }
  }, [room, roomId, betData?.userId, communityCards, myCards]);

  // 메이드, 프리미엄 카드 반짝임 효과
  useEffect(() => {
    if (myCards.length !== 2) {
      return;
    }

    if (communityCards.length === 0) {
      // 프리미엄 카드 효과
      // AA, KK, QQ, JJ, AK, 1010, Aqs, Ajs, KQs
      const cards = [CARD_INFO[myCards[0]], CARD_INFO[myCards[1]]]
      const [valueL, suitL] = cards[0].split('');
      const [valueR, suitR] = cards[1].split('');
      const values = [valueL, valueR].sort().join('');

      if (values === 'AA' || values === 'KK' || values === 'QQ' || values === 'JJ' || values === 'TT' || values === 'AK') {
        setShiningCards(cards.join(','));
      } else if ((values === 'AQ' || values === 'AJ' || values === 'KQ') && suitL === suitR) {
        setShiningCards(cards.join(','));
      }
    } else {
      // 메이드 효과
      if (myCards.length === 2 && !myCards.some( x => x === -1)) {
        try{
          const solved = Hand.solve([
            ...myCards.map(x => CARD_INFO[x]),
            ...communityCards.map(x => CARD_INFO[x])
          ]);
          if (solved.rank >= 4) {
            const numCards = numCardsByRank(solved.rank)
            const targetCards = solved.cards.map((x: any) => toCardString(x.value, x.suit)).slice(0, numCards);
            setShiningCards(targetCards.sort().join(','));
          }
        }catch(e){
          console.error("2>>>>>!!", e)
        }
      }
    }
  }, [myCards, communityCards]);

/* 올인 테스트
  useEffect(() => {
    (async () => {
      if (myInfo?.blindWait) {
        await requestSetBlindWait(roomId, false);
      }
    })();
  }, [roomId, myInfo?.blindWait]);
  useEffect(() => {
    if (betData && myInfo && betData?.userId === myInfo?.userId) {
      handleTryBet(BET_TYPE.ALLIN, 0)
    }
  }, [betData?.userId, myInfo?.userId]);

  useEffect(() => {
    (async () => {
      if (isRebuyInAvailable) {
        await handleBuyIn(room?.groupData.maxBuyin);
      }
    })();
  }, [roomId, room, isRebuyInAvailable]);
*/
  useEffect(() => {
    setCardOpenEndListener(async (data: {
      roomId: number;
    }) => {
      showOpenButton.current = false;
    });
    setCardOpenListener(async (data: {
      roomId: number,
      userId: number,
      cardArr: number[]
    }) => {
      setWinnerCards(produce((d) => {
        console.log(d, d[data.userId], data.cardArr);
        if (d[data.userId] && Array.isArray(d[data.userId])) {
          if (d[data.userId].length === 0) {
            d[data.userId] = data.cardArr;
          } else if (d[data.userId].length === 2) {
            if (data.cardArr[0] !== -1) {
              d[data.userId][0] = data.cardArr[0]
            }
            if (data.cardArr[1] !== -1) {
              d[data.userId][1] = data.cardArr[1]
            }
          }
        } else {
          d[data.userId] = data.cardArr;
        }
        console.log(">>> ", d[data.userId]);
      }))
    })
    setStartGameShuffleListener(async (data: {
      roomId: number,
      userId: number
    }) => {
      if (roomId != data.roomId) {
        return;
      }
      if (isRestTime) {
        setRestTime(false);
      }
      showOpenButton.current = false;
      showingWinner.current = false;
      setMyCards([]);
      isPlaying.current = true;

      // 모든 카드 가운데로 모으기
      gameLayout.resetPlayersCards();

      // 딜러버튼 위치부터 시계방향으로 배열 만들어서 카드 딜링
      let arr = players.filter(x => x !== undefined) as GamePlayer[];
      const startIndex = arr.findIndex(x => x?.userId === data?.userId);
      arr = arr.splice(startIndex).concat(arr);
      await gameLayout.dealCardsToPlayers(arr);

      // SB/BB
      const playersBetting: PlayersBettings[] = [];
      for (let player of players) {
        if (player && player.status === ROOM_JOIN_STATUS.PLAYING && player.bet > 0) {
          playSFX(moveChipsSound(player.bet));
          playersBetting.push({
            id: player.userId,
            bet: player.bet,
            ante: player.ante,
            rake: player.rake,
            folded: false,
            stackSize: player.stackSize,
            prevStackSize: player.prevStackSize,
          });
          setPlayersBetting(playersBetting);
        }
      }

      // 내 카드 세팅
      if (myInfo && myInfo.seat >= 0) {
        const {cards} = await requestMyCards(roomId);
        playSFX(Sounds.SFX_CARD_SQUASH)
        console.log('myCards', 2)
        setMyCards(cards);
      }
    });
    setRequestBetListener(async (data: requestBetModel) => {
      if (roomId != data.roomId) {
        return;
      }
      if (!isPlaying.current) {
        isPlaying.current = true;
      }

      showOpenButton.current = false;
      showingWinner.current = false;

      setShowAutoAction(true)
      setShowAutoActionForRequest(true)
      if (!betData || data.userId !== betData.userId) {
        setBetData(data);
      }

      if (data.leftSec === null || data.leftSec === undefined) {
        setBetLeftSec(undefined);
      } else {
        setBetLeftSec(data.leftSec);
      }

      if (data.userId === myInfo?.userId) {
        if (autoCall) {
          if (data.legalAct.includes('call')) {
            await handleTryBet(BET_TYPE.CALL, 0)

          } else if (data.legalAct.includes('check')) {
            await handleTryBet(BET_TYPE.CHECK, 0)
          }
        } else {
          if (autoCheckFold) {
            if (data.legalAct.includes('check')) {
              await handleTryBet(BET_TYPE.CHECK, 0)
            } else {
              await handleTryBet(BET_TYPE.FOLD, 0)
            }
          }
        }
        setAutoCall(false)
        setAutoCheckFold(false)
      }
      if (sentAct && data.userId !== myInfo?.userId) {
        setSentAct(false);
      }
    });
    setEmitAfterBetListener(async (data: {
      model: emitAfterBetModel,
      roomId: number
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      showOpenButton.current = false;
      const model = data.model;

      if (model.lastBetStatus) {
        const {
          userId,
          type,
          bet
        } = model.lastBetStatus;

        if (type === BET_TYPE.CHECK) {
          playSFX(Sounds.SFX_CHECK, true)
        } else if (type === BET_TYPE.CALL) {
          playSFX(Sounds.NAR_CALL, true)
        } else if (type === BET_TYPE.RAISE || type === BET_TYPE.BET) {
          playSFX(Sounds.NAR_RAISE, true)
        } else if (type === BET_TYPE.ALLIN) {
          playSFX(Sounds.SFX_ALLIN_MERGE, true)
        } else if (type === BET_TYPE.FOLD) {
          playSFX(Sounds.NAR_FOLD, true)
        }

        setPlayersAct(produce((d) => {
          d[userId] = type;
        }));

        if (bet > 0) {
          playSFX(moveChipsSound(bet));
        }

        setBetData(null);
      }

      if (model.playerBettings) {
        setPlayersBetting(model.playerBettings);

        // 스택사이즈 업데이트
        for (let {
          id,
          stackSize
        } of model.playerBettings) {
          updateStackSize(id, stackSize);
        }
      }

      // 올인인 경우 팟 반영
      if (model.isAllIn && model.pots) {
        setPots(model.pots.map(x => x.amount + x.potRake));
        gameLayout.moveCoinToGamePot();
        setPlayersBetting([]);
      }
    });
    setChangeRoundListener(async (data: {
      roomId: number
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      if (room) {
        if (room.currentRound !== null && room.currentRound !== undefined) {
          showingWinner.current = false;
          showOpenButton.current = false;
        }
        // changeRound 될때 자동체크폴드 콜  초기화
        setAutoCall(false)
        setAutoCheckFold(false)

        setShowAutoAction(false);
        setNoRoundAnim(false);

        const prevTotalPot = pots.reduce((a, v) => a + v, 0);
        const newTotalPot = room.pots.reduce((a, v) => a + v, 0);
        if (newTotalPot > prevTotalPot) {
          gameLayout.moveCoinToGamePot();
          setPots(room.pots || []);
        }
        setPlayersBetting([]);

        // 폴드 외 액션 삭제
        setPlayersAct(produce((d) => {
          for (let userId in d) {
            if (d[userId] !== BET_TYPE.FOLD) {
              delete d[userId];
            }
          }
        }));
        await wait(500);
        // console.log(">>>>>>> change round", room.cards || [])
        setCommunityCards(room.cards || []);
      }
    });
    setAllInShowdownListener(async (data: {
      roomId: number,
      wholeCards: {
        userId: number,
        holeCards: number[]
      }[]
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      // 승률 설정 전 리셋
      calculator.resetWinningRates();
      const innerWinnerCards: WinnerCards = {};
      for (let {
        userId,
        holeCards
      } of data.wholeCards) {
        innerWinnerCards[userId] = holeCards;
        calculator.addPlayer(userId, holeCards);
      }
      if (innerWinnerCards) {
        playSFX(Sounds.SFX_SHOWDOWN);
        setWinnerCards(prev => {
          return {
            ...prev,
            ...innerWinnerCards
          }
        })
      }
    });
    setOpenCommunityCardsListener(async (data: {
      roomId: number;
      cards: number[];
    }) => {
      showOpenButton.current = false;
      if (roomId != data.roomId) {
        return;
      }

      setCommunityCards(data.cards);
      setRoom(produce((d:any) => {
        d.cards = data.cards;
      }));
      await calculator.setCommunityCard(data.cards);

      setTimeout(() => {
        calculator.updateWinningRate();
      }, 1000);
    });
    setGameWinnerListener(async (data: {
      roomId: number;
      winners: winnerModel[];
      players: GamePlayer[];
      prize: number;
      wholeCards: {
        [key: number]: number[]
      };
      tournamentRankingList: {
        userId: number,
        stackSize: number,
        ranking: number
      }[];
      totalMemberCount: number;
      totalCurrentMemberCount:number;
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      if (room) {
        // 위너 설정
        setWinnerCards(data.wholeCards || {});
        await wait(1000);

        setWinners(data.winners);
        if (data.wholeCards) {
          setWinnerCards(prev => {
            return {
              ...prev,
              ...data.wholeCards
            }
          })
          if (!data.wholeCards[myInfo?.userId ?? -1]) {
            showOpenButton.current = true;
          } else {
            showOpenButton.current = false;
          }
        }
        calculator.resetWinningRates();
        gameLayout.moveCoinToWinners(data.winners);

        // 스택 업데이트
        for (let {
          userId,
          stackSize
        } of data.players) {
          updateStackSize(userId, stackSize);
        }

        setPots([]);
        setTimeout(() => {
          showingWinner.current = false;
        }, 2000)
        // 토너먼트 처리
        if (room.type === ROOM_TYPE.TOURNAMENT) {
          // 랭킹 세팅
          if (isRestTime) {
            showOpenButton.current = false;
          }

          if (data.tournamentRankingList) {
            const myRanking = data.tournamentRankingList.find(x => x.userId === myInfo?.userId);
            if (myRanking) {
              setMyRank(myRanking.ranking);
              setTotalMember(data.totalMemberCount);
              setCurrentMember(data.totalCurrentMemberCount)
            }
          }

          // 휴식시간 세팅
          const startedAt = parseDatetime(room.groupData.startedAt);
          const {
            playTimeSeconds,
            restTimeSeconds
          } = room.groupData.timeStructure;
          if (determineRestTime(startedAt, playTimeSeconds, restTimeSeconds)) {
            if (room?.currentRound === null || room?.currentRound === undefined) {
              setRestTime(true);
            } else {
              setRestTime(false);
            }
          }
        }
      }
    });
    setTableCleanUpListener(async (data: any) => {
      if(data.roomId === roomId){
        console.log('>>>>>> resetGame', 3)
        resetGame();
        isPlaying.current = false;
      }
    });
    setLeaveJoinRoomSocketListener(async (data: any) => {
      if (roomId != data.roomId) {
        return;
      }

      if (data.reason == -4) {
        if(room?.type === ROOM_TYPE.TOURNAMENT){
          const {
            playTimeSeconds,
            restTimeSeconds
          } = room.groupData.timeStructure;
          console.log(room.groupData)
          const playTime = calcPlayTime(moment.utc(room.groupData.startedAt), playTimeSeconds, restTimeSeconds);
          const level = calcLevel(playTime, room.groupData.blindStructure);

          if(level+1 < room.groupData.availableRegisterLevel){
            const buyinCnt = await requestGetTournamentBuyinCount( room?.groupId )
            if(buyinCnt.isEnd == false){
              const rebuyin = await new Promise<number>((r) => {
                closeDialogByPopupId("rebuyin");
                openDialog({
                  popupId:"rebuyin",
                  title: t("리바이인"),
                  component: <TournamentRebuyinDialog
                    buyinCnt={buyinCnt.count}
                    groupId={room?.groupId}
                    maxRebuyin={room?.groupData.rebuyinLimit}
                    buyinPrice={room?.groupData.buyinPrice}
                    supplyChip={room?.groupData.rebuyinChip}
                    currentLevel={level + 1}
                  />,
                  confirm: true,
                  disableBackdropClick:true,
                  confirmText: t('리바이인'),
                  cancelText: t('나가기'),
                  confirmDisable: room?.groupData.rebuyinLimit > 0 ? room?.groupData.rebuyinLimit <= buyinCnt.count : false,
                  onConfirm: () => {
                    const chip = currencyToChip("USD", room?.groupData.buyinPrice);
                    const buyin = UserToCurrency(chip);
                    requestRebuyinTournament(room?.groupId, room?.groupData.buyinPrice, buyin).then((res) => {
                      r(res.result as number);
                    });
                  },
                  onCancel: () => {
                    r(0);
                  }
                });
              });

              if(rebuyin < 0){
                switch(rebuyin){
                  case -1:
                    openDialog({
                      title: t('안내'),
                      text: t('리바이인이 불가능한 레벨입니다.')
                    });
                    break;
                  case -2:
                    openDialog({
                      title: t('안내'),
                      text: t('칩이 부족합니다.')
                    });
                    break;
                  case -3:
                    openDialog({
                      title: t('안내'),
                      text: t('게임이 시작되지 않아 리바이인이 불가능합니다.')
                    });
                    break;
                  case -101:
                    openDialog({
                      title: t('안내'),
                      text: t('보유 금액을 불러오지 못했습니다. 관리자에게 문의해주세요.')
                    });
                    break;
                  case -4:
                    openDialog({
                      title: t('안내'),
                      text: t('리바이인이 불가능합니다.')
                    });
                    break;
                  case -6:
                    openDialog({
                      title: t('안내'),
                      text: t('리바인 횟수를 초과하였습니다.')
                    });
                    break;
                  case -7:
                    openDialog({
                      title: t('안내'),
                      text: t('탈락하지 않은 상태입니다.')
                    });
                    break;
                  case -8:
                    openDialog({
                      title: t('안내'),
                      text: t('칩이 남아있어 리바이인이 불가능합니다.')
                    });
                    break;
                  case -11:
                    openDialog({
                      title: t('안내'),
                      text: t('보유금을 차감에 실패했습니다. 관리자에게 문의해주세요.')
                    });
                    break;
                  default:
                    openDialog({
                      title: t('안내'),
                      text: t('리바이인이 불가능합니다.')
                    });
                    break;
                }
              }

              if (rebuyin > 0) {
                history.push("/game?id=" + rebuyin + "&time="+Date.now());
                requestRoomInfo(rebuyin);
                return;
              }
            }
          }else{
            let rank = await requestGetMyTournamentRank(room?.groupId);
            openDialog({
              title: t('안내'),
              text:
                "<div style='border-top:1px solid #555;border-bottom:1px solid #555; padding:20px 0;margin-bottom:20px;font-size:18px'>"+
                t('토너먼트 성적 : {{myRank}}등', {myRank: rank.rank}) +
                "</div><div style='margin-bottom:10px'>" +
                t("참여해 주셔서 감사합니다.<br> 로비로 이동합니다.")+
                "</div>"
            });
          }

        }else{
          openDialog({
            title: t('안내'),
            text: t('칩을 모두 잃어 대기실로 이동되었습니다.')
          });
        }
      }

      if (data.reason == -1) {
        openDialog({
          title: t('안내'),
          text: t('게임룸이 닫혀 대기실로 이동되었습니다.')
        });
      } else if (data.reason == -2) {
        openDialog({
          title: t('안내'),
          text: t('장시간 입력이 없어 대기실로 이동되었습니다.')
        });
      } else if (data.reason == -9901) {
        openDialog({
          title: t('안내'),
          text: t('최소인원 미달로 토너먼트가 종료되었습니다.<br>토너먼트 참가비용은 환불되었습니다.')
        });
      } else if (data.reason == -5) {
        // openDialog({
        //   title: t('안내'),
        //   text: t('정산이 완료된 상태여서 나가집니다.')
        // });
      }

      history.push('/');
    });
    setRefreshPlayersInfoListener(async (data: {
      roomId: number,
      players: GamePlayer[]
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      const joinedUser = Object.values(data.players).find(x => x !== null);
      if (!joinedUser) {
        playSFX(Sounds.SFX_GAME_OUT);
      } else if (joinedUser.blindWait) {
        playSFX(Sounds.SFX_GAME_IN);
      }

      for (let seat in data.players) {
        const player = data.players[seat];

        if (player) {
          if (player.status === ROOM_JOIN_STATUS.FOLD) {
            // 유저가 나갔다가 다시 들어와서 FOLD 처리
            setPlayersAct(produce(d => {
              d[player.userId] = BET_TYPE.FOLD;
            }))
          }
          // else if (isPlaying.current) {
          //   player.status = ROOM_JOIN_STATUS.BUYIN_READY;
          // }
        }
        await updatePlayer(Number(seat), player);
      }

      if (isPlaying.current && (room?.type !== ROOM_TYPE.TOURNAMENT || !isRestTime)) {
        gameLayout.showAllCards();
      }
    });
    setCollectAnteListener(async (data: {
      roomId: number,
      antePlayers: {
        userId: number,
        ante: number
      }[]
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      if (data.antePlayers) {
        for (let {
          userId,
          ante
        } of data.antePlayers) {
          setPots(produce(d => {
            d[0] = (d[0] || 0) + ante;
          }));
          await gameLayout.moveCoinAnte(userId, ante);
        }
      }
    });
    setCollectRakeListener(async (data: {
      roomId: number,
      rakePlayers: {
        userId: number,
        rake: number
      }[]
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      if (data.rakePlayers) {
        for (let {
          userId,
          rake
        } of data.rakePlayers) {
          await gameLayout.moveCoinRake(userId, rake);
        }
      }
    });
    setSendEmojiListener(async (data: {
      roomId: number,
      userId: number,
      emojiId: number
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      const {
        emojiId,
        userId
      } = data
      setEmoticon(produce(d => {
        d[userId] = emojiId;
      }));

      // 3초 뒤 해당 플레이어의 이모티콘 삭제
      setTimeout(() => {
        setEmoticon(produce(d => {
          if (d[userId] === emojiId) {
            delete d[userId];
          }
        }));
      }, 3000);
    });
    return () => {
      setStartGameShuffleListener(null);
      setRequestBetListener(null);
      setEmitAfterBetListener(null);
      setChangeRoundListener(null);
      setAllInShowdownListener(null);
      setOpenCommunityCardsListener(null);
      setGameWinnerListener(null);
      setTableCleanUpListener(null);
      setLeaveJoinRoomSocketListener(null);
      setRefreshPlayersInfoListener(null);
      setCollectAnteListener(null);
      setCollectRakeListener(null);
      setSendEmojiListener(null);
    };
  });
  useEffect(() => {
    onRefreshMyRooms()
  }, [showJoinButtonRing]);
  //자동 콜 풀리는 로직
  useEffect(() => {
    if (room?.currentBet) {
      if (lastBet !== room?.currentBet) {
        setAutoCall(false)
        setLastBet(0)
      }
    } else {
      setAutoCall(false)
      setLastBet(0)
    }
  }, [room?.currentBet])
  // 토너먼트 남은시간 타이머 계산
  useEffect(() => {
    if (!room || room.type !== ROOM_TYPE.TOURNAMENT) {
      return;
    }

    const startedAt = parseDatetime(room.groupData.startedAt);
    const {
      playTimeSeconds,
      restTimeSeconds
    } = room.groupData.timeStructure;

    const interval = setInterval(() => {
      let leftSec = 0;

      const elapsedForRound = calcElapsedForRound(startedAt, playTimeSeconds, restTimeSeconds);
      const playTime = calcPlayTime(parseDatetime(room.groupData.startedAt), playTimeSeconds, restTimeSeconds);
      const blindUptime = calcBlindUpTime(playTime, room.groupData.blindStructure);
      if (blindUptime) {
        setBlindTimerText(
          t('블라인드 업 : {{mm}}분 {{ss}}초 남음',{
            mm: moment.unix(blindUptime).format('mm'),
            ss: moment.unix(blindUptime).format('ss')
          })
        )
      }
      let descText = "";
      if (elapsedForRound > 0) {
        if (elapsedForRound >= playTimeSeconds) {
          // 현재 휴식 시간. 다음 라운드까지 남은 시간
          if (isRestTime) {
            descText = t("휴식시간 : ");
            leftSec = playTimeSeconds + restTimeSeconds - elapsedForRound;
          }
        } else {
          // 현재 라운드 진행 중. 다음 휴식 시간까지 남은 시간
          if (!isRestTime) {
            descText = t("휴식까지 : ");
            leftSec = playTimeSeconds - elapsedForRound;
          }
        }
      } else {
        // 토너먼트 시작 전. 시작까지 남은 시간
        leftSec = -elapsedForRound;
      }

      if (leftSec >= 0) {
        setTimerText(
          descText+t('{{mm}}분 {{ss}}초 남음',{
            mm: moment.unix(leftSec).format('mm'),
            ss: moment.unix(leftSec).format('ss')
          })
        )
      }
      if (leftSec <= 0) {
        setRestTime(false)
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [room, isRestTime]);

  // 승자 족보 세팅
  useEffect(() => {
    if (winners.length > 0 && Object.keys(winnerCards).length > 0 && communityCards.length > 0) {
      const ceremonyCards: string[] = [];
      let ceremonyRanking = ''
      for (let winner of winners) {
        const cards = winnerCards[winner.userId];
        if (cards && cards.length === 2 && !cards.some( x => x === -1)) {
          try{
            const solved = Hand.solve([
              ...cards.map(x => CARD_INFO[x]),
              ...communityCards.map(x => CARD_INFO[x])
            ])

            const targetCards = solved.cards.map((x: any) => toCardString(x.value, x.suit));
            for (let card of targetCards) {
              if (!ceremonyCards.includes(card)) {
                ceremonyCards.push(card);
              }
            }

            if (winner.userId === myInfo?.userId || ceremonyRanking === '') {
              ceremonyRanking = solved.descr;
            }
          }catch(err){
            console.error("3>>>>>!!", err)
          }
        }
      }

      setCeremonyCards(ceremonyCards);
      setCeremonyRanking(ceremonyRanking);
    }
  }, [myInfo?.userId, winners, winnerCards, communityCards]);

  const isObserve = useMemo<boolean>(() => {
    if (room) {
      return room.players.findIndex(p => p.userId === userId) === -1
    } else {
      return false;
    }
  }, [room, userId])

  const handleOpenCard = (dir: 'left' | 'right' | 'all', handle?: (left: boolean, right: boolean) => void) => {
    const showWaitButtonCondition = (myInfo?.status === ROOM_JOIN_STATUS.PLAYING || myInfo?.status === ROOM_JOIN_STATUS.FOLD) &&
      showOpenButton.current &&
      !isObserve
    if (!showWaitButtonCondition) return;

    const openCard1 = dir === 'left' || dir === 'all';
    const openCard2 = dir === 'right' || dir === 'all';

    if(dir == "left" && openCard1 == cardOpenStatus.left) return ;
    if(dir == "right" && openCard2 == cardOpenStatus.right) return ;
    if(
      openCard1 == cardOpenStatus.left &&
      openCard2 == cardOpenStatus.right
    ) return ;

    console.log(openCard1, openCard2, cardOpenStatus.left, cardOpenStatus.right)

    requestRequestCardOpen(roomId, openCard1, openCard2).then(res => {
      if (res.result) {
        setCardOpenStatus(prev => {
          if (dir === 'all') {
            return {
              right: true,
              left: true
            }
          } else if (dir === 'left') {
            return {
              ...prev,
              left: true
            }
          } else if (dir === 'right') {
            return {
              ...prev,
              right: true
            }
          } else {
            return {
              ...prev
            }
          }
        })
        if (handle) {
          handle(openCard1, openCard2)
        }
      }
    })
  }

  const handleLeave = useCallback(async () => {
    if (room?.type === ROOM_TYPE.RING && myInfo) {
      if (Number(myInfo?.stackSize) >= 0 && Number(myInfo?.handsCount) >= 0) {
        // let vaults = await requestMyGroupVaultList();
        // let vault = vaults.list.find(v => v.groupId == room?.groupId && room?.roomId == v.roomId);
        // if( vault ){
        //   await requestLeaveRoom(roomId);
        //   history.push('/');
        //   return;
        // }

        const res = await requestGetHandCuttingList();
        const handCuttingList = res.list;
        let settle = myInfo.stackSize;
        let buyin = myInfo.buyin;
        let winAmount = settle - buyin;
        let lossPercentage = 0;
        let maxHands = 0;

        let cuttingList = res.list.sort((a, b) => {
          return a.handCount - b.handCount
        })
        maxHands = cuttingList[cuttingList.length - 1].handCount;

        for (let v of handCuttingList) {
          if (myInfo.handsCount <= v.handCount) {
            lossPercentage = v.lossPercentage
            let lossAmount = Math.floor(winAmount * (lossPercentage) / 100);
            settle = settle - lossAmount;
            break;
          }
        }

        if (winAmount < 0) {
          settle = myInfo.stackSize;
          winAmount = 0;
        }

        const leave = await new Promise<boolean>((r) => {
          openDialog({
            popupId:"leaveSettlement",
            title: `[${t("게임 종료")}]`,
            component: <LeaveSettlement
              roomType={room.type}
              bb={blind.big}
              hands={myInfo.handsCount}
              stack={myInfo?.stackSize}
              buyin={buyin}
              winAmount={winAmount}
              lossPercentage={lossPercentage}
              settle={settle}
              maxHands={maxHands+1}
              cuttingList={cuttingList}
              lack={maxHands >= myInfo.handsCount}
            />,
            confirm: true,
            confirmText: t('멀티테이블 이용'),
            cancelText: t('나가기'),
            onConfirm: () => {
              r(false);
            },
            onCancel: () => {
              r(true);
            }
          });
        });

        if (!leave) {
          // 그냥 로비로 나감
          let v = await requestMyInfo()
          setMyInfo(v.info);
          history.replace("/");
          return;
        }
      }
    }else if(myInfo && room?.type === ROOM_TYPE.TOURNAMENT){
      const leave = await new Promise<boolean>((r) => {
        openDialog({
          title: t("[토너먼트 나가기]"),
          text: t('방에서 나갈 경우 자동폴드(엔티,블라인드지불) 됩니다.<br>정말 나가시겠습니까?'),
          confirm: true,
          confirmText: t('나가기'),
          cancelText: t('닫기'),
          onConfirm: () => {
            r(true);
          },
          onCancel: () => {
            r(false);
          }
        });
      });

      if (!leave) {
        return;
      }
    }
    setGlobalLoading(true);
    try {
      const data = await requestLeaveRoom(roomId);
      if (data.result) {
        let v = await requestMyInfo()
        setMyInfo(v.info);
        history.replace("/");
      }else{
        let anyData = data as any;
        if(anyData.code == -1){
          if(anyData.iasd == true){
            openDialog({
              title: t('안내'),
              text: t('싯아웃에 실패하였습니다. 에이전시에 문의해주세요.')
            });
          }
        }
      }
    }catch(err){
      console.error(err)
    } finally {
      setGlobalLoading(false);
    }
  }, [roomId, room?.type, myInfo?.stackSize, blind]);

  const handleShowOption = useCallback(() => {
    setShowOptionModal(true);
  }, []);

  const handleRealTimePrize = useCallback(()=>{
    if(room){
      requestGetTournamentPrize(room?.groupId).then((res) => {
        if(res.prize){

          let r = currencyToChip("USD", res.prize);
          let prize = UserToCurrency(r);

          openDialog({
            title: t('토너먼트 총상금'),
            text:
            t("총 참여자수 : {{num}}명",{num:res.players})+"<br/>"+
            t("총 리엔트리 : {{num}}명",{num:res.rebuyins})+"<br/>"+
            "<br/>"+
            (res.gtdRatio > 0 ? t("상금 프라이즈 {{ratio}}%",{ratio:res.gtdRatio})+"<br/>" : "")+
            t("총상금 : {{userCurrencyMark}}{{num}}", {num:prize.toLocaleString(), userCurrencyMark:UserCurrencyMark()})
          });
        }
      });
    }
  },[room])

  const handleToggleBlindWait = useCallback(async () => {
    await requestSetBlindWait(roomId, !myInfo?.blindWait);
  }, [myInfo?.blindWait]);

  const handleSitDown = useCallback(async (s: number) => {
    if (myInfo?.status !== ROOM_JOIN_STATUS.PLAYING) {
      setBuyInSeat(s);
    }
  }, [myInfo?.status]);

  const handleVaultBuyInOpen = useCallback(async () => {
    setOpenVaultBuyIn(true);
  },[]);

  const handleVaultBuyIn = useCallback(async (vaultId: number) => {
    const seat = buyInSeat;
    setBuyInSeat(-1);
    setOpenVaultBuyIn(false);

    setGlobalLoading(true);
    let data = await requestVaultBuyin(roomId, vaultId, seat);
    if(data.result == -1){
      openDialog({
        title: t('안내'),
        text: t('알수없는 오류로 바이인을 실패했습니다.')
      });
    }else if(data.result == -4002){
      openDialog({
        title: t('안내'),
        text: t('해당 정산의 게임이 아직 끝나지 않아 사용 할 수 없습니다.')
      });
    }

    requestRoomInfo(roomId);
    requestMyStatusInRoom(roomId);
    setGlobalLoading(false);
  },[buyInSeat]);

  const handleBuyIn = useCallback(async (amount: number, vaultId:number) => {
    const seat = buyInSeat;
    setBuyInSeat(-1);
    setGlobalLoading(true);

    if(vaultId > 0){
      let data = await requestVaultBuyin(roomId, vaultId, seat);
      if(data.result == -1){
        openDialog({
          title: t('안내'),
          text: t('알수없는 오류로 바이인을 실패했습니다.')
        });
      }else if(data.result == -4002){
        openDialog({
          title: t('안내'),
          text: t('해당 정산의 게임이 아직 끝나지 않아 사용 할 수 없습니다.')
        });
      }
    }else{
      let data = await requestBuyin(roomId, amount, UserToChip(amount), seat);
      if (data.result == -1) {
        openDialog({
          title: t('안내'),
          text: t('알수없는 오류로 바이인을 실패했습니다.')
        });
      } else if (data.result == -2) {
        openDialog({
          title: t('안내'),
          text: t('보유 머니가 부족하여 바이인을 실패했습니다.')
        });
      } else if (data.result == -3) {
        openDialog({
          title: t('안내'),
          text: t('이미 해당 자리에 누군가 먼저 앉아서 바이인을 실패하였습니다.')
        });
      } else if (data.result == -11) {
        openDialog({
          title: t('안내'),
          text: t('바이인 최대 한도를 초과하여 바이인할 수 없습니다.')
        });
      } else if (data.result == -15) {
        openDialog({
          title: t('안내'),
          text: t('게임 대기 중엔 추가 바이인을 할 수 없습니다.')
        });
      }  else if (data.result == -1001) {
        openDialog({
          title: t('안내'),
          text: t('나가기 예약이 되어있어 바이인을 할 수 없습니다.')
        });
      } else if (data.result == -1001) {
        openDialog({
          title: t('안내'),
          text: t('현재 진행 중인 게임이 있습니다.<br>기존에 진행 중인 게임이 종료된 후 다시 바이인을 시도해 주세요.')
        });
        history.replace("/");
      } else if(data.result == -100001){
        openDialog({
          title: t('안내'),
          text: t('변환 비율에 변동이 있습니다. 정보를 다시 불러옵니다.')
        });
        requestRequestExchangeRate();
      } else if(data.result == -100002){
        openDialog({
          title: t('안내'),
          text: t('최소 바이인 칩은 10,000개입니다.')
        });
      } else {
        let anyData = data as any;
        if(anyData.code == -1){
          if(anyData.iasd == true){
            openDialog({
              title: t('안내'),
              text: t('바이인에 실패하였습니다. 에이전시에 문의해주세요.')
            });
          }else{
            openDialog({
              title: t('안내'),
              text: t('알수없는 오류로 바이인을 실패했습니다.') + `[${data.result}]`
            });
          }
        }
      }
    }
    requestRoomInfo(roomId);
    requestMyStatusInRoom(roomId);
    setGlobalLoading(false);
    onRefreshMyRooms();
  }, [buyInSeat]);

  const handleRejoinGame = useCallback(async () => {
    if (!isRebuyInAvailable || !myInfo) {
      return;
    }

    if (room?.type === ROOM_TYPE.RING) {
      setBuyInSeat(myInfo.seat);
    } else if (room?.type === ROOM_TYPE.TOURNAMENT) {
      // 0원되면 그냥 쫒겨남
      // openDialog({
      //   title: t('바이인 안내'),
      //   text: `리바이인 시 ${room.groupData.rebuyinChip.toLocaleString()} 을 얻고, ${room.groupData.buyinPrice} 를 지출하게 됩니다.<br/>현재 머니 : ${myProfileInfo?.money}`
      //   confirm: true,
      //   confirmText: '리바인',
      //   cancelText: '취소',
      //   onConfirm: async () => {
      //     await requestRebuyinTournament(room.groupId);
      //   }
      // });
    }
  }, [room, myInfo, isRebuyInAvailable, myProfileInfo]);

  const handleTryBet = useCallback(async (type: BET_TYPE, betAmount: number) => {
    console.log("betAmount", betAmount)
    await requestTryBet(roomId, type, betAmount);
    setShowAutoActionForRequest(false)
    setBetData(null);
  }, [roomId]);

  const handleOpenProfile = useCallback((userId: number) => {
    setProfileUserId(userId);
  }, []);

  const handleSendEmoticon = useCallback(async (emoticonId?: number) => {
    if (emoticonId) {
      await requestRequestEmoji(roomId, emoticonId);
    }

    setShowEmoticonSelector(false);
  }, [roomId]);

  const handleRebuyin = useCallback(async()=>{
    if(myInfo && room){
      if(myInfo.left == true){
        return openDialog({
          title: t('안내'),
          text: t('나가기가 예약되어있어 추가 바이인을 할 수 없습니다.')
        });
      }
      if(myInfo.stackSize == 0){
        return openDialog({
          title: t('안내'),
          text: t('남은 칩이 없어 추가 바이인을 할 수 없습니다.')
        });
      }else if(myInfo.stackSize >= room.groupData.maxBuyin){
        return openDialog({
          title: t('안내'),
          text: t('최대 바이인 금액을 보유하고 있어 추가 바이인을 할 수 없습니다.')
        });
      }
      setBuyInSeat(myInfo.seat);
    }
  },[myInfo, room])


  const StatusBoardComponent = useMemo(function(){
    return !room ? <></> : <StatusBoard
      roomType={room.type}
      SB={blind.small}
      BB={blind.big}
      ante={blind.ante}
      rakeType={room.groupData.rakeType}
      rake={room.groupData.rake}
      myRank={myRank}
      totalMember={totalMember}
      currentMember={currentMember}
      straddle={room.groupData.isStraddle}
      timerText={timerText}
      blindUpTimerText={blindTimerText}
      groupData={room.groupData}
      isObserve={isObserve}
    />
  },[room, blind, myRank, totalMember,currentMember, timerText, blindTimerText, isObserve]) ! ;

  useEffect(() => {
    if(room){
      console.log("???")
      onInit({
          roomId,
          handleLeave,
          StatusBoardComponent,
          handleRealTimePrize,
          handleShowOption,
          handleSendEmoticon,
          handleBuyIn,
          handleVaultBuyInOpen,
          handleVaultBuyIn,
          handleRebuyin
        })
    }
  }, [
    room,
    roomId,
    handleLeave,
    handleRealTimePrize,
    handleShowOption,
    handleSendEmoticon,
    handleBuyIn,
    handleVaultBuyInOpen,
    handleVaultBuyIn,
    handleRebuyin,
    StatusBoardComponent
  ]);

  if (!room) {
    return <></>;
  }

  console.log("??====??")

  const isTournament = room.type === ROOM_TYPE.TOURNAMENT;
  const myWaitBuyin = room && myInfo ? room.waitBuyins.find(v=>v.userId == myInfo.userId) : null;
  return <>
    {showMoveRoomLoading && <Loading opacity={showMoveRoomLoadingOpacity}>{t("방 이동 중...")}</Loading>}
    <GameBackground gameStyle={gameStyle}/>
    <Wrapper>
      {closeNotice == false && !!notice ? <NoticeBar onClick={()=>setCloseNotice(true)}>
        <div className="ic"><img src="/images/ic_notice.png" alt=""/></div>
        <div className="txt">{notice[curLang]||notice.KR}</div>
        <img src="/images/ic_close.png" alt=""/>
      </NoticeBar> : null}
      <GameTable>
        <img
          style={{
            objectFit: 'contain',
            padding: (orientation === 'portrait_mini' ? '3% 0 2%' : 0)
          }}
          className="game-table"
          src={`/images/game/table_${orientation === 'landscape' ? 'landscape' : 'portrait'}_type_${gameStyle}.svg`}
        />
        <DealerButton className="dealer-button"/>
        {/*{*/}
        {/*  // 승자 칩 뿌리는 이펙트*/}
        {/*  winners.findIndex(x => x.userId === myInfo?.userId) !== -1 && (*/}
        {/*    <WinnerChipEffect/>*/}
        {/*  )*/}
        {/*}*/}
        <div className="table-hole"/>
        {
          players.map((p, i) => {
            const userId = p?.userId ?? -1;
            const isMe = userId === myInfo?.userId;
            const disabled = !p && !!myInfo && myInfo?.status !== ROOM_JOIN_STATUS.OBSERVE;
            const isBetNow = betData?.userId == userId;
            const winningRate = calculator.winningRates.find(x => x.userId === userId);

            const cards = [];
            if (isMe && Array.isArray(myCards)) {
              cards.push(...myCards);
            } else if (winnerCards[userId]) {
              cards.push(...winnerCards[userId]);
            }

            const myRebuyin = room?.waitBuyins.find(v=>v.userId == userId);

            return <Player
              roomType={room.type}
              openCard={isMe ? cardOpenStatus : {left: false, right: false}}
              handleOpenCard={handleOpenCard}
              key={i}
              idx={i}
              player={p}
              BB={blind.big}
              rebuyin={myRebuyin? myRebuyin.amount : 0}
              act={playersAct[userId] || p?.lastAction}
              me={isMe}
              betNow={isBetNow}
              disabled={disabled}
              winners={winners}
              rate={winningRate?.rate}
              communityCards={communityCards}
              cards={cards}
              currency={p?.currency || ""}
              onClickSeat={handleSitDown}
              onClickProfile={handleOpenProfile}
              onClickEmoticon={() => setShowEmoticonSelector(true)}
            />;
          })
        }
        {
          Array.from({length: maxTableMember}).map((v, i) => {
            const p = players[i];
            const userId = p?.userId ?? -1;
            let amount = 0;
            if (p && playersBetting) {
              const playerBetting = playersBetting.find(q => q.id == userId);
              if (playerBetting) {
                amount = playerBetting.bet;
              }
            }
            return <PlayerPot key={i} roomType={room.type} userId={userId} bb={blind.big} amount={amount}/>;
          })
        }

        <FieldPots roomType={room.type} total={totalPotSize} pots={pots} BB={blind.big}>
          {
            orientation === 'portrait' && (
              StatusBoardComponent
            )
          }
        </FieldPots>
       <CommunityCards className="community-cards-wrapper" data-cards={communityCards.length}
                        noAnimation={noRoundAnim}>
          {
            Array.from({length: 5}).map((_, i) => {
              const showCard = communityCards[i] >= 0;
              if (showCard) {
                return <PokerCard
                  key={i}
                  className="community-card"
                  data-open={showCard}
                  card={communityCards[i]}
                  flip
                  delay={noRoundAnim ? -1 : 1000}
                />;
              } else {
                return <div key={i}/>;
              }
            })
          }
          {
            ceremonyRanking && (
              <WinnerHandRanking>
                <div>{ceremonyRanking}</div>
              </WinnerHandRanking>
            )
          }
        </CommunityCards>
        <CoinMoveWrapper className="coin-move-wrapper"/>
        {
          ceremonyRanking && (
            <CeremonyDimmer/>
          )
        }
        {
          !showEmoticonSelector && (
            <InGameButtonWrapper className="ingame-button-wrapper">
              {
                (showActions &&
                  !(
                    (
                      autoAction === 'check/fold' && (
                        betData!.legalAct.includes('check') || betData!.legalAct.includes('fold')
                      )
                    ) ||
                    (
                      autoAction === 'autocall' && (
                        betData!.legalAct.includes('call'))
                    )
                  )
                ) ? (
                  <ActionButtons
                    handleClickTryBet={handleTryBet}
                    myInfo={myInfo!}
                    room={room}
                    legalActs={betData!.legalAct}
                    totalPot={totalPotSize}
                    bb={blind.big}
                  />
                ) : (((myInfo !== undefined) && !myInfo.waitGame && (
                    playersBetting.length > 0 ?
                      (
                        room.currentRound !== 'pre-flop' ? (
                          (playersBetting.sort((a, b) => b.bet - a.bet)[0].bet === 0) ||
                          (playersBetting.sort((a, b) => b.bet - a.bet)[0].bet > myInfo.bet)
                        ) : (
                          (
                            playersAct[myInfo.userId] === BET_TYPE.BB ||
                            players.find(_ => _?.userId === myInfo.userId)?.lastAction === BET_TYPE.BB
                          ) ||
                          playersBetting.sort((a, b) => b.bet - a.bet)[0].bet > myInfo.bet
                        )
                      ) : true
                  ) && (showAutoActionForRequest) && (showAutoAction) && (room.roomStatus === ROOM_STATUS.INGAME) && (myInfo.status !== ROOM_JOIN_STATUS.FOLD) && (!myInfo.blindWait) && (myInfo.stackSize > 0)) &&
                  <WaitButtonWrapper>
                    <div style={{
                      flex: 1
                    }}/>
                    <InGameButton roomType={room.type} bb={blind.big} noAction checked={autoCheckFold} onChecked={() => {
                      if (autoCheckFold) {
                        setAutoCheckFold(false)
                      } else {
                        setAutoCheckFold(true)
                        setAutoCall(false)
                      }
                    }}>
                      {t('체크/폴드')}
                    </InGameButton>
                    <InGameButton roomType={room.type} bb={blind.big}
                      noAction
                      callAmount={(room.currentBet ?? 0) - (myInfo.bet ?? 0)}
                      checked={autoCall}
                      onChecked={() => {
                        if (autoCall) {
                          setAutoCall(false)
                        } else {
                          setAutoCall(true)
                          setAutoCheckFold(false)
                          setLastBet(room?.currentBet)
                        }
                      }}
                    >
                      {t('자동 콜')}
                    </InGameButton>
                    <div/>
                  </WaitButtonWrapper>
                )
              }
              {
                showJoinButtonRing && (
                  <JoinButtonWrapper>
                    {
                      isRebuyInAvailable ? (
                        <InGameButton roomType={room.type} bb={blind.big} dark noAction onClick={handleRejoinGame}>
                          {t('게임 참여하기')}
                        </InGameButton>
                      ) : (
                        <InGameButton roomType={room.type} bb={blind.big} dark noAction checked={myInfo?.blindWait} onChecked={handleToggleBlindWait}>
                          {t('블라인드 대기')}
                        </InGameButton>
                      )
                    }
                  </JoinButtonWrapper>
                )
              }
              {
                showJoinButtonTournament && (
                  <JoinButtonWrapper>
                    {
                      // isRetired ? (
                      //   <InGameButton roomType={room.type} onClick={handleRejoinGame}>
                      //     게임 참여하기
                      //   </InGameButton>
                      // ) : null
                    }
                  </JoinButtonWrapper>
                )
              }
            </InGameButtonWrapper>
          )
        }
      </GameTable>

      {
        isRestTime && (
          <RestTimePopup>{t('Break Time')}</RestTimePopup>
        )
      }

    </Wrapper>
    {
      (
        (myInfo?.status === ROOM_JOIN_STATUS.PLAYING || myInfo?.status === ROOM_JOIN_STATUS.FOLD) &&
        showOpenButton.current &&
        !isObserve
      ) &&
      <WaitButtonWrapper isCenter={true}>
        <OpenCardButton all={false} disabled={cardOpenStatus.left} onClick={() => handleOpenCard('left')}>
          <div className='dim'/>
          <div className='text'><img src='/new-image/open_eye.png'/>{t("오픈")}</div>
          <div className='card-row'>
            <div className='card-wrapper'>
              {
                myCards && myCards.length > 0 && <>
                  <span>{numberToCardData(myCards[0]).cardNumber}</span>
                  <img src={`/new-image/${numberToCardData(myCards[0]).image}`}/>
                </>
              }
            </div>
          </div>
        </OpenCardButton>
        <OpenCardButton all={false} disabled={cardOpenStatus.right} onClick={() => handleOpenCard('right')}>
          <div className='dim'/>
          <div className='text'><img src='/new-image/open_eye.png'/>{t("오픈")}</div>
          <div className='card-row'>
            <div className='card-wrapper'>
              {
                myCards && myCards.length > 0 && <>
                  <span>{numberToCardData(myCards[1]).cardNumber}</span>
                  <img src={`/new-image/${numberToCardData(myCards[1]).image}`}/>
                </>
              }
            </div>
          </div>
        </OpenCardButton>
        <OpenCardButton all={true} disabled={cardOpenStatus.left && cardOpenStatus.right}
                        onClick={() => handleOpenCard('all')}>
          <div className='dim'/>
          <div className='text'><img src='/new-image/open_eye.png'/>{t("오픈")}</div>
          <div className='card-row'>
            <div className='card-wrapper'>
              {
                myCards && myCards.length > 0 && <>
                  <span>{numberToCardData(myCards[0]).cardNumber}</span>
                  <img src={`/new-image/${numberToCardData(myCards[0]).image}`}/>
                </>
              }
            </div>
            <div className='card-wrapper'>
              {
                myCards && myCards.length > 0 && <>
                  <span>{numberToCardData(myCards[1]).cardNumber}</span>
                  <img src={`/new-image/${numberToCardData(myCards[1]).image}`}/>
                </>
              }
            </div>
          </div>
        </OpenCardButton>
      </WaitButtonWrapper>
    }
    {/*
      !isObserve && <EmojiButtonWrapper drawerOpen={
        showInfo || showHistory || isRestTime || buyInSeat !== -1 || profileUserId !== -1
      }>
        <div className='button button-style' onClick={() => {
          setShowEmoji(!showEmoji)
        }}>
          <img src={showEmoji ? '/new-image/emoji/ic_emojiClosed.png' : '/new-image/emoji/ic_emoji.png'}/>
        </div>
        {
          showEmoji && <div className='emoji-wrapper'>
            {
              EMOJI_LIST.map((v, i) => {
                return <div className='emoji button-style' key={i} onClick={() => sendEmoji(i + 1)}>
                  <img src={'/new-image/emoji/gif_preview/' + v + ".png"}/>
                </div>
              })
            }
          </div>
        }
      </EmojiButtonWrapper>
    */}
  </>;
}
