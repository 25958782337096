import styled from "styled-components";
import {useTranslation} from "react-i18next";
import React, {useMemo} from "react";
import { chipToCurrency, UserCurrencyMark, UserToCurrency } from "../../conversionChip";
import { useRecoilState } from "recoil";
import { myInfoState } from "../../recoil/MyInfo";
import DigitsColorWrapper from "../DigitsColor";

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 0 8px 0;
  position: relative;
  min-width: 350px;

  .message {
    line-height: 150%;
    margin-top: 20px;
    font-size: 15px;
    white-space: pre-wrap;
  }

  .tip {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.5;
    margin-top: 8px;
    line-height: 1.5;
  }

  .border {
    width: 100%;
    min-height: 1px;
    background-color: #2D2D2D;
    margin: 16px 0;
  }
`;

const Hand = styled.div<{
  lack: boolean
}>`
  position: absolute;
  left: 0;
  right: 0;
  transform: translateY(-70px);
  >div{
    display: inline-flex;
    padding: 5px 10px;
    border-radius: 10px;
    background: rgba(255, 37, 37, 0.20);
    border: 1px solid rgba(255, 37, 37, 1);
    margin-bottom: 12px;
    align-items: center;
    justify-content: center;

    ${p => !p.lack && `
      background: rgba(106, 255, 42, 0.2);
    `};

    img {
      margin-right: 4px;
    }

    span {
      margin-right: 6px;
      font-weight: bold;
    }
  }
`;

const Warning = styled.div`
  margin-top: 20px;
`

const Settlement = styled.div`
  border-radius: 10px;
  background-color: rgba(31, 31, 31, 1);
  text-align: left;
  padding: 15px;

  .stack {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .settle {
    font-size: 16px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    img{
      width: 20px;
    }
    gap: 5px;

    >div{
      display: flex;
      align-items: center;
    }
  }

  >div{
    >span {
      opacity: 0.5;
      margin-right: 4px;

      &.diff {
        opacity: 1 !important;
        padding-left: 4px;

        &[data-loss="true"] {
          color: #FF2525;
        }

        &[data-loss="false"] {
          color: #37FF25;
        }
      }
    }
  }

  .fiat{
    margin-left: 5px;
  }

  .loss{
    color: #FF2525;
    opacity: 1;
  }
`;

const SubInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 13px;
  div{
    span{
      color: #999;

      &:last-child{
        margin-left: 5px;
        color: white;
      }
    }
  }
`;

const Title = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  transform: translateY(-25px);
  font-size: 20px;
  color: white;
  text-align: center;
`

function LeaveSettlement(
  {
    hands,
    stack,
    buyin,
    lossPercentage,
    settle,
    winAmount,
    maxHands,
    cuttingList,
    lack,
  }: {
    hands: number,
    stack: number,
    buyin: number,
    winAmount: number,
    lossPercentage: number,
    settle: number,
    maxHands: number,
    cuttingList: {handCount: number, lossPercentage: number}[],
    lack: boolean,
  }
) {
  const {t} = useTranslation();
  const [myInfo, setMyInfo] = useRecoilState(myInfoState);

  const lossAmount = useMemo(() => {
    return Math.floor(winAmount * (lossPercentage / 100));
  }, [winAmount, lossPercentage]);

  const message = useMemo(() => {
    if (lack) {
      return t('핸드 수 부족으로 인해 정산 시 이익금 중 {{lossPercentage}}%가 차감되어 정산됩니다.', {lossPercentage: lossPercentage});
    } else {
      return t('핸드 수를 충족하여 이익금 100% 정산 가능합니다.')
    }
  }, [lack, lossPercentage]);

  const cuttingListTexts = useMemo(() => {
    const texts = [];
    for(let i=0; i<cuttingList.length - 1; i++) {
      const h = [cuttingList[i].handCount, cuttingList[i+1].handCount];
      const p = cuttingList[i + 1].lossPercentage;
      texts.unshift(
        t('{{h0}} ~ {{h1}} 핸드 시 이익금 중 {{p}}% 차감', {h0: h[0], h1: h[1], p: p})
      )
    }

    return texts;
  }, [cuttingList]);

  return <Wrapper>
    <Title>{t("정산")}</Title>
    <Hand lack={lack}>
      <div>
        {
          lack && (
            <img src="/images/ic_leave_warn.svg"/>
          )
        }
        <span>{t('핸드 수')}</span>{Number(hands||0).toLocaleString()}
      </div>
    </Hand>
    <div className="message">
      {message}
    </div>
    <div className="tip">
      {t('{{maxHands}} 핸드 초과 시 100% 정산 가능', {maxHands: maxHands})}<br/>
      {/*
        cuttingListTexts.map((x, i) => <React.Fragment key={i}>
          {x}<br/>
        </React.Fragment>)
      */}
    </div>
    <div className="border"/>
    <SubInfo>
      <div>
        <span>{t("변환 비율")}{" "}</span>
        <span>{1}{":"}{UserCurrencyMark()}{chipToCurrency(myInfo?.currency ?? 'KRW', 1, 1000).toLocaleString()}</span>
      </div>

      <div>
        <span>{t("현재 보유금")}</span>
        <span>{UserCurrencyMark()} {Number(myInfo?.money||0).toLocaleString()}</span>
      </div>
    </SubInfo>
    <Settlement>
      <div className="stack">
        <span>{t('총 바이인')}</span>{Number(buyin|| 0).toLocaleString()}
        <span className="fiat">({UserCurrencyMark()}<DigitsColorWrapper fontSize={13} number={UserToCurrency(Number(buyin|| 0))}/>)</span>
      </div>
      <div className="stack">
        <span>{t('현재 보유 칩')}</span>{Number(stack|| 0).toLocaleString()}
        <span className="fiat">({UserCurrencyMark()}<DigitsColorWrapper fontSize={13} number={UserToCurrency(Number(stack|| 0))}/>)</span>
      </div>
      <div className="stack">
        <span>{t('이익금')}</span>
        {
          winAmount > 0 ? <span className="diff" data-loss="false">
            +{Number(winAmount|| 0).toLocaleString()}
          </span> : 0
        }
        <span className="fiat">({UserCurrencyMark()}<DigitsColorWrapper fontSize={13} number={UserToCurrency(Number(Math.max(0, winAmount)))}/>)</span>
        {lossAmount > 0 && <span className="loss">-{lossPercentage}% (-{UserCurrencyMark()}<DigitsColorWrapper digitScale={0.85} digitOpacity={0.5} color="#FF2525" number={UserToCurrency(Number(lossAmount))} />)</span>}
      </div>

      <div className="border"/>

      <div className="settle">
        <div>{t('정산 금액')} : </div>
        <div><img src="/images/ic_buyin_chips.svg" style={{marginRight:"5px"}}/> {Number(settle|| 0).toLocaleString()}</div>
        <div>({UserCurrencyMark()}<DigitsColorWrapper fontSize={14} number={UserToCurrency(settle)}/>)</div>

        {/*
          lossAmount > 0 && (
            <span className="diff" data-loss="true">
               ({`-${Number(lossAmount || 0).toLocaleString()})`}
            </span>
          )
        */}
      </div>

    </Settlement>

    <Warning>
      {t('화폐의 최소단위 이하의 금액은 정산되지 않습니다.')}
    </Warning>
  </Wrapper>
}

export default LeaveSettlement;