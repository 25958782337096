import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
// @ts-ignore
import { Hand } from "pokersolver";
import { BET_TYPE, CARD_INFO, GamePlayer, ROOM_JOIN_STATUS, ROOM_TYPE, winnerModel } from "../../dataset";
import ActionTag from "./ActionTag";
import PokerCard from "./PokerCard";
import Const from "../../Const";
import moment from "moment/moment";
import useScreenOrientation, { MEDIA_DESKTOP, MEDIA_MOBILE_MINI } from "../../hooks/useScreenOrientation";
import { parseDatetime } from "../../constants/moment";
import { useRecoilState, useRecoilValue } from "recoil";
import { gameOptionState } from "../../recoil/GameOption";
import WinningRate from "./WinningRate";
import Emoticon from "./Emoticon";
import { betLeftSecState, emoticonByUserId } from "../../recoil/Game";
import TimerProgress from "../common/TimerProgress";
import { playSFX } from "../../utils/sound";
import { useTranslation } from "react-i18next";
import { emoticonBanState } from "../../recoil/MyInfo";
import { PrintAlwaysBB } from "../PrintAlwaysBB";
import { Textfit } from 'react-textfit';


const Wrapper = styled.div<{
  me?: boolean
}>`
  width: ${p => p.me ? 72 : 64}px;
  position: absolute;
  left: -100%;
  top: -100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  color: #FFF;
  
  &[data-winner="true"] {
    z-index: 6;
  }

  @media ${MEDIA_DESKTOP} {
    width: 104px;
    height: 124px;
  }

  &[data-fold="true"] {
    .player-wrapper {
      opacity: 0.6;
      transition: opacity 0.2s linear 1s, filter 0.2s linear 0.5s;

      // TODO: 윈도우 크롬 버그때문에 강제로 리페인팅 하도록 애니메이션 적용함.
      // 버그 해결되면 이부분 지워야 함.. 퍼포먼스 문제 있을 수 있음.
      &::before {
        position: absolute;
        width: 1px;
        height: 1px;
        content: ' ';
        animation: BugSolver 1s infinite;
        background-color: black;
        will-change: transform;

        @keyframes BugSolver {
          to {
            transform: rotate(0.1deg);
          }
        }
      }

      .profileImg {
        border-color: transparent !important;
      }
    }
  }

  > .player-wrapper {
    width: 100%;

    > div.top {
      width: 100%;
      position: relative;

      > .action {
        left: 50%;
        top: 8px;
        transform: translate(-50%, -100%);
        z-index: 10;

        @media ${MEDIA_DESKTOP} {
          top: 6px;
        }
      }

      > .profileImg {
        border: 2px solid #FFF;
        width: ${p => p.me ? 64 : 56}px;
        height: ${p => p.me ? 64 : 56}px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        border-radius: 50%;
        transition: all 0.2s linear;
        margin: 0 auto;
        position: relative;
        cursor: pointer;
        
        ${p => p.me ? `
          width: 64px;
          height: 64px;
        ` : `
          width: 56px;
          height: 56px;
        `};

        @media ${MEDIA_DESKTOP} {
          ${p => p.me ? `
            width: 88px;
            height: 88px;
          ` : `
            width: 80px;
            height: 80px;
          `};
        }

        &[data-betnow=true] {
          border-color: transparent;
        }

        &[data-winner=true] {
          transition: border 0.2s linear;
          border: 4px solid #FFEDAC;
          filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.60));
        }
      }
    }

    > div.bottom {
      width: 100%;
      position: relative;
      cursor: pointer;
      margin-top: -15px;

      > .vpip {
        position: absolute;
        width: 16px;
        height: 16px;
        font-size: 10px;
        font-weight: 500;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #3A3A3A;
        transform: translateY(-14px);
        z-index: 2;

        @media ${MEDIA_DESKTOP} {
          width: 24px;
          height: 24px;
          font-size: 12px;
        }
      }

      > .profileName {
        width: 100%;
        border-radius: 5px;
        background: radial-gradient(95.31% 95.31% at 50% 100%, rgba(182, 182, 182, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), #181A1D;
        padding: 4px 2px;
        transition: all 0.1s linear;
        position: relative;
        z-index: 1;

        @media ${MEDIA_DESKTOP} {
          padding: 6px 10px;
        }

        > .nickname {
          font-size: 10px;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: keep-all;

          @media ${MEDIA_DESKTOP} {
            font-size: 12px;
          }
        }
        
        > .agency {
          font-size: 10px;
          transform: scale(0.7);
          line-height: 1;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        > .stack {
          width: 100%;
          color: #2F99FB;
          text-align: center;
          font-size: 10px;
          font-weight: 600;
          line-height: 1;

          @media ${MEDIA_DESKTOP} {
            font-size: 13px;
          }
        }

        .time-guage {
          height: 4px;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 0;
          border-radius: 0 0 5px 5px;
          overflow: hidden;

          &::after {
            transition: all 1s linear;
          }
        }
      }
    }
  }
`;

const CountryFlag = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(-11px);
  z-index: 10000000;
  img{
    width: 16px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 1);
  }


  @media ${MEDIA_DESKTOP} {
    right: 0;
    bottom: 0;
    transform: translateY(-11px);
    img{
      width: 20px;
    }
  }
`;

const CardDeck = styled.div<{
  show: boolean
  zoom: boolean
  me: boolean
}>`
  position: absolute;
  bottom: 8px;
  right: 2px;
  white-space: nowrap;
  word-break: keep-all;
  display: ${p => p.show ? 'flex' : 'none'};
  /* pointer-events: none; */

  ${p => p.me && `
    bottom: -8px;
    right: -12px;
    transform: translateX(100%);
    gap: 4px;
  `}

  ${p => p.zoom && `
    transition: transform 0.5s linear;
    transform: scale(1.7);
    transform-origin: bottom right;
  `};


  @media ${MEDIA_DESKTOP} {
    bottom: 0;
    
    ${p => p.zoom && `
      transform: scale(1.3);
    `};

    ${p => p.me && `
      bottom: 0;
    `}
  }

  .player-card {
    width: 18px;
    height: 24px;
    transition: all 0.5s;
    opacity: 0;
    will-change: transform;

    &:nth-child(1) {
      transform: translate(5px, 0) rotate(-5deg);
    }

    &:nth-child(2) {
      transform: translate(0px, 0) rotate(5deg);
    }

    @media ${MEDIA_DESKTOP} {
      width: 36px;
      height: 48px;

      &:nth-child(1) {
        transform: translate(15px, 0) rotate(-5deg);
      }

      ${p => p.me && `
        width: 60px;
        height: 80px;
      `}
    }

    ${p => p.me && `
      width: 42px;
      height: 56px;
      
      &:nth-child(1) {
        transform: none;
      }
  
      &:nth-child(2) {
        transform: none;
      }
    `};
  }
`;

const SettingFoldCardOpen = styled.div`
  font-size: 12px;
  padding: 5px 0;
  position: absolute;
  left : 0;
  right: 0;
  top: 15px;
  background-color: rgba(0,0,0,0.9);
  border-radius: 5px;
  cursor: pointer;
  text-align: center;

  &:hover{
    background-color: rgba(0,0,0,0.7);
  }

  &:active{
    background-color: rgba(0,0,0,0.5);
  }

`

const HandRanking = styled.div<{ me?: boolean }>`
  position: absolute;
  top: calc(100% + 2px);
  left: 50%;
  min-width: 100%;
  transform: translateX(-50%) scale(0.9);
  color: #CDCDCD;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: -0.18px;
  white-space: nowrap;
  border-radius: 9px;
  border: 0.5px solid #606060;
  background: #1F1F25;
  padding: 2px 4px;
  
  ${p => p.me ? `
    top: initial;
    bottom: -4px;
    transform: translateX(-50%) translateY(100%) scale(0.9);
  ` : `
    @media ${MEDIA_MOBILE_MINI} {
        top: 100%;
    }
  `};

  @media ${MEDIA_DESKTOP} {
    font-size: 13px;
    letter-spacing: -0.22px;
    transform: translateX(-50%);
    padding: 4px;
    border-radius: 12px;
    bottom: -28px;
  }
`;


const RebuyinAmount = styled.div<{ top: number }>`
  position: absolute;
  top: calc(100% + 2px + ${p => p.top}px);
  left: 50%;
  min-width: 100%;
  transform: translateX(-50%) scale(0.9);
  color: #CDCDCD;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: -0.18px;
  white-space: nowrap;
  border-radius: 9px;
  border: 0.5px solid #606060;
  background: #1F1F25;
  padding: 2px 4px;

  span{
    color: yellow;
  }

  @media ${MEDIA_DESKTOP} {
    font-size: 13px;
    letter-spacing: -0.22px;
    transform: translateX(-50%);
    padding: 4px;
    border-radius: 12px;
    bottom: -28px;
  }
`;


const SeatButton = styled.div<{
  disabled: boolean
}>`
  width: 64px;
  height: 64px;
  cursor: pointer;
  background: url(/images/btn_seat_bg.png) center no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;

  @media ${MEDIA_DESKTOP} {
    width: 80px;
    height: 80px;
    font-size: 16px;
  }

  ${p => p.disabled && `
    display: none;
  `};

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
`;

const LeftReady = styled.div`
  position: absolute;
  top: -2px;
  right: -2px;
  width: 16px;
  height: 16px;
  background: #FFC92C;
  color: #000;
  font-size: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 1;

  img{
    width: 12px;
    height: 12px;
  }

  @media ${MEDIA_DESKTOP} {
    width: 24px;
    height: 24px;
    font-size: 12px;
    
    img{
      width: 20px;
      height: 20px;
    }
  }


`;

const Timer = styled.div<{
  afk: boolean
}>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0 6px 12px rgba(0, 0, 0, 0.40);
  font-size: 16px;
  font-weight: 600;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.4);
  padding-bottom: 5%;
  border-radius: 50%;
  z-index: 1;

  ${p => p.afk && `
    transform: scale(0.8);
    
    
    @media ${MEDIA_DESKTOP} {
      transform: scale(1.2);
    }
  `};

  @media ${MEDIA_DESKTOP} {
    font-size: 24px;
    padding-top: 10px;
  }
`;

const PrizeText = styled.div`
  position: absolute;
  top: -2px;
  left: 0;
  transform: translate(0, -100%);
  right: 0;
  justify-content: center;
  display: flex;

  text-align: center;
  font-size: 10px;
  font-weight: 900;
  background: linear-gradient(180deg, #FFF 0%, #FFC92C 100%);
  background-clip: text;
  align-items: center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0px 2px 4px black);

  @media ${MEDIA_DESKTOP} {
    font-size: 14px;
  }
`;

const AllInFire = styled.img`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  transform: scale(1.35);
`;

const WinnerLight = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transform: scale(3.5);
`;

const StyledWinningRate = styled(WinningRate)`
  position: absolute;
  left: 0;
  bottom: 15px;
  width: 100%;

  @media ${MEDIA_DESKTOP} {
    bottom: 14px;
  }
`;

const EmoticonButton = styled.div`
  position: absolute;
  left: 0;
  bottom: -8px;
  transform: translateX(-100%);
  cursor: pointer;
  padding: 8px 8px;
  line-height: 0;
`;

const EmoticonWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 20px;
  display: flex;
  justify-content: center;
  transform: translateX(-50%);

  @media ${MEDIA_DESKTOP} {
    bottom: 30px;
  }
`;

function Player(
  {
    idx,
    player,
    act,
    cards,
    betNow,
    winners,
    rate,
    disabled,
    communityCards,
    me,
    BB,
    currency,
    rebuyin,
    openCard,
    roomType,
    onClickSeat,
    onClickProfile,
    onClickEmoticon,
    handleOpenCard
  }: {
    openCard: {
      left: boolean;
      right: boolean;
    };
    currency: string;
    idx: number;
    player?: GamePlayer;
    act?: number;
    cards: number[];
    betNow?: boolean;
    winners?: winnerModel[];
    rate?: number;
    disabled?: boolean;
    communityCards: number[];
    me?: boolean;
    roomType: ROOM_TYPE,
    rebuyin: number;
    BB: number;
    onClickSeat?: (s: number) => void;
    onClickProfile?: (userId: number) => void;
    onClickEmoticon?: () => void;
    handleOpenCard: (dir: 'left' | 'right' | 'all', handle?: (left: boolean, right: boolean) => void) => void;
  }
): JSX.Element {
  const { t } = useTranslation();

  const timerRef = useRef<HTMLDivElement>(null);
  const gameOption = useRecoilValue(gameOptionState);
  const [isAfk, setAfk] = useState<boolean>(false);
  const emoticonId = useRecoilValue(emoticonByUserId(player?.userId));
  const betLeftSec = useRecoilValue(betLeftSecState);
  const emoticonBanList = useRecoilValue(emoticonBanState);
  const [innerCommunityCards, setInnerCommunityCards] = useState<number[]>([]);
  const [foldOpenWaitLeft, setFoldOpenWaitLeft] = useState<boolean>(false);
  const [foldOpenWaitRight, setFoldOpenWaitRight] = useState<boolean>(false);
  const [setting, setSetting] = useRecoilState(gameOptionState);

  const orientation = useScreenOrientation();

  const isSeated = useMemo<boolean>(() => {
    return !!player;
  }, [player]);

  const leftSec = useMemo<number | undefined>(() => {
    if (!betNow) {
      return;
    }
    return betLeftSec;
  }, [betNow, betLeftSec]);

  const [winner, prize] = useMemo<[boolean, number]>(() => {
    const ret: [boolean, number] = [false, 0];
    if (!player || !winners || !Array.isArray(winners)) {
      return ret;
    }

    const idx = winners.findIndex(x => x.userId === player?.userId);
    if (idx !== -1) {
      ret[0] = true;
      ret[1] = player.stackSize - player?.prevStackSize;
    }

    return ret;
  }, [player?.userId, winners, player?.prevStackSize]);

  const {
    isPlaying,
    isFold,
    isObserve
  } = useMemo(() => {
    const status = player?.status
    return {
      isFold: act === BET_TYPE.FOLD || status === ROOM_JOIN_STATUS.FOLD,
      isPlaying: status === ROOM_JOIN_STATUS.PLAYING || status === ROOM_JOIN_STATUS.FOLD,
      isObserve: status === ROOM_JOIN_STATUS.OBSERVE || status === ROOM_JOIN_STATUS.BUYIN_READY,
    };
  }, [player?.status, act]);

  const showActionTag = useMemo(() => {
    return !betNow && !winner;
  }, [betNow, winner]);

  const showTimer = useMemo(() => {
    return !!player?.leaveRoomTime || betNow;
  }, [player?.leaveRoomTime, betNow]);

  const showCards = useMemo(() => {
    return Array.isArray(cards) && cards[0] !== undefined && cards[1] !== undefined;
  }, [cards]);

  const handRankingText = useMemo<string>(() => {
    if (cards.length === 2 && !cards.some(x => x === -1)) {
      try{
        return Hand.solve([
          ...cards.map(x => CARD_INFO[x]),
          ...innerCommunityCards.map(x => CARD_INFO[x])
        ]).descr;
      }catch(err){
        console.error(">>>>!!",err)
        return "";
      }
    } else {
      return '';
    }
  }, [cards, innerCommunityCards]);

  const emoticonSize = useMemo<number>(() => {
    if (orientation === 'landscape') {
      return me ? 132 : 112;
    } else {
      return me ? 92 : 72;
    }
  }, [me, orientation]);

  const agencyName = useMemo<string | undefined>(() => {
    if (player?.extraData?.hasOwnProperty('agencyName')) {
      return (player.extraData as { agencyName?: string }).agencyName;
    }
  }, [player?.extraData]);

  const handleClickProfile = useCallback(() => {
    if (player) {
      onClickProfile && onClickProfile(player.userId);
    }
  }, [me, player, onClickProfile]);

  const handleClickSeat = useCallback(() => {
    onClickSeat && onClickSeat(idx);
  }, [idx, onClickSeat]);

  const handleClickEmoticon = useCallback(() => {
    if (me && !emoticonId) {
      onClickEmoticon && onClickEmoticon();
    }
  }, [me, emoticonId, onClickEmoticon]);

  // 프리플랍 이후 카드 오픈 시 족보 반영 지연
  useEffect(() => {
    const ms = communityCards.length === 0 ? 0 : 1800
    const timer = setTimeout(() => {
      setInnerCommunityCards(communityCards)
    }, ms)

    return () => {
      clearTimeout(timer)
    }
  }, [communityCards]);

  useEffect(() => {
    if (isFold && me && (foldOpenWaitLeft || foldOpenWaitRight) && winners && winners.length > 0) {
      handleOpenCard(
        foldOpenWaitLeft && foldOpenWaitRight ? 'all' : (foldOpenWaitLeft ? 'left' : 'right')
        , (left, right) => {
          if (left && right) {
            setFoldOpenWaitLeft(false)
            setFoldOpenWaitRight(false)
          }
        })
    }
    if (!isFold && isPlaying) {
      setFoldOpenWaitLeft(false)
      setFoldOpenWaitRight(false)
    }
  }, [winners, handleOpenCard, me, foldOpenWaitRight, foldOpenWaitLeft, isFold])

  // 장시간 미입력 대기실 이동 타이머
  useEffect(() => {
    if (player?.leaveRoomTime === 'forceFold') {
      setAfk(true);
      return;
    } else {
      setAfk(false);
    }

    if (!player?.leaveRoomTime) {
      return;
    }

    const interval = setInterval(() => {
      if (!timerRef.current) {
        return;
      }

      const leaveRoomTime = parseDatetime(player?.leaveRoomTime);
      const leftSec = leaveRoomTime.unix() - moment().unix();
      if (leftSec >= 0 && timerRef.current.innerText !== leftSec.toString()) {
        timerRef.current.innerText = leftSec.toString();
      }
    }, 200);

    return () => {
      clearInterval(interval);
    };
  }, [player?.leaveRoomTime]);

  // 내 차례 타이머
  useEffect(() => {
    if (!timerRef.current) {
      return;
    } else if (!showTimer || !betNow || betLeftSec === undefined) {
      return;
    }

    timerRef.current.innerText = Math.max(betLeftSec - 1, 0).toString()
  }, [showTimer, betNow, betLeftSec]);

  // 이모티콘 소리 재생
  useEffect(() => {
    if (emoticonId && (me || emoticonBanList.filter(e => e == 0 || e == player?.userId).length == 0)) {
      playSFX(`emoji${emoticonId.toString().padStart(2, '0')}.wav`, true);
    }
  }, [emoticonId, player?.userId, me, emoticonBanList]);

  return <Wrapper
    className={`game-seat seat-${player?.userId}`}
    me={me}
    data-play={isFold || isObserve ? "0" : "1"}
    data-fold={isFold || isObserve || isAfk}
    data-winner={winner}
  >
    {
      (isSeated && player) ? (
        <div className={"player-wrapper"} >
          <div className="top">
            {
              (showActionTag && act) && (
                <ActionTag act={act} />
              )
            }
            <div className="profileImg"
              data-betnow={betNow}
              data-winner={winner}
              style={{ backgroundImage: `url(/images/profile/${player.profileImg}.png)` }}
              onClick={handleClickProfile}
            >
              {
                act === BET_TYPE.ALLIN && (
                  <AllInFire src="/images/effect/fire.png" />
                )
              }
              {
                leftSec !== undefined && (
                  <TimerProgress value={leftSec} max={Const.ACTION_LIMIT_TIME} />
                )
              }
              {
                showTimer && (
                  <Timer ref={timerRef} className="timer" afk={isAfk}>
                    {
                      isAfk ? <div><img src="/images/ic_afk.svg" /></div> : <div />
                    }
                  </Timer>
                )
              }
              {
                player?.left && (
                  <LeftReady>
                    <img src="/images/ic_game_exit.svg" />
                  </LeftReady>
                )
              }
            </div>
            <CountryFlag>{currency ? <img src={"/images/flag_" + currency + ".png"} /> : <></>}</CountryFlag>
            <CardDeck data-winner={Boolean(winner && prize)} className="card-deck" data-inner-seat={idx}
              data-seat={player.seat} show={isPlaying || isFold} me={me!} zoom={!me && showCards}>
              <PokerCard isOpenCard={openCard.left || foldOpenWaitLeft} onClick={() => {
                if (me) {
                  if (isFold && isPlaying && (winners == null || winners.length === 0)) {
                    setFoldOpenWaitLeft(!foldOpenWaitLeft)
                  } else {
                    handleOpenCard('left')
                  }
                }
              }} data-winner={Boolean(winner && prize)} className="player-card left" card={cards[0]} flip={showCards} delay={100} isShowOpenIcon={me && (isFold || (winners != null && winners.length > 0))} />
              <PokerCard isOpenCard={openCard.right || foldOpenWaitRight} onClick={() => {
                if (me) {
                  if (isFold && isPlaying && (winners == null || winners.length === 0)) {
                    setFoldOpenWaitRight(!foldOpenWaitRight)
                  } else {
                    handleOpenCard('right')
                  }
                }
              }} data-winner={Boolean(winner && prize)} className="player-card right" card={cards[1]} flip={showCards} delay={100} isShowOpenIcon={me && (isFold || (winners != null && winners.length > 0))} />
              {
                // 내 족보 텍스트
                (me && handRankingText) && (
                  <HandRanking me={me}>{handRankingText}</HandRanking>
                )
              }
            </CardDeck>
            {
              winner && <>
                <WinnerLight src="/images/effect/win.png" />
                {
                  prize > 0 && (
                    <PrizeText>
                      <Textfit mode="single" max={orientation === 'landscape' ? 14 : 10}>
                        {(Number(prize) > 0 ? '+' : '')}
                        {PrintAlwaysBB(roomType, setting.alwaysBB, Number(prize), BB)}
                      </Textfit>
                    </PrizeText>
                  )
                }
              </>
            }
            <StyledWinningRate rate={rate} />
          </div>
          <div className="bottom" onClick={handleClickProfile}>
            <div className="vpip">{Math.floor(player.vpip * 100)}</div>
            <div className="profileName">
              <div className="nickname">{player.nickname}</div>
              {
                agencyName && (
                  <div className="agency">#{agencyName}</div>
                )
              }
              <div className="stack">
                <Textfit mode="single" max={orientation === 'landscape' ? 13 : 10}>
                  {PrintAlwaysBB(roomType, setting.alwaysBB, player.stackSize, BB)}
                </Textfit>
              </div>
            </div>
            {
              // 다른 플레이어 족보 텍스트
              (!me && !!handRankingText) && (
                <HandRanking>{handRankingText}</HandRanking>
              )
            }
            {
              rebuyin > 0 && (
                <RebuyinAmount top={(!me && !!handRankingText) ? 15 : 0}>{t("추가 바이인")}<br /><span>+{PrintAlwaysBB(roomType, setting.alwaysBB,rebuyin,BB)}</span></RebuyinAmount>
              )
            }
          </div>
        </div>
      ) : (
        <SeatButton disabled={disabled!} onClick={handleClickSeat}>Seat</SeatButton>
      )
    }
    {
      me && (
        <EmoticonButton onClick={handleClickEmoticon}>
          <img src="/images/ic_emoticon.svg" />
        </EmoticonButton>
      )
    }
    {
      emoticonId && (me || emoticonBanList.filter(e => e == 0 || e == player?.userId).length == 0) && (
        <EmoticonWrapper>
          <Emoticon id={emoticonId} size={emoticonSize} />
        </EmoticonWrapper>
      )
    }
  </Wrapper>;
}

export default Player;
