import styled from "styled-components";
import {numberToCard} from "../../utils/common";
import { t } from "i18next";

const MultiTableButtonWrapper = styled.div<{ selected: boolean; isLighting: boolean }>`
  width: 52px;
  height: 28px;
  flex-shrink: 0;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  ${p => p.selected ? `
    border: 1px solid #FFF;
    background: radial-gradient(51.79% 51.79% at 50% 30.36%, rgba(255, 255, 255, 0.20) 0%, rgba(217, 217, 217, 0.00) 100%, rgba(217, 217, 217, 0.00) 100%), #181A1D;
  ` : `
    background: #181A1D;
  `};

  @keyframes blink {
    0% {
      border: 1px solid rgba(47, 153, 251, 1);
    }
    50% {
      //0
      border: 1px solid rgba(47, 153, 251, 0);
    }
    100% {
      border: 1px solid rgba(47, 153, 251, 1);
    }
  }

  ${p => p.isLighting ? `
     animation: blink 1s linear infinite;
  ` : ``};
  > .hand {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;

    > .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > img {
        width: 8px;
        height: 8px;
      }

      > span {
        color: #FFF;
        text-align: center;
        font-family: Pretendard;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.2px;
      }
    }
  }

  > .pot {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1px;
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
  }

  > .progress {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 28px;
    height: 3px;
    background: #4D4D4D;
    border-radius: 1px;

    > .progress-bar {
      height: 100%;
      background: #2F99FB;
      border-radius: 1px;
      transition: width 1s linear;
    }
  }
`

export const MultiTableAddButton = styled(MultiTableButtonWrapper)`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  > img {
    width: 20px;
    height: 20px;
  }

`

interface MultiTableButtonProps {
  selected: boolean;
  progress?: number;
  showPot: boolean;
  showProgress: boolean;
  hand?: number[];
  pot?: number;
  onClick: () => void;
  isPlaying: boolean
}

const MultiTableButton = ({isPlaying, showProgress, showPot, selected, progress, hand, pot, onClick}: MultiTableButtonProps) => {
  return <MultiTableButtonWrapper isLighting={showProgress} selected={selected} onClick={onClick}>
    {
      isPlaying ? <>
        {
          showPot ? <>
            {
              pot ? <div className="pot">
                <span>P</span>
                <span>{pot.toLocaleString()}</span>
              </div> : <div className='pot'>
                {pot}
              </div>
            }
          </> : <>
            {
              (hand && hand.length > 0) ? <div className="hand">
                {hand.map((v, i) => {
                  const {suit, value} = numberToCard(v);
                  return <div className="item" key={i}>
                    <img src={`/images/card_icon/_${suit.toLowerCase()}.svg`} alt="card"/>
                    <span>{value}</span>
                  </div>
                })}
              </div> : <div className="pot">
                <span>{t("대기중")}</span>
              </div>
            }
          </>
        }
        {
          (showProgress && progress && progress > 0 )&& <div className="progress">
            <div className="progress-bar" style={{width: `${Math.max(progress, 0)}%`}}></div>
          </div>
        }
      </> : <div className="pot">
        <span>{t("대기중")}</span>
      </div>
    }

  </MultiTableButtonWrapper>
}

export default MultiTableButton;
