import styled from "styled-components";

const BaccaratWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: radial-gradient(50.53% 28.41% at 49.87% 100%, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%), #101012;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 12px;

  > .video-wrapper {
    position: relative;
    width: 100%;
    aspect-ratio: 16/8;
    background: #2A2E3D;
    >.next-game {
      position: absolute;
      bottom: 2px;
      left: 0;
      width: 100%;
      padding: 8px 10px;
      background: rgba(24, 26, 29, 0.50);
      color: #FFF;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.2px;
      text-align: center;
    }
    > .timer {
      position: absolute;
      top: 12px;
      right: 12px;
      width: 48px;
      height: 48px;
    }

    > .horizontal-line {
      left: 0;
      bottom: 0;
      width: 100%;
      position: absolute;
      height: 6px;
      background: linear-gradient(0deg, #101012 0%, rgba(16, 16, 18, 0.00) 100%);
    }
    >.card-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      justify-content: center;
      >.card-list{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        >.title {
          color: #FFF;
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.165px;
        }
        >.card-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 4px;
          >img {
            width: 40px;
          }
        }
      }
    }
  }

  > .game-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    padding: 4px;
    > .game-status-bar-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      >.hint-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 4px;
        >.wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 2px;
          padding: 1px 6px;
          border-radius: 20px;
          >span {
            color: #FFF;
            font-family: Pretendard;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.165px;
          }
        }
        >.wrapper.player {
          background: rgba(54, 122, 255, 0.10);
        }
        >.wrapper.banker {
          background: rgba(255, 54, 54, 0.10);
        }
      }
      > .game-status-bar {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;

        > .item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 4px;

          > .tag {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #FFF;
            text-align: center;
            text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
            font-family: Pretendard;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.165px;
            width: 15px;
            height: 15px;
            border-radius: 50%;
          }

          > .tag.player {
            background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.10) 100%), #367AFF;
          }

          > .tag.banker {
            background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.10) 100%), #FF3636;
          }

          > .tag.tie {
            background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.10) 100%), #21B02F;
          }

          > span {
            color: #FFF;
            text-align: center;
            font-family: Pretendard;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.165px;
          }

        }
      }
    }
      

    > .score-board-wrapper {
      padding: 5px;
      aspect-ratio: 344/137;
      max-height: 300px;
      width: 100%;
      display: flex;
      >div{
        display: flex;
        flex:1;
        border-radius: 5px;
        padding: 5px;
        overflow: hidden;
        background: white;
        >div{
          border: 1px solid #999;
          border-radius: 5px;
          overflow: hidden;
          flex-shrink: 0;
          flex:1;
          
          display: flex;
          flex-direction: column;
        }
      }
    }

    > .betting-wrapper {
      margin-top: 4px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      gap: 3px;
      >.row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        gap: 3px;
        &:first-child{
          flex-grow: 1;
        }
      }
    }
  }
`;

export default BaccaratWrapper;
