import styled, {keyframes} from "styled-components";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import { useEffect, useMemo, useState } from "react";
import { requestNoticeIds } from "../../api";
import { GroupInfo, ROOM_TYPE } from "../../dataset";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { parseDatetime } from "../../constants/moment";
import { calcLevel, calcPlayTime } from "../../utils/tournament";

const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  >.row {
    display: flex;
    justify-content: space-around;
    padding-bottom: 15px;

    @media ${MEDIA_DESKTOP} {
      width: 500px;
      justify-content: space-between;
      margin: 0 auto;
      margin-bottom: 32px;
    }
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;

  @media ${MEDIA_DESKTOP} {
    padding: 0 10px;
  }

  .icon-wrapper {
    width: 74px;
    height: 74px;
    padding: 13px;

    img {
      transition: all 0.2s linear;
    }
  }

  .text {
    color: #A5A5A5;
    font-size: 11px;
    font-weight: 600;
    margin-top: -8px;
    transition: color 0.2s linear;

    @media ${MEDIA_DESKTOP} {
      font-size: 14px;
    }
  }

  &:hover {
    .icon-wrapper {
      img {
        filter: brightness(2);
      }
    }

    .text {
      color: #FFF;
    }
  }

  &[data-selected="true"] {
    .icon-wrapper {
      background-image: url(/images/menu_selected.svg);
      background-position: center;

      img {
        filter: brightness(2);
      }
    }

    .text {
      color: #2F99FB;
      text-shadow: 0px 1px 4px rgba(0, 87, 161, 0.50);
      font-weight: 600;
    }
  }
`;

//ripple animation
const RippleAnimation = keyframes`
0% {
  transform: scale(1);
  opacity: 0.4;
}
100% {
  transform: scale(3);
  opacity: 0;
}
`;

const Ripple = styled.div<{ color:string|undefined, delayed: number }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${p=>p.color || "#906CEE"};
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0.2;
  
  animation: ${RippleAnimation} 2s linear infinite;
  animation-delay: ${props => props.delayed}s;
  pointer-events: none;
`;

function deltaTime(start: string){
  const now = moment.utc().unix() * 1000;
  const startAt = moment.utc(start).unix() * 1000;
  const diff = Math.abs(now - startAt);
  const sec = Math.floor(diff / 1000);
  const min = Math.floor(sec / 60);
  const hour = Math.floor(min / 60);
  return {
    text:`${hour.toString().padStart(2,"0")}:${(min%60).toString().padStart(2,"0")}:${(sec%60).toString().padStart(2,"0")}`,
    delta : now - startAt
  };
}

function TournamentStatus({
  tournament
}:{tournament:GroupInfo}){
  const [forceUpdate, setForceUpdate] = useState<number>(0);
  const {t} = useTranslation();

  useEffect(()=>{
    let timer = setInterval(()=>{
      setForceUpdate(Date.now());
    }, 1000);
    return () => {
      clearInterval(timer);
    }
  },[])

  const {text, delta} = deltaTime(tournament.data.startedAt);
  const curLevel = useMemo<Number>(()=>{
    if(tournament.data == null) return 0;

    const startedAt = parseDatetime(tournament.data.startedAt);
    const {
      playTimeSeconds,
      restTimeSeconds
    } = tournament.data.timeStructure;
    const playTime = calcPlayTime(startedAt, playTimeSeconds, restTimeSeconds);

    return calcLevel(playTime, tournament.data.blindStructure);
  },[tournament.data, delta])

  if(tournament.isEnd) return <></>;
  if(curLevel >= tournament.data.availableRegisterLevel) return <></>;

  return <>
    <div>
    {delta > 0 ?
      <div style={{color:'#b2ff89', fontSize:'12px', fontWeight:'bold'}}>{text} {t("경과")}</div> :
      <div style={{color:'#ffaa00', fontSize:'12px', fontWeight:'bold'}}>{text} {t("후 시작")}</div>
    }
    </div>
  </>
}

function Menu(
  {
    current,
    onClickMenu,
    tournamentGroups
  }: {
    current: string,
    tournamentGroups:GroupInfo[],
    onClickMenu: (menu: string) => void
  }
) {

  const [notices, setNotices] = useState<boolean>(false);
  useEffect(() => {
    requestNoticeIds().then((res) => {
      const r = JSON.parse(localStorage.getItem('notices') || '[]');
      if(res.ids.filter((id: number) => !r.includes(id)).length > 0) {
        setNotices(true);
      }
    });
  }, []);

  const groups = useMemo(()=>{
    let result = [...tournamentGroups];
    result.sort((a:any,b:any)=>{
      return parseDatetime(a.data.startedAt).unix() - parseDatetime(b.data.startedAt).unix();
    });
    return result.filter(v=>!v.isEnd);
  },[tournamentGroups]);

  return <Wrapper>
    <div className='row'>
      <Item data-selected={current === 'cash'} onClick={() => onClickMenu('cash')}>
        <div className="icon-wrapper">
          <img src="/images/menu_cash.svg"/>
        </div>
        <div className="text">CASH GAME</div>
      </Item>
      <Item data-selected={current === 'tournament'} onClick={() => onClickMenu('tournament')}>
        <div className="icon-wrapper">
          <img src="/images/menu_tournament.svg"/>
        </div>
        <div className="text">TOURNAMENT</div>
        {groups.map(group => {
          return <TournamentStatus key={group.groupId} tournament={group}/>
        })}
        {tournamentGroups.filter(v=>!v.isEnd).length  >0 && <>
          <Ripple color={"#ffaa00"} delayed={0}/>
          <Ripple color={"#ffaa00"} delayed={0.7}/>
          <Ripple color={"#ffaa00"} delayed={1.4}/>
        </>}
      </Item>
      <Item data-selected={current === 'baccarat'} onClick={() => onClickMenu('baccarat')} style={{display:"none"}}>
        <div className="icon-wrapper">
          <img src="/images/menu_baccarat.svg"/>
        </div>
        <div className="text">BACCARAT</div>
      </Item>
      <Item data-selected={current === 'notice'} onClick={() => onClickMenu('notice')}>
        <div className="icon-wrapper">
          <img src="/images/menu_event.svg"/>
        </div>
        <div className="text">NOTICE</div>
        {notices ? <>
          <Ripple color="" delayed={0}/>
          <Ripple color="" delayed={0.7}/>
          <Ripple color="" delayed={1.4}/>
        </> : null}
      </Item>
      <Item data-selected={current === 'setting'} onClick={() => onClickMenu('setting')}>
        <div className="icon-wrapper">
          <img src="/images/menu_setting.svg"/>
        </div>
        <div className="text">SETTING</div>
      </Item>
    </div>
  </Wrapper>
}

export default Menu;
