import {useCallback, useEffect, useRef} from "react";
import {wait} from "../utils/common";
import {GamePlayer, ROOM_JOIN_STATUS, winnerModel} from "../dataset";
import useScreenOrientation from "./useScreenOrientation";
import {playSFX, Sounds} from "../utils/sound";
import {minimumChips, moveChipsSound} from "../utils/chip";

const TABLE_PORTRAIT_MINI_ORIGIN_WIDTH = 370;
const TABLE_PORTRAIT_MINI_ORIGIN_HEIGHT = 567;

const TABLE_PORTRAIT_ORIGIN_WIDTH = 375;
const TABLE_PORTRAIT_ORIGIN_HEIGHT = 667;

const TABLE_LANDSCAPE_ORIGIN_WIDTH = 1080;
const TABLE_LANDSCAPE_ORIGIN_HEIGHT = 720;

const RECTS_SEATS_PORTRAIT_MINI = [
  [0.24, 0.79],
  [0.11, 0.58],
  [0.14, 0.40],
  [0.19, 0.23],
  [0.38, 0.14],
  [0.62, 0.14],
  [0.82, 0.23],
  [0.87, 0.40],
  [0.89, 0.58],
];

const RECTS_DEALER_BUTTON_PORTRAIT_MINI = [
  [0.33, 0.69],
  [0.23, 0.60],
  [0.25, 0.40],
  [0.30, 0.275],
  [0.47, 0.23],
  [0.51, 0.23],
  [0.71, 0.275],
  [0.75, 0.40],
  [0.77, 0.60],
];
const RECTS_PLAYER_POT_PORTRAIT_MINI = [
  [0.48, 0.70],
  [0.34, 0.63],
  [0.29, 0.45],
  [0.33, 0.325],
  [0.40, 0.26],
  [0.60, 0.26],
  [0.67, 0.325],
  [0.71, 0.45],
  [0.66, 0.63],
];
const COMMUNITY_CARDS_Y_PORTRAIT_MINI = 0.53;
const MY_CARDS_Y_PORTRAIT_MINI = 0.8;
const POTS_Y_PORTRAIT_MINI = 0.39;
const TABLE_HOLE_Y_PORTRAIT_MINI = 0.52;

const RECTS_SEATS_PORTRAIT = [
  [0.25, 0.77],
  [0.09, 0.6],
  [0.11, 0.35],
  [0.16, 0.18],
  [0.38, 0.1],
  [0.62, 0.1],
  [0.84, 0.18],
  [0.89, 0.35],
  [0.91, 0.56],
];

const RECTS_DEALER_BUTTON_PORTRAIT = [
  [0.29, 0.66],
  [0.20, 0.55],
  [0.24, 0.41],
  [0.28, 0.23],
  [0.47, 0.16],
  [0.53, 0.16],
  [0.72, 0.23],
  [0.76, 0.41],
  [0.80, 0.55],
];
const RECTS_PLAYER_POT_PORTRAIT = [
  [0.48, 0.67],
  [0.27, 0.61],
  [0.29, 0.36],
  [0.3, 0.26],
  [0.4, 0.19],
  [0.6, 0.19],
  [0.7, 0.26],
  [0.71, 0.36],
  [0.73, 0.58],
];
const COMMUNITY_CARDS_Y_PORTRAIT = 0.485;
const MY_CARDS_Y_PORTRAIT = 0.8;
const POTS_Y_PORTRAIT = 0.3;
const TABLE_HOLE_Y_PORTRAIT = 0.5;

const RECTS_SEATS_LANDSCAPE = [
  [0.36, 0.77],
  [0.05, 0.56],
  [0.07, 0.33],
  [0.27, 0.15],
  [0.5, 0.15],
  [0.73, 0.15],
  [0.93, 0.33],
  [0.95, 0.56],
  [0.64, 0.77],
];
const RECTS_DEALER_BUTTON_LANDSCAPE = [
  [0.33, 0.62],
  [0.19, 0.59],
  [0.17, 0.41],
  [0.32, 0.26],
  [0.47, 0.26],
  [0.68, 0.26],
  [0.82, 0.42],
  [0.81, 0.60],
  [0.58, 0.62],
];
const RECTS_PLAYER_POT_LANDSCAPE = [
  [0.42, 0.62],
  [0.19, 0.55],
  [0.22, 0.38],
  [0.28, 0.28],
  [0.52, 0.26],
  [0.74, 0.28],
  [0.80, 0.38],
  [0.82, 0.55],
  [0.64, 0.62],
];
const COMMUNITY_CARDS_Y_LANDSCAPE = 0.487;
const MY_CARDS_Y_LANDSCAPE = 0.8;
const POTS_Y_LANDSCAPE = 0.368;
const TABLE_HOLE_Y_LANDSCAPE = 0.5;


function useGameLayout(
  {
    dealerIndex,
    maxTableMember,
    pots
  }: {
    dealerIndex: number
    maxTableMember: number
    pots: number[]
  }
) {
  const tableRectRef = useRef<{
    x: number,
    y: number,
    w: number,
    h: number
  }>({
    x: 0,
    y: 0,
    w: 0,
    h: 0
  });
  const prevDealerIndex = useRef<number>(dealerIndex);

  const orientation = useScreenOrientation();

  const rearrangeLayout = () => {
    const container = document.getElementsByClassName('game-table')[0] as HTMLDivElement;
    if (!container || container.offsetHeight == 0) {
      return;
    }
    const isPortrait = orientation === 'portrait' || orientation === 'portrait_mini';
    let tableOriginWidth = 0;
    let tableOriginHeight = 0;
    if(orientation === 'portrait_mini') {
      tableOriginWidth = TABLE_PORTRAIT_MINI_ORIGIN_WIDTH;
      tableOriginHeight = TABLE_PORTRAIT_MINI_ORIGIN_HEIGHT;
    }else if(orientation === 'portrait') {
      tableOriginWidth = TABLE_PORTRAIT_ORIGIN_WIDTH;
      tableOriginHeight = TABLE_PORTRAIT_ORIGIN_HEIGHT;
    }else if(orientation === 'landscape') {
      tableOriginWidth = TABLE_LANDSCAPE_ORIGIN_WIDTH;
      tableOriginHeight = TABLE_LANDSCAPE_ORIGIN_HEIGHT;
    }

    let width = container.offsetWidth;
    let height = container.offsetHeight;

    const containerAspect = container.offsetWidth / container.offsetHeight;
    const originAspect = tableOriginWidth / tableOriginHeight;
    if (containerAspect > originAspect) {
      width = height * (tableOriginWidth / tableOriginHeight);
    } else if (containerAspect < originAspect) {
      height = width * (tableOriginHeight / tableOriginWidth);
    }

    const left = container.offsetLeft + (container.offsetWidth - width) / 2;
    const top = container.offsetTop + (container.offsetHeight - height) / 2;
    const cx = left + width / 2;
    const cy = top + height / 2;

    tableRectRef.current = {
      x: left,
      y: top,
      w: width,
      h: height
    };

    const scale = Math.min(width / tableOriginWidth, isPortrait  ? (orientation === 'portrait_mini' ? 0.84 : 1.5) :  1.5);

    function position(arr: any, idx: number, left: number, top: number) {
      try {
        arr[idx].style.transform = "scale(" + scale + ")";
        arr[idx].style.left = (left - arr[idx].offsetWidth / 2) + "px";
        arr[idx].style.top = (top - arr[idx].offsetHeight / 2) + "px";
      } catch (err) {
      }
    }

    const communityCardsWrapper = document.getElementsByClassName("community-cards-wrapper") as any;
    const fieldPotsWrapper = document.getElementsByClassName("field-pots") as any;
    const myCardsWrapper = document.getElementsByClassName("my-cards-wrapper") as any;
    const dealerButton = document.getElementsByClassName("dealer-button") as any;
    const tableHole = document.getElementsByClassName("table-hole") as any;
    const inGameButtonWrapper = document.getElementsByClassName("ingame-button-wrapper") as any;

    const communityCardsY = isPortrait ? (orientation === 'portrait_mini' ? COMMUNITY_CARDS_Y_PORTRAIT_MINI :COMMUNITY_CARDS_Y_PORTRAIT ) : COMMUNITY_CARDS_Y_LANDSCAPE;
    const myCardsY = isPortrait ? (orientation === 'portrait_mini' ? MY_CARDS_Y_PORTRAIT_MINI :MY_CARDS_Y_PORTRAIT )  : MY_CARDS_Y_LANDSCAPE;
    const fieldPotsY = isPortrait ? (orientation === 'portrait_mini' ? POTS_Y_PORTRAIT_MINI :POTS_Y_PORTRAIT )  : POTS_Y_LANDSCAPE;
    const tableHoleY = isPortrait ? (orientation === 'portrait_mini' ? TABLE_HOLE_Y_PORTRAIT_MINI :TABLE_HOLE_Y_PORTRAIT )  : TABLE_HOLE_Y_LANDSCAPE;

    position(communityCardsWrapper, 0, cx, top + height * communityCardsY);
    position(myCardsWrapper, 0, cx, top + height * myCardsY);
    position(fieldPotsWrapper, 0, cx, top + height * fieldPotsY);
    position(tableHole, 0, cx, top + height * tableHoleY);

    // 인게임 버튼 사이즈 조정
    if (inGameButtonWrapper[0] && inGameButtonWrapper[0].style.transform !== 'none') {
      const buttonScale = Math.min(scale, 1);
      inGameButtonWrapper[0].style.transformOrigin = `right bottom`;
      inGameButtonWrapper[0].style.transform = `scale(${buttonScale}, ${buttonScale})`
    }

    const seats = document.getElementsByClassName("game-seat") as any;
    const pots = document.getElementsByClassName("game-player-pot") as any;
    const seatRects = JSON.parse(JSON.stringify(isPortrait ? (orientation === 'portrait' ? RECTS_SEATS_PORTRAIT : RECTS_SEATS_PORTRAIT_MINI) : RECTS_SEATS_LANDSCAPE));
    const potRects = JSON.parse(JSON.stringify(isPortrait ? (orientation === 'portrait' ? RECTS_PLAYER_POT_PORTRAIT : RECTS_PLAYER_POT_PORTRAIT_MINI) : RECTS_PLAYER_POT_LANDSCAPE));
    const dealerBtnRects = JSON.parse(JSON.stringify(isPortrait ? (orientation === 'portrait' ? RECTS_DEALER_BUTTON_PORTRAIT : RECTS_DEALER_BUTTON_PORTRAIT_MINI): RECTS_DEALER_BUTTON_LANDSCAPE));

    let deleteSeatIndices: number[] = [];
    if (maxTableMember === 5) {
      deleteSeatIndices = isPortrait ? [6, 5, 4, 3] : [8, 7, 6, 5];
    } else if (maxTableMember === 6) {
      deleteSeatIndices = isPortrait ? [6, 5, 4] : [7, 6, 5];
    } else if (maxTableMember === 8) {
      if (isPortrait) {
        // 8자리일 때는 모바일에서만 최상단 한 자리 삭제
        seatRects[4][0] = 0.5;
        potRects[4][0] = 0.5;
        dealerBtnRects[4][0] = 0.5;
        deleteSeatIndices = [5];
      }
    }

    for (let seatIndex of deleteSeatIndices) {
      seatRects.splice(seatIndex, 1);
      potRects.splice(seatIndex, 1);
      dealerBtnRects.splice(seatIndex, 1);
    }

    // 좌석과 팟 위치 설정
    for (let i = 0; i < maxTableMember; i++) {
      position(seats, i, left + width * seatRects[i][0], top + height * seatRects[i][1]);
      position(pots, i, left + width * potRects[i][0], top + height * potRects[i][1]);
    }

    // 딜러버튼 위치 설정
    if (dealerIndex !== -1) {
      // 딜러버튼의 위치가 바뀐 경우 transition 활성화
      if (prevDealerIndex.current !== dealerIndex) {
        playSFX(Sounds.SFX_DEALER_BUTTON_MOVE);
        dealerButton[0].style.transition = 'all 1s ease-in-out';
        prevDealerIndex.current = dealerIndex;
      } else if (dealerButton[0].style.transition !== '') {
        dealerButton[0].style.transition = '';
      }
      position(dealerButton, 0, left + width * dealerBtnRects[dealerIndex][0], top + height * dealerBtnRects[dealerIndex][1]);
    } else {
      position(dealerButton, 0, cx, cy);
    }
  };

  const coinMove = useCallback(async (from: DOMRect, to: DOMRect, amount: number) => {
    const wrapper = document.querySelector(".coin-move-wrapper");
    if (!wrapper) {
      return;
    }

    const chips = minimumChips(amount);
    for (let {size, amount} of chips) {
      for (let i = 0; i < amount; i++) {
        const div = document.createElement("div");
        div.className = "move-coin";
        div.style.width = (orientation === 'portrait' ? 14 : 20) + 'px';
        div.style.height = (orientation === 'portrait' ? 13 : 19) + 'px';
        div.style.backgroundImage = `url(/images/chip_${size}.png)`;
        div.style.transform = `translate(${from.x + from.width / 2 - 7}px,${from.y + from.height / 2 - 7}px)`;
        wrapper.append(div);

        setTimeout(async () => {
          try {
            await wait(10);
            div.style.transform = `translate(${to.x + to.width / 2 - 7}px,${to.y + to.height / 2 - 7}px)`;
            await wait(200);
            div.style.opacity = "0";
            await wait(200);
            div.remove();
          } finally {
          }
        }, 10);

        await wait(50);
      }

    }
  }, [orientation]);

  const chipsMove = useCallback((source: HTMLElement, to: DOMRect) => {
    const wrapper = document.querySelector(".coin-move-wrapper");
    if (!wrapper) {
      return;
    }

    const div = document.createElement("div");
    div.innerHTML = source.outerHTML;
    div.className = "move-coin";

    const from = source.getBoundingClientRect();
    const scale =  from.width / source.clientWidth;

    div.style.transform = `translate(${from.x}px,${from.y}px) scale(1)`;
    const child = div.firstElementChild as HTMLElement | null
    if (child) {
      child.style.transform = `scale(${scale})`
      child.style.transformOrigin = '0% 0%';
    }

    wrapper.append(div);

    (async () => {
      try {
        await wait(10);
        div.style.transform = `translate(${to.x + to.width / 2 - from.width / 2}px,${to.y + to.height / 2 - from.height / 2}px) scale(${scale * 0.5})`;
        await wait(500);
        div.remove();
      } finally {
      }
    })();
  }, [orientation]);

  const moveCoinToGamePot = useCallback(() => {
    const fieldPot = document.querySelector(".field-pot");
    if (fieldPot) {
      const potElems = [...document.querySelectorAll(".game-player-pot .chips") as any];
      for (let potElem of potElems) {
        const amount = Number(potElem.getAttribute('data-amount'));
        if (amount > 0) {
          playSFX(moveChipsSound(amount));
          chipsMove(
            potElem,
            fieldPot.getBoundingClientRect(),
          );
        }
      }
    }
  }, []);

  const moveCoinAnte = useCallback(async (userId: number, amount: number, delay: number = 0) => {
    await wait(delay);
    const seat = document.querySelector(`.seat-${userId}`);
    const fieldPot = document.querySelector(".field-pot");
    if (seat && fieldPot) {
      playSFX(moveChipsSound(amount));
      await coinMove(
        seat.getBoundingClientRect(),
        fieldPot.getBoundingClientRect(),
        amount
      );
    }
  }, []);

  const moveCoinRake = useCallback(async (userId: number, amount: number, delay: number = 0) => {
    await wait(delay);
    const seat = document.querySelector(`.seat-${userId}`);
    const tableHole = document.querySelector(".table-hole");
    if (seat && tableHole) {
      playSFX(moveChipsSound(amount));
      await coinMove(
        seat.getBoundingClientRect(),
        tableHole.getBoundingClientRect(),
        amount,
      );
    }
  }, []);

  const moveCoinToWinners = useCallback((winners: winnerModel[]) => {
    const fieldPot = document.querySelector(".field-pot.chips") as HTMLElement | null;
    if (fieldPot) {
      for (let winner of winners) {
        const seat = document.querySelector(`.seat-${winner.userId}`);
        if (seat) {
          playSFX(moveChipsSound(winner.amount));
          chipsMove(
            fieldPot,
            seat.getBoundingClientRect(),
          );
        }
      }

      fieldPot.setAttribute('data-amount', '0');
    }
  }, []);
  const getAllCardElements = useCallback((): NodeListOf<HTMLElement> => {
    return document.querySelectorAll('.card-deck > .player-card') as NodeListOf<HTMLElement>;
  }, []);

  const getAllDeckElements = useCallback((): NodeListOf<HTMLElement> => {
    return document.querySelectorAll('.card-deck') as NodeListOf<HTMLElement>;
  }, []);

  const getAllCommunityCardElements = useCallback(() => {
    return document.querySelectorAll('.community-card') as NodeListOf<HTMLElement>;
  }, []);

  const hideAllCards = useCallback(() => {
    const cardElements = getAllCardElements();
    for (let i = 0; i < cardElements.length; i++) {
      cardElements[i].style.opacity = '0';
    }
  }, []);

  const showAllCards = useCallback(() => {
    const cardElements = getAllCardElements();
    for (let i = 0; i < cardElements.length; i++) {
      cardElements[i].style.opacity = '1';
    }
  }, []);

  const resetPlayersCards = useCallback(() => {
    if (!tableRectRef.current) {
      return;
    }

    const cx = tableRectRef.current.x + tableRectRef.current.w / 2;
    const cy = tableRectRef.current.y + tableRectRef.current.h / 2;
    const decks = getAllDeckElements();

    // 모든 카드를 가운데로 모으기
    for (let i = 0; i < decks.length; i++) {
      const deck = decks[i];
      const cards = deck.querySelectorAll('.player-card') as NodeListOf<HTMLElement>;
      cards[0].style.transition = 'none';
      cards[1].style.transition = 'none';
      cards[0].style.opacity = '0';
      cards[1].style.opacity = '0';
      const deckRect = deck.getBoundingClientRect();
      const x = cx - deckRect.x - cards[0].offsetWidth / 2;
      const y = cy - deckRect.y - cards[0].offsetHeight / 2;
      cards[0].style.transform = `translate(${x}px, ${y}px) rotate(-360deg)`;
      cards[1].style.transform = `translate(${x - cards[1].offsetWidth}px, ${y}px) rotate(-360deg)`;
    }
  }, []);

  const dealCardsToPlayers = useCallback(async (players: GamePlayer[]) => {
    const cards0: HTMLElement[] = [];
    const cards1: HTMLElement[] = [];
    for (let i = 0; i < players.length; i++) {
      if (players[i].status !== ROOM_JOIN_STATUS.PLAYING) {
        continue;
      }

      const seat = players[i].seat;
      const cards = document.querySelectorAll(`.card-deck[data-seat="${seat}"] > .player-card`);
      if (cards.length !== 2) {
        console.error('card elements of seat ' + seat + ' are not found! cards.length : ' + cards.length);
        continue;
      }
      cards0.push(cards[0] as HTMLElement);
      cards1.push(cards[1] as HTMLElement);
    }

    // 카드 딜링 애니메이션 (transform 해제 시키기)
    const cards = [...cards0, ...cards1];
    for (let i = 0; i < cards.length; i++) {
      playSFX(Sounds.SFX_CARD_DIS, true);
      cards[i].style.transition = 'all 200ms linear';
      cards[i].style.opacity = '1';
      cards[i].style.transform = '';
      await new Promise<void>((r) => setTimeout(() => r(), 100));
    }

    {
      // 카드가 혹시라도 이동되지 않는 경우를 대비해 강제적으로 제 위치로 돌려놓는다.
      const cards = getAllCardElements();
      for (let i = 0; i < cards.length; i++) {
        cards[i].style.transition = '';
        cards[i].style.opacity = '1';
        cards[i].style.transform = '';
      }
    }
  }, []);

  useEffect(() => {
    const gameTableElem = document.querySelector('.game-table');
    if (!gameTableElem) {
      return;
    }

    rearrangeLayout();

    gameTableElem.addEventListener('load', rearrangeLayout);
    window.addEventListener('resize', rearrangeLayout);
    window.addEventListener('orientationchange', rearrangeLayout);

    return () => {
      gameTableElem.removeEventListener('load', rearrangeLayout);
      window.removeEventListener('resize', rearrangeLayout);
      window.removeEventListener('orientationchange', rearrangeLayout);
    };
  }, [dealerIndex, maxTableMember, pots, orientation]);

  return {
    tableRect: tableRectRef.current,
    moveCoinToGamePot,
    moveCoinToWinners,
    moveCoinAnte,
    moveCoinRake,
    getAllCardElements,
    getAllDeckElements,
    getAllCommunityCardElements,
    resetPlayersCards,
    hideAllCards,
    showAllCards,
    dealCardsToPlayers,
  };
}

export default useGameLayout;
