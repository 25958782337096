import styled from "styled-components";

const TimerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.165px;
    z-index: 2;
  }

  > svg {
    position: relative;
    border: 3px solid #6EFF7C;
    border-radius: 50%;
    transition: all 1s linear;
    background: #6EFF7C1A;
  }
`

interface TimerProps {
  current: number;
  max: number;
}

const Timer = ({current, max}: TimerProps) => {
  const radius = 16;
  const circumference = 2 * Math.PI * radius;
  const progress = (current / max) * circumference;

  return (
    <TimerWrapper>
      <span>{current}</span>
      <svg width="100%" height="100%" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <circle r={radius} cx="16" cy="16" fill="#6EFF7C1A" stroke="#6EFF7C1A" strokeWidth="32"/>
        <circle
          style={{
            transition: 'all 1s linear',
          }}
          r={radius}
          cx="16"
          cy="16"
          fill="transparent"
          stroke="#6EFF7C80"
          strokeWidth="32"
          strokeDasharray={circumference}
          strokeDashoffset={circumference - progress}
          transform="rotate(-90 16 16)"
        />
      </svg>
    </TimerWrapper>
  );
}

export default Timer;
