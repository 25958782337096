import {Sheet} from "react-modal-sheet";
import styled from "styled-components";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {useEffect, useState} from "react";
import Main from "./Main";
import History from "./History";
import Howto from "./Howto";
import Payout from "./Payout";
import Verification from "./Verification";
import Chat from "./Chat";
import Setting from "./Setting";

const MenuSheetWrapper = styled.div`
  width: 100%;
  border-radius: 12px 12px 0px 0px;
  background: #17171B;
  padding: 16px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.5s ease-in-out;

  div.wrap {
    transition: all 0.3s ease-in-out;
    width: 100%;
    height: 443px;
    max-height: 443px;
    overflow: hidden;
    position: relative;
  }
  .route-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    transition: all 0.3s ease-in-out;
    height: 100%;
  }

  .route-section.transition-wrap-enter-done {
    position: relative;
  }

  .transition-wrap {
    position: absolute;
    left: 15px;
    right: 15px;
    transition: all 0.3s ease-in-out;
  }
  .transition-group{
    height: 100%;
  }
  .transition-group.left .transition-wrap-enter {
    position: absolute;
    opacity: 0;
    transform: translate3d(20px, 0, 0);
  }

  .left .transition-wrap-enter-active {
    opacity: 1;
    transform: translate3d(100%, 0, 0);
    transition: opacity 150ms, transform 1.5s;
  }

  .left .transition-wrap-exit {
    position: absolute;
    opacity: 1;
    //transform: translate3d(-100%, 0, 0);
  }

  .left .transition-wrap-exit-active {
    position: absolute;
    opacity: 0;
    //transform: scale(0.9);
    //transform: translate3d(-100%, 0, 0);
  }

  // right

  .right .transition-wrap-enter {
    position: absolute;
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  .right .transition-wrap-enter-active {
    opacity: 1;
    transform: translate3d(-100%, 0, 0);
    transition: opacity 300ms, transform 1.5s;
  }

  .right .transition-wrap-exit {
    position: absolute;
    opacity: 1;
    transform: translate3d(100%, 0, 0);
  }

  .right .transition-wrap-exit-active {
    position: absolute;
    opacity: 0;
    transform: scale(0.9);
    transform: translate3d(100%, 0, 0);
  }

  > .section {
    width: 100%;
    height: 100%;
  }

  > .close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  > .title {
    width: 100%;
    height: 19px;
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
  }

  > .back {
    position: absolute;
    top: 16px;
    left: 20px;
    cursor: pointer;
    width: 24px;
    height: 24px;

  }
`

interface MenuSheetProps {
  show: boolean;
  title: string;
  onClose: () => void;
}

export type Menu = 'main' | 'lobby' | 'chat' | 'support' | 'history' | 'settings' | 'howto' | 'payout' | 'gameverified'
const MenuSheet = ({show, title, onClose}: MenuSheetProps) => {
  const [menu, setMenu] = useState<Menu>('main');
  useEffect(() => {
    setMenu('main')
  }, [show]);

  useEffect(() => {
    const videoWrapper = document.getElementById('video-wrapper') as HTMLDivElement;
    const wrap = document.getElementById('menu-wrapper') as HTMLDivElement;
    if (videoWrapper && wrap) {
      wrap.style.height = `calc(100svh - ${videoWrapper.clientHeight}px - 38px)`;
      wrap.style.maxHeight = `calc(100svh - ${videoWrapper.clientHeight}px - 38px)`;
    }
  }, [menu, show]);
  return <Sheet
    style={{
      width: '100%'
    }}
    isOpen={show}
    onClose={() => {
    }}
    disableDrag={true}
    detent={'content-height'}
  >
    <Sheet.Container style={{
      background: 'none'
    }}>
      <Sheet.Content>
        <MenuSheetWrapper>
          {menu !== 'main' && <img className='back' src='/images/baccarat/back.svg' onClick={() => setMenu('main')}/>}
          <img className='close' src='/images/baccarat/Close.svg' onClick={onClose}/>
          <div className='title'>
            {menu === 'main' && '메뉴'}
            {menu === 'lobby' && '로비'}
            {menu === 'chat' && '채팅'}
            {menu === 'support' && '지원'}
            {menu === 'history' && '게임 기록'}
            {menu === 'settings' && '설정'}
            {menu === 'howto' && '플레이 방법'}
            {menu === 'payout' && '지급금 & 한도'}
            {menu === 'gameverified' && '임의 게임 검증'}
          </div>
          <div className="wrap" id='menu-wrapper'>
            <TransitionGroup className={`transition-group left`}>
              <CSSTransition
                key={menu}
                timeout={{
                  enter: 150,
                  exit: 150,
                }}
                classNames={"transition-wrap"}
              >
                <div className="route-section fade">
                  {menu === "main" && (
                    <Main onSelectMenu={(menu) => {
                      setMenu(menu)
                    }} title={title}/>
                  )}
                  {menu === 'history' && <History/>}
                  {menu === 'howto' && <Howto/>}
                  {menu === 'payout' && <Payout/>}
                  {menu === 'gameverified' && <Verification/>}
                  {menu === 'chat' && <Chat/>}
                  {menu === 'settings' && <Setting/>}
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </MenuSheetWrapper>
      </Sheet.Content>
    </Sheet.Container>
    <Sheet.Backdrop onTap={onClose}/>
  </Sheet>
}
export default MenuSheet;
