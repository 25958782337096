import styled from "styled-components";
import ToggleSwitch from "./ToggleSwitch";
import {useCallback, useEffect, useMemo, useState} from "react";
import {AlwaysBBType, gameOptionState} from "../recoil/GameOption";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {useTranslation} from "react-i18next";
import { emoticonBanState } from "../recoil/MyInfo";
import { requestToggleEmoticonBan } from "../api";
import { globalLoadingState } from "../recoil/Loading";
import RadioButton from "./common/RadioButton";
import { ROOM_TYPE } from "../dataset";


const SettingItem = styled.div`
  display: flex;
  padding: 0 8px;
  justify-content: flex-end;
  width: 100%;

  .name {
    flex: 1;
  }

  .checkbox-gap {
    padding-left: 10px;
  }
`;

const SettingModalWrapper = styled.div`
  width: 311px;
  padding: 24px 16px 16px;
  border-radius: 12px;
  background: #17171B;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .image-wrapper {
    width: 228px;
    object-fit: contain;
  }

  > .table-image {
    height: 268px;
  }

  > .title-row {
    z-index: 1;
    width: 100%;
    position: relative;
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;

    > .back {
      cursor: pointer;
      padding: 6.5px;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0px;
    }
  }

  > .content {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;

    > .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 12px;
      margin-bottom: 23px;

      > .item {
        width: 54px;
        height: 72px;
        position: relative;

        > img {
          width: 54px;
          height: 72px;
          object-fit: contain;
        }

        > .layer {
          transition: all 0.2s ease-in-out;
          position: absolute;
          width: 56px;
          height: 76px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 5px;
          border: 1px solid var(--Primary-blue, #2F99FB);
          background: rgba(16, 16, 18, 0.50);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          opacity: 0;

          > img {
            width: 16px;
            height: 16px;
          }
        }

        > .selected {
          opacity: 1;
        }
      }
    }

    > .item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > .title {
        color: #FFF;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 21px */
        letter-spacing: -0.2px;
      }

      > .arrow-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;

        > span {
          color: #FFF;
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 18px */
          letter-spacing: -0.2px;
        }

        > .arrow {
          object-fit: contain;
          width: 20px;
          height: 20px;
          padding: 4px;
          opacity: 0.5;
        }
      }
    }
  }
`;

const ConfirmButton = styled.div`
  height: 48px;
  width: 100%;
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.2px;
  border-radius: 8px;
  background: #2D2D2D;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  cursor: pointer;
`;


interface SettingModalProps {
  onClose: () => void;
  defaultStep?: 'default' | 'table' | 'card';
  roomType?:ROOM_TYPE
}

const SettingModal = (
  {
    onClose,
    defaultStep,
    roomType
  }: SettingModalProps
) => {
  const {t} = useTranslation();
  const [step, setStep] = useState<'default' | 'table' | 'card'>(defaultStep || 'default');
  const [setting, setSetting] = useRecoilState(gameOptionState);

  const [showImage, setShowImage] = useState<boolean>(false);
  const [showTableImageOption, setShowTableImageOption] = useState<string>('');
  const [showCardImageOption, setShowCardImageOption] = useState<string>('');
  const emoticonBanList = useRecoilValue(emoticonBanState);

  const [emoticon, setEmoticon] = useState<boolean>(false);

  const setGlobalLoading = useSetRecoilState(globalLoadingState);

  useEffect(() => {
    if (step === 'card') {
      switch (setting.cardStyle) {
        case 1:
          setShowCardImageOption('/images/img_cardstyle_1.png');
          break;
        case 2:
          setShowCardImageOption('/images/img_cardstyle_2.png');
          break;
        case 3:
          setShowCardImageOption('/images/img_cardstyle_3.png');
          break;
      }
    }
  }, [step, setting]);

  useEffect(function(){
    setEmoticon(emoticonBanList.filter(e=>e == 0).length != 0);
  },[emoticonBanList.filter(e=>e == 0).length != 0])

  useEffect(function(){
    setGlobalLoading(false)
  },[emoticon]);

  const setEmoticonBan = useCallback((flag: boolean) => {
    setGlobalLoading(true)
    requestToggleEmoticonBan(0);
  },[]);

  const setAlwaysBB = useCallback((alwaysBB: AlwaysBBType) => {
    setSetting((prev) => ({
      ...prev,
      alwaysBB: alwaysBB
    }));

    if(alwaysBB != AlwaysBBType.bb){
      localStorage.setItem("toggleViewFiat", (alwaysBB == AlwaysBBType.fiat).toString());
    }
  }, []);

  return useMemo(() => {
    return <SettingModalWrapper>
      {
        step === 'default' && <>
          <div className="title-row">{t('옵션')}</div>
          <div className="content">
            <div className="item" onClick={() => setStep('card')}>
              <div className="title">{t('카드 스타일')}</div>
              <div className="arrow-row">
                <img className="arrow" src="/images/ic_arrow_right.svg" alt="arrow_right"/>
              </div>
            </div>

            <div className="item" style={{flexDirection:"column", alignItems:"flex-start"}}>
              <div className="title">{t('칩 표시 변경')}</div>
              <SettingItem>
                <RadioButton checked={setting.alwaysBB == AlwaysBBType.chip} onChecked={() => setAlwaysBB(AlwaysBBType.chip)}>{t('금액 표시')}</RadioButton>
                <div className="checkbox-gap"/>

                {roomType == null || roomType == ROOM_TYPE.RING ? <>
                  <RadioButton checked={setting.alwaysBB == AlwaysBBType.fiat} onChecked={() => setAlwaysBB(AlwaysBBType.fiat)}>{t('화폐 표시')}</RadioButton>
                  <div className="checkbox-gap"/>
                </> : null}

                <RadioButton checked={setting.alwaysBB == AlwaysBBType.bb || (setting.alwaysBB == AlwaysBBType.fiat && roomType == ROOM_TYPE.TOURNAMENT)} onChecked={() => setAlwaysBB(AlwaysBBType.bb)}>{t('BB 표시')}</RadioButton>
              </SettingItem>
            </div>

            <div className="item" onClick={() => {
              setSetting({
                ...setting,
                bgmMuted: !setting.bgmMuted
              });
            }}>
              <div className="title">{t('BGM 끄기')}</div>
              <div className="arrow-row">
                <ToggleSwitch checked={setting.bgmMuted} onChange={(value) => {
                  setSetting({
                    ...setting,
                    bgmMuted: value
                  });
                }}/>
              </div>
            </div>
            <div className="item" onClick={() => {
              setSetting({
                ...setting,
                sfxMuted: !setting.sfxMuted
              });
            }}>
              <div className="title">{t('효과음 끄기')}</div>
              <div className="arrow-row">
                <ToggleSwitch checked={setting.sfxMuted} onChange={(value) => {
                  setSetting({
                    ...setting,
                    sfxMuted: value
                  });
                }}/>
              </div>
            </div>

            <div className="item" onClick={() => {setEmoticonBan(false)}}>
              <div className="title">{t('이모티콘 끄기')}</div>
              <div className="arrow-row">
                <ToggleSwitch checked={emoticon} onChange={(value) => {}}/>
              </div>
            </div>

          </div>
          <ConfirmButton onClick={() => onClose()}>
            {t('확인')}
          </ConfirmButton>
        </>
      }
      {
        step === 'card' && <>
          <div className="title-row">
            <img
              onClick={() => defaultStep === 'card' ? onClose() : setStep('default')}
              className="back"
              src="/images/ic_popup_arrow_left.svg"/>
            <span>{t('카드 스타일')}</span>
          </div>
          <img className="image-wrapper card-image" src={showCardImageOption}
               alt="image"/>
          <div className="content">
            <div className="row">
              <div className="item" onClick={() => {
                setShowCardImageOption('/images/img_cardstyle_1.png');
                setSetting({
                  ...setting,
                  cardStyle: 1
                });
              }}>
                <div className={'layer ' + ((setting.cardStyle === 1) ? 'selected' : '')}>
                  <img src="/images/ic_option_checked.svg"/>
                </div>
                <img src="/images/img_option_card_1.png"/>
              </div>
              <div className="item" onClick={() => {
                setShowCardImageOption('/images/img_cardstyle_2.png');
                setSetting({
                  ...setting,
                  cardStyle: 2
                });
              }}>
                <div className={'layer ' + ((setting.cardStyle === 2) ? 'selected' : '')}>
                  <img src="/images/ic_option_checked.svg"/>
                </div>
                <img src="/images/img_option_card_2.png"/>
              </div>
              <div className="item" onClick={() => {
                setShowCardImageOption('/images/img_cardstyle_3.png');
                setSetting({
                  ...setting,
                  cardStyle: 3
                });
              }}>
                <div className={'layer ' + ((setting.cardStyle === 3) ? 'selected' : '')}>
                  <img src="/images/ic_option_checked.svg"/>
                </div>
                <img src="/images/img_option_card_3.png"/>
              </div>
            </div>
          </div>
        </>
      }

    </SettingModalWrapper>;
  }, [step, onClose, setting, showImage, showCardImageOption, showTableImageOption, emoticon]);
};

export default SettingModal;
