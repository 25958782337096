import styled from "styled-components";

const PayoutWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
  gap: 4px;

  > .summary-wrapper {
    width: calc(100% - 40px);
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    border-radius: 12px;
    background: #1F1F1F;

    > .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;

      > span {
        color: #FFF;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      > span.blue {
        color: #2F99FB;
        font-weight: 700;
      }
    }
  }
  >.title-wrapper {
    width: 100%;
    padding: 8px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #1F1F1F;
    span {
      color: #FFF;
      font-family: Pretendard;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  >.description-wrapper {
    padding: 0 20px;
    width: 100%;
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    >.row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: #FFF;
      text-align: right;
      font-family: Pretendard;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      > div {
        &:first-child {
          width: 120px;
          text-align: left;
        }
      }
      > .fixed {
        width: 64px;
        text-align: right;
      }
    }
  }
  >.bottom-wrapper {
    margin-top: 16px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`
const Payout = () => {
  return <PayoutWrapper>
    <div className='summary-wrapper'>
      <div className='row'>
        <span>테이블 |</span>
        <span>스피드 바카라 D</span>
      </div>
      <div className='row'>
        <span>베팅 한도 |</span>
        <span className='blue'>$1 ~ $30,000</span>
      </div>
      <div className='row'>
        <span>에이전시 베팅 한도 |</span>
        <span className='blue'>최대 $6,000</span>
      </div>
    </div>
    <div className='title-wrapper'>
      <span>배팅</span>
      <span>배팅 한도</span>
      <span>지급금</span>
    </div>
    <div className='description-wrapper'>
      <div className='row'>
        <div>플레이어 페어</div>
        <div>$1~$1,000</div>
        <div className='fixed'>
          11~88:1
        </div>
      </div>
      <div className='row'>
        <div>뱅커 페어</div>
        <div>$1~$1,000</div>
        <div className='fixed'>
          11~88:1
        </div>
      </div>
      <div className='row'>
        <div>퍼펙트 페어*</div>
        <div>$1~$1,000</div>
        <div className='fixed'>
          11~88:1
        </div>
      </div>
      <div className='row'>
        <div>플레이어 페어</div>
        <div>$1~$1,000</div>
        <div className='fixed'>
          11~88:1
        </div>
      </div>
      <div className='row'>
        <div>플레이어 페어</div>
        <div>$1~$1,000</div>
        <div className='fixed'>
          11~88:1
        </div>
      </div>
      <div className='row'>
        <div>플레이어 페어</div>
        <div>$1~$1,000</div>
        <div className='fixed'>
          11~88:1
        </div>
      </div>
      <div className='row'>
        <div>플레이어 페어</div>
        <div>$1~$1,000</div>
        <div className='fixed'>
          11~88:1
        </div>
      </div>
      <div className='row'>
        <div>플레이어 페어</div>
        <div>$1~$1,000</div>
        <div className='fixed'>
          11~88:1
        </div>
      </div>
    </div>
    <div className='bottom-wrapper'>
      * 사이드 베팅은 메인 베팅의 절반을 초과할 수 없습니다.
    </div>
  </PayoutWrapper>
}

export default Payout;
