import styled from "styled-components";
import {useCallback, useMemo} from "react";
import {MEDIA_DESKTOP, MEDIA_MOBILE_MINI} from "../../hooks/useScreenOrientation";
import D3Button from "./D3Button";
import { useRecoilState } from "recoil";
import { gameOptionState } from "../../recoil/GameOption";
import { fixedNumber } from "../../utils/common";
import { ROOM_TYPE } from "../../dataset";
import { PrintAlwaysBB } from "../PrintAlwaysBB";

const Wrapper = styled.div<{dark?: boolean, raise?: boolean, disabled?:boolean; noAction?: boolean }>`
  position: relative;
  width: 106px;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 19px;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(16, 16, 18, 0.20);
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  cursor: pointer;
  transition: filter 0.1s linear;
  align-items: center;
  white-space: nowrap;
  background: radial-gradient(100% 76.89% at 50% 0%, rgba(47, 153, 251, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%), rgba(0, 0, 0, 1);
  filter: ${p=>p.disabled == true ? "grayscale(1)":""};

  ${p => p.disabled && `
    user-select: none;
    pointer-events: none;
    opacity: 0.5;
  `};

  ${p => !p.noAction && `
    background: none !important;
    padding 13px 15px 19px;
    
    &::before {
      display: none;
    }
  `};
  
  ${p => p.dark && `
    background: radial-gradient(102.77% 100% at 50% 100%, rgba(168, 168, 168, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%), rgba(0, 0, 0, 1);
  `};
  
  ${p => p.raise && `
    background: radial-gradient(100% 76.89% at 50% 0%, rgba(47, 153, 251, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%), #000;
    padding 15px 19px;
    
  `};

  @media ${MEDIA_DESKTOP} {
    width: 188px;
    height: 60px;
    font-size: 20px;
    border-radius: 12px;
  }
  @media ${MEDIA_MOBILE_MINI}{
    height: 40px;
  }

  &::before {
    width: 100%;
    height: 0.5px;
    //transform: scale(1, 0.5);
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(90deg, transparent, white, transparent);
  }

  &:hover {
    filter: brightness(1.15);
  }

  &:active {
    filter: brightness(0.8);
  }

  .call-amount {
    font-size: 10px;
    margin-top: 2px;
    @media ${MEDIA_MOBILE_MINI} {
      font-size: 8px;
      margin-top: 0;
    }
    
  }

  .inner {
    gap: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media ${MEDIA_MOBILE_MINI} {
      font-size: 12px;
    }
  }
`;

const ButtonBackground = styled(D3Button)`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
`

const Checkbox = styled.div<{ checked?: boolean }>`
  width: 12px;
  height: 12px;
  background: url(/images/checkbox_off.svg) no-repeat center;
  background-size: contain;
  margin-right: 4px;

  ${p => p.checked && `
    background-image: url(/images/checkbox_on.svg);
  `};

  @media ${MEDIA_DESKTOP} {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`;

const Raise = styled.div`
  position: absolute;
  top: 6px;
  left: 16px;
  color: #FBB62F;
  font-size: 10px;
  font-weight: 500;
  @media ${MEDIA_MOBILE_MINI} {
    font-size: 8px;
  }
`;


function InGameButton({roomType, isAllIn, bb, children, callAmount, checked, subTitle, disabled, noAction, raise, bet, dark, onChecked, onClick}: {
  children: any
  checked?: boolean
  noAction?: boolean,
  raise?: boolean
  subTitle?: string
  bet?: boolean
  disabled?: boolean
  bb:number
  dark?: boolean
  onChecked?: (e: any) => void
  onClick?: (e: any) => void
  callAmount?: number;
  isAllIn?: boolean;
  roomType: ROOM_TYPE
}) {
  const [setting, setSetting] = useRecoilState(gameOptionState);

  const isCheckbox = useMemo(() => {
    return onChecked
  }, [onChecked]);

  const handleClick = useCallback((e: any) => {
    if(disabled) {
      return;
    } else if (onChecked) {
      onChecked(e);
    } else if (onClick) {
      onClick(e)
    }
  }, [isCheckbox, onClick, onChecked, disabled]);

  return <Wrapper noAction={noAction} dark={dark || (isCheckbox && !checked)} raise={raise || bet} onClick={handleClick} disabled={disabled}>
    {
      !noAction && (
        <ButtonBackground isMax={subTitle === 'MAX' || isAllIn}  dark={dark || raise || bet}/>
      )
    }
    <Raise>{subTitle}</Raise>
    {/* { bet && (<Raise>bb</Raise>) }
    { raise && (<Raise>pot</Raise>) } */}
    <div className='inner'>
      {
        isCheckbox && (
          <Checkbox checked={checked}/>
        )
      }
      {children}
    </div>
    {
      (callAmount !== undefined && !isNaN(callAmount) && callAmount > 0) &&
        <div className='call-amount'>
          {PrintAlwaysBB(roomType, setting.alwaysBB, callAmount, bb)}
          {/* setting.alwaysBB ?
            `${fixedNumber(callAmount / (bb||1), 2).toLocaleString()} BB` :
            callAmount.toLocaleString()
          */}
        </div>
    }
  </Wrapper>
}

export default InGameButton
