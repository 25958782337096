import styled from "styled-components";
import {useDroppable} from "@dnd-kit/core";
import useLoadBaccaratGame from "../../hooks/useLoadBaccaratGame";

const BettingButtonWrapper = styled.div<{
  type: 'player' | 'banker' | 'tie' | 'pair'
  titleSize: 'normal' | 'small'
  showOption?: boolean;
  isOver: boolean;
  disabled: boolean;
  hasGap?: boolean;
}>`
  cursor: pointer;
  position: relative;
  padding: 8px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${p => p.titleSize === 'normal' ? `
    justify-content: space-between;
  ` : ``}
  ${p => p.titleSize === 'small' ? `
    justify-content: center;
  ` : ``}
  flex: 1;
  border-radius: 5px;
  border-top: 0.5px solid rgba(255, 255, 255, 0.2);
  border-right: 0.5px solid transparent;
  border-bottom: 0.5px solid transparent;
  border-left: 0.5px solid transparent;
  height: 100%;
  transition: all 0.5s;
  ${p => p.isOver && `
    border: 0.5px solid #FFF;
  `};

  ${p => p.type === 'player' && `
    background: linear-gradient(180deg, rgba(49, 122, 207, 0.20) 0%, rgba(49, 122, 207, 0.10) 100%);
    box-shadow: 0px 2px 2px 0px rgba(16, 16, 18, 0.20);
    ${p.showOption ? `
    background: linear-gradient(180deg, #317ACF 0%, rgba(49, 122, 207, 0.50) 100%);
    ` : ``}
  `}
  ${p => p.type === 'banker' && `
    background: linear-gradient(180deg, rgba(217, 60, 51, 0.20) 0%, rgba(217, 60, 51, 0.10) 100%);
    ${p.showOption ? `
    background: linear-gradient(180deg, #D93C33 0%, rgba(217, 60, 51, 0.50) 100%);
    ` : ``}
  `}
  ${p => p.type === 'tie' && `
    background: linear-gradient(180deg, rgba(79, 207, 92, 0.20) 0%, rgba(79, 207, 92, 0.10) 100%);
    box-shadow: 0px 2px 2px 0px rgba(16, 16, 18, 0.20);
  `};
  ${p => p.type === 'pair' && `
    background: linear-gradient(180deg, rgba(250, 150, 32, 0.20) 0%, rgba(250, 150, 32, 0.10) 100%);
    justify-content: center;
  `};
  ${p => p.hasGap && `
    gap: 3px;
    padding: 3px 10px;
    @media (min-height: 650px) {
      padding: 8px 10px;
      gap: 5px;
    }
  `};

  ${p => p.disabled && `
    cursor: not-allowed;
    pointer-events: none;
    filter: grayscale(100%);
  `}
  > .title {
    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    font-family: Pretendard;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    ${p => p.titleSize === 'normal' && 'font-size: 14px;'};
    ${p => p.titleSize === 'small' && 'font-size: 12px;'};
  }

  > .sub {
    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Pretendard;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  > .status {
    width: 100%;
    display: flex;

    ${p => p.type === 'tie' ? `
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2px;
    ` : ``}
    ${p => p.type === 'player' ? `
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 5px;
    ` : ``}
    ${p => p.type === 'banker' ? `
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 5px;
    ` : ``}
    > .percent {
      width: 30px;
      height: 30px;
    }

    > .info-wrapper {
      display: flex;
      flex-direction: column;
      ${p => p.type === 'tie' ? `
          align-items: center;
        ` : ``}
      ${p => p.type === 'player' ? `
          align-items: flex-start;
        ` : ``}
      ${p => p.type === 'banker' ? `
          align-items: flex-end;
        ` : ``}
      justify-content: center;
      gap: 1px;

      > .money {
        ${p => p.type === 'tie' ? `
          color: #4FCF5C;
        ` : ``}
        ${p => p.type === 'player' ? `
          color: #2F99FB;
        ` : ``}
        ${p => p.type === 'banker' ? `
          color: #FB3737;
        ` : ``}
        font-family: Pretendard;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.2px;
      }

      > .player {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 2px;

        > img {
          width: 12px;
          height: 12px;
        }

        > span {
          ${p => p.type === 'tie' ? `
            color: #4FCF5C;
          ` : ``}
          ${p => p.type === 'player' ? `
            color: #2F99FB;
          ` : ``}
          ${p => p.type === 'banker' ? `
            color: #FB3737;
          ` : ``}
          text-align: center;
          font-family: Pretendard;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.2px;
        }
      }
    }
  }

  > .arrow {
    width: 12px;
    height: 12px;
    position: absolute;
    ${p => p.type === 'player' ? `
      left: 4px;
      bottom: 2px;
    ` : ``}
    ${p => p.type === 'banker' ? `
      right: 4px;
      bottom: 2px;
    ` : ``}
    transition: all 0.3s;
    transform: rotate(180deg);
    opacity: 0.5;
  }

  > .arrow.show {
    transform: rotate(0deg);
    opacity: 1;
  }
`

interface BettingButtonProps {
  id: string;
  children: React.ReactNode | React.ReactNode[];
  type: 'player' | 'banker' | 'tie' | 'pair';
  titleSize: 'normal' | 'small';
  showOption?: boolean;
  onClick: (event: any) => void;
  enableDrag?: boolean;
  hasGap?: boolean;
}

const BettingButton = ({
                         id,
                         children,
                         type,
                         titleSize,
                         showOption,
                         onClick,
                          hasGap = false,
                         enableDrag = false
                       }: BettingButtonProps) => {
  const {setNodeRef, isOver} = useDroppable({
    id: id,
    disabled: !enableDrag
  });
  return <BettingButtonWrapper
    hasGap={hasGap}
    disabled={!enableDrag}
    isOver={isOver}
    id={id}
    ref={setNodeRef}
    type={type} titleSize={titleSize} showOption={showOption}
    onClick={event => onClick(event)}>
    {children}
  </BettingButtonWrapper>
}
export default BettingButton;
