import styled from "styled-components";

const HistoryWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
  gap: 4px;

  > .title-row {
    padding: 0 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > .item {
      color: #FFF;
      font-family: Pretendard;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  > .content-wrapper {
    width: 100%;
    overflow-y: auto;
    max-height: calc(100vh - 265px);

    > .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      > .summary-row {
        padding: 8px 20px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: #1F1F1F;
        color: #FFF;
        font-family: Pretendard;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        > .hash {
          width: 137.8px;
        }

        > .result {
          width: 64px;
          text-align: right;
        }
      }

      > .detail-row {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 4px 20px;

        > .item-row {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;
          padding: 4px 0 8px;
          border-bottom: 1px solid #2D2D2D;

          > .title-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;

            > .title-col {
              opacity: 0.6;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 4px;
              color: #FFF;
              font-family: Pretendard;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-align: center;
            }

            > .verify-button {
              width: 56px;
              height: 24px;
              cursor: pointer;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              gap: 1px;
              border-radius: 20px;
              border: 1px solid #2F99FB;
              background: rgba(47, 153, 251, 0.10);

              img {
                width: 16px;
                height: 16px;
              }

              span {
                color: #2F99FB;
                text-align: right;
                font-family: Pretendard;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }
            }
          }
          >.hash-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 4px;
            >.hash-row{
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              gap: 4px;
              >img{
                width: 12px;
                height: 12px;
              }
              >.hash{
                color: #FFF;
                text-align: center;
                font-family: Pretendard;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-decoration-line: underline;
                opacity: 0.6;
              }
            }
          }
          >.item {
            color: #FFF;
            text-align: center;
            font-family: Pretendard;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            opacity: 0.6;
          }
          >.item.fixed {
            width: 64px;
          }
        }
      }
    }
  }
`
const History = () => {
  return <HistoryWrapper>
    <div className='title-row'>
      <div className='item'>날짜 / 시간 / 방</div>
      <div className='item'>블록해시</div>
      <div className='item'>배팅</div>
      <div className='item'>승리 / 패배</div>
    </div>
    <div className='content-wrapper'>
      <div className='content'>
        <div className='summary-row'>
          <div className='title'>~24. 5. 29</div>
          <div className='hash'></div>
          <div className='title'>￦160,000</div>
          <div className='result'>￦40,000</div>
        </div>
        <div className='detail-row'>
          {
            Array.from({length: 10}).map((_, index) => {
              return <div key={index} className='item-row'>
                <div className='title-item'>
                  <div className='title-col'>
                    <span>(#000001)</span>
                    <span>오후 5:33:00</span>
                  </div>
                  <div className='verify-button'>
                    <img src='/images/baccarat/UI/List/Icon/Mini/Search.svg'/>
                    <span>검증</span>
                  </div>
                </div>
                <div className='hash-list'>
                  <div className='hash-row'>
                    <img src='/images/baccarat/UI/List/Logo/eth.png'/>
                    <span className='hash'>20242896 / 0x407...7267</span>
                  </div>
                  <div className='hash-row'>
                    <img src='/images/baccarat/UI/List/Logo/op.png'/>
                    <span className='hash'>20242896 / 0x407...7267</span>
                  </div>
                  <div className='hash-row'>
                    <img src='/images/baccarat/UI/List/Logo/arb.png'/>
                    <span className='hash'>20242896 / 0x407...7267</span>
                  </div>
                  <div className='hash-row'>
                    <img src='/images/baccarat/UI/List/Logo/base.png'/>
                    <span className='hash'>20242896 / 0x407...7267</span>
                  </div>
                </div>
                <div className='item'>￦50,000</div>
                <div className='item fixed'>￦50,000</div>
              </div>
            })
          }
        </div>
      </div>
    </div>
  </HistoryWrapper>
}

export default History;
