import "./overrides";
import React, {useLayoutEffect} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import {SnackbarProvider} from 'notistack'
import {RecoilRoot} from "recoil";
import theme from './theme';
import Container from './Container';
import RejoinCheck from "./components/RejoinCheck";
import Lobby from "./pages/Lobby";
import Game from "./pages/Game";
import Tournament from "./pages/Tournament";
import DirectLink from "./pages/DirectLink";
import ArticleViewer from "./pages/ArticleViewer";
import MoveRoom from "./pages/MoveRoom";
import GameTransition from "./pages/GameTransition";
import Baccarat from "./pages/Baccarat";

const App: React.FC = () => {
  return (<ThemeProvider theme={theme}>
    <BrowserRouter>
      <Route path={'/link'} exact>
        <DirectLink />
      </Route>

      <RecoilRoot>
        <RejoinCheck/>
        <SnackbarProvider className="snackbar" anchorOrigin={{horizontal: "center", vertical: "top"}}>
          <Container>
            <Switch>
              <Redirect path="/" exact to={"/lobby"}/>
              <Route path={'/lobby'} exact>
                <Lobby/>
              </Route>

              <Route path={'/tournament/:groupId'} exact>
                <Tournament/>
              </Route>

              <Route path={'/game'} exact>
                <GameTransition/>
              </Route>
              <Route path={'/moveroom'} exact>
                <MoveRoom/>
              </Route>
              <Route path={'/baccarat'} exact>
                <Baccarat/>
              </Route>
              <Route path={'/notice/:noticeId'} exact>
                <ArticleViewer />
              </Route>
            </Switch>
          </Container>
        </SnackbarProvider>
      </RecoilRoot>
    </BrowserRouter>
  </ThemeProvider>);
};

export default App;
// export default ()=>{
//   let list = [
//     {result:"banker", playerScore:10, bankerScore:10},
//     {result:"banker", playerScore:10, bankerScore:10},
//     {result:"banker", playerScore:10, bankerScore:10},

//     {result:"player", playerScore:10, bankerScore:10},
//     {result:"player", playerScore:10, bankerScore:10},
//     {result:"player", playerScore:10, bankerScore:10},
//     {result:"player", playerScore:10, bankerScore:10},
//     {result:"player", playerScore:10, bankerScore:10},
//     {result:"player", playerScore:10, bankerScore:10},
//     {result:"player", playerScore:10, bankerScore:10},
//     {result:"player", playerScore:10, bankerScore:10},

//     {result:"banker", playerScore:10, bankerScore:10},
//     {result:"banker", playerScore:10, bankerScore:10},
//     {result:"banker", playerScore:10, bankerScore:10},
//     {result:"banker", playerScore:10, bankerScore:10},
//     {result:"banker", playerScore:10, bankerScore:10},
//     {result:"banker", playerScore:10, bankerScore:10},

//     {result:"player", playerScore:10, bankerScore:10},
//     {result:"banker", playerScore:10, bankerScore:10},

//     {result:"player", playerScore:10, bankerScore:10},
//     {result:"banker", playerScore:10, bankerScore:10},

//     {result:"player", playerScore:10, bankerScore:10},
//     {result:"banker", playerScore:10, bankerScore:10},

//     {result:"player", playerScore:10, bankerScore:10},
//     {result:"banker", playerScore:10, bankerScore:10},

//     {result:"player", playerScore:10, bankerScore:10},
//   ] as BaccaratResultInfo[]

//   return <div style={{display:"flex", flexDirection:"column", border:"1px solid black"}}>
//     <div style={{border:"1px solid black"}}>
//       <LoadSimulator widthPixel={"900px"} heightPixel={"250px"} results={list} columnsCount={6} simulatorType={"bigload"} />
//     </div>
//     <div style={{display:"flex"}}>
//       <div>
//         <LoadSimulator widthPixel={"400px"} heightPixel={"100px"} results={list} columnsCount={6} simulatorType={"bigeye"} />
//         <div style={{borderTop:"1px solid black", borderBottom:"1px solid black"}}>
//           <LoadSimulator widthPixel={"400px"} heightPixel={"100px"} results={list} columnsCount={6} simulatorType={"smalleye"} />
//         </div>
//         <LoadSimulator widthPixel={"400px"} heightPixel={"100px"} results={list} columnsCount={6} simulatorType={"stick"} />
//       </div>
//       <div style={{borderLeft:"1px solid black"}}>
//         <LoadSimulator widthPixel={"500px"} heightPixel={"302px"} results={list} columnsCount={6} simulatorType={"sixload"} />
//       </div>
//     </div>
//   </div>
// };
