import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {fixedNumber, formatNumber} from "../../utils/common";
import {useDraggable} from "@dnd-kit/core";
import {Textfit} from "react-textfit";

const BetChipWrapper = styled.div<{position: number}>`
  position: absolute;
  width: 30px;
  height: 27.5px;
  ${p => p.position === 0 && `
    bottom: 4px;
    right: 20px;
  `};
  ${p => p.position === 1 && `
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
  `};
  ${p => p.position === 2 && `
    bottom: 4px;
    left: 20px;
  `};
  ${p => p.position === 3 && `
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
  `};
  ${p => p.position === 4 && `
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `};
  ${p => p.position === 5 && `
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
  `};
  ${p => p.position === 6 && `
     top: 50%;
    transform: translateY(-50%);
    right: 20px;
  `};
  ${p => p.position === 7 && `
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `};
  ${p => p.position === 8 && `
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
  `};
  >.background {
    width: 100%;
    height: 100%;
  }
  >.value {
    position: absolute;
    max-width: 20px;
    padding-bottom: 3px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FFF;
    font-family: Pretendard;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
  }
`
interface BetChipProps {
  amount: number;
  position: number;
}
const BetChip = ({amount, position}:BetChipProps) => {
  if(amount === undefined) {
    amount = 0;
  }
  const [chipImage, setChipImage] = useState<string>("");
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform
  } = useDraggable({
    id: "draggable_chip"+position
  });

  useEffect(() => {
    if(amount < 10) {
      setChipImage("Red");
    }else if(amount <= 100) {
      setChipImage("Yellow");
    }else if(amount <= 500){
      setChipImage("Grey");
    }else if(amount <= 1000){
      setChipImage("Pink");
    }else if(amount <= 5000){
      setChipImage("White");
    }else if(amount <= 10000){
      setChipImage("Blue");
    }else if(amount <= 25000){
      setChipImage("Green");
    }else if(amount <= 1000000){
      setChipImage("Purple");
    }else if(amount <= 10000000){
      setChipImage("Chocolate");
    }else {
      setChipImage("Gold");
    }
  }, [amount]);

  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  } : undefined;
  return <BetChipWrapper
    {...attributes}
    ref={setNodeRef}
    style={style}
    {...listeners}
    className='bet_chip' id={`bet_chip_`+position} position={position}>
    {
      amount > 0 && <>
        <img className="background" src={`/images/baccarat/unnamed_chip/${chipImage}.png`} alt=""/>
        <div className='value'>
          <Textfit mode="single" max={10}>
            <span>{formatNumber(amount)}</span>
          </Textfit>
        </div>
      </>
    }
  </BetChipWrapper>
}

export default BetChip;
