import BaccaratWrapper from "../../components/Baccarat/BaccaratWrapper";
import Timer from "../../components/Baccarat/Timer";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import BettingButton from "../../components/Baccarat/BettingButton";
import Percent from "../../components/Baccarat/Percent";
import BottomWrapper from "../../components/Baccarat/BottomWrapper";
import BettingChipArea from "../../components/Baccarat/BettingChipArea";
import Result from "../../components/Baccarat/Result";
import BetOption, { BetOptionArr, BetOptionInterface } from "../../components/Baccarat/BetOption";
import MenuSheet from "../../components/Baccarat/MenuSheet";
import { useRecoilValue } from "recoil";
import { userIdSelector } from "../../recoil/MyInfo";
import useLoadBaccaratGame from "../../hooks/useLoadBaccaratGame";
import { BaccaratActionType, BaccaratBetType, BaccaratBonusType, BaccaratResultInfo } from "../../dataset";
import { requestBaccaratAction, requestBaccaratUndoAction } from "../../api";
import { setAckListener as setBaccaratRemainBetTime } from "../../api/from_server_baccarat_baccaratRemainBetTime";
import { setAckListener as setBaccaratGameStatus } from "../../api/from_server_baccarat_baccaratGameStatus";
import { setAckListener as setBaccaratMyWinAmount } from "../../api/from_server_baccarat_baccaratMyWinAmount";
import { enqueueSnackbar } from "notistack";
import BetChip from "../../components/Baccarat/BetChip";
import {
  closestCorners,
  DndContext, DragEndEvent, DragOverEvent,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import LoadSimulator from "../../components/BaccaratSimulator";
import { fiatToFiat, UserCurrency, UserCurrencyMark } from "../../conversionChip";
import { useTranslation } from "react-i18next";

const Simulator = ({
  resultList,
  layout,
}:{
  resultList:BaccaratResultInfo[],
  layout:string,
}) => {

  const containerRef = useRef<HTMLDivElement>(null);

  const handleResize = useCallback(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    if(containerRef.current){

    }

  }, [containerRef]);

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [handleResize]);


  return <div className='score-board-wrapper'>
    <div>
      <div>
        <div style={{ borderBottom: "1px solid #999", width: '100%', display:"flex", flex:"1" }}>
          <LoadSimulator results={resultList} columnsCount={6}
            simulatorType={"bigload"} />
        </div>
        <div style={{ display: "flex", flexDirection: 'row', width: '100%', height:(22*3)+"px" }}>
          <LoadSimulator results={resultList} columnsCount={10}
            simulatorType={"bigeye"} />
          <div style={{
            borderRight: "1px solid #999",
            borderLeft: "1px solid #999",
            flex:"1",
            display:"flex"
          }}>
            <LoadSimulator results={resultList} columnsCount={10}
              simulatorType={"smalleye"} />
          </div>
          <LoadSimulator results={resultList} columnsCount={10}
            simulatorType={"stick"} />
        </div>
      </div>
    </div>
  </div>
}

const Baccarat = () => {
  const { t } = useTranslation();
  const userId = useRecoilValue(userIdSelector);
  const {
    tableId,
    table,
    myRooms,
    totalBetAmount,
    gameStatus,
    totalGameHistory,
    remainBetTime,
    winAmount,
    resultList,
    myBalance
  } = useLoadBaccaratGame();
  const [selectedChip, setSelectedChip] = useState(5000);

  const [showMenu, setShowMenu] = useState(false);
  const [showOption, setShowOption] = useState<'player' | 'banker' | null>(null);
  const [selectedOption, setSelectedOption] = useState<{
    player: BetOptionInterface,
    banker: BetOptionInterface
  }>({
    player: BetOptionArr[1],
    banker: BetOptionArr[3]
  })
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  });
  const sensors = useSensors(mouseSensor, touchSensor);

  const handleClickBetButton = (target: EventTarget, type: BaccaratBetType, bonusType?: BaccaratBonusType) => {
    if (gameStatus && remainBetTime[0] <= 0) {
      enqueueSnackbar('베팅 가능한 시간이 아닙니다.', { variant: 'error' });
      return;
    }
    let targetDiv = target as HTMLDivElement;
    let betChipWrapper = targetDiv.querySelector('.bet_chip') as HTMLDivElement;

    if (betChipWrapper === null) {
      //칩을 클릭하였을때. 부모 요소로 전환하여 다시 검색한다.
      targetDiv = targetDiv.parentElement as HTMLDivElement;
      betChipWrapper = targetDiv.querySelector('.bet_chip') as HTMLDivElement;
      if (betChipWrapper == null) return;
    }
    const startDiv = document.getElementById('center-button') as HTMLImageElement;

    const targetRect = betChipWrapper.getBoundingClientRect();
    const imgRect = startDiv.getBoundingClientRect();

    const newImg = document.createElement('img');
    if (selectedChip === 5) {
      newImg.src = '/images/baccarat/Chip2/Red.png';
    } else if (selectedChip === 10) {
      newImg.src = '/images/baccarat/Chip2/Yellow.png';
    } else if (selectedChip === 100) {
      newImg.src = '/images/baccarat/Chip2/Grey.png';
    } else if (selectedChip === 500) {
      newImg.src = '/images/baccarat/Chip2/Pink.png';
    } else if (selectedChip === 5000) {
      newImg.src = '/images/baccarat/Chip2/Blue.png';
    } else if (selectedChip === 25000) {
      newImg.src = '/images/baccarat/Chip2/Purple.png';
    } else if (selectedChip === 100000) {
      newImg.src = '/images/baccarat/Chip2/Green.png';
    }
    newImg.style.position = 'fixed';
    newImg.style.width = '30px';
    newImg.style.height = '27.5px';
    newImg.style.zIndex = '100';
    newImg.style.left = imgRect.x + 'px';
    newImg.style.top = imgRect.y + 'px';
    newImg.style.transition = 'all 0.4s';
    targetDiv.appendChild(newImg);

    setTimeout(() => {
      //newImg가 targetDiv의 중앙으로 이동하는 애니메이션
      newImg.style.left = (targetRect.x + targetRect.width / 2 - 15) + 'px';
      newImg.style.top = (targetRect.y + targetRect.height / 2 - 14) + 'px';
      //newImg.style.width = '0px';
      //newImg.style.height = '0px';
      newImg.style.opacity = '0.7';
      setTimeout(() => {
        newImg.remove();
      }, 500)
    }, 100);
    requestBaccaratAction(tableId, BaccaratActionType.BET, selectedChip, BaccaratBetType.NONE, type, bonusType ?? BaccaratBonusType.NONE);
  }

  const handleClickUndo = () => {
    requestBaccaratUndoAction(tableId)
  }

  const handleClickBettingOption = (target: 'player' | 'banker') => {
    if (showOption === target) {
      setShowOption(null);
    } else {
      setShowOption(target);
    }
  }

  const handleDragStart = (event: DragStartEvent) => {
    console.log('drag start')
  }
  const handleDragOver = (event: DragOverEvent) => {
    console.log('drag over')
  }
  const handleDragEnd = (event: DragEndEvent) => {
    if (table === undefined || table.curInfo === undefined) {
      console.log('table is undefined')
      return;
    }
    const { active, over } = event;
    if (over && active.id !== over.id) {
      console.log('active:', active.id, 'over:', over.id)
      const fromChipId = parseInt(active.id.toString().replace('draggable_chip', ''));
      let fromBetType;
      switch (fromChipId) {
        case 0:
          fromBetType = BaccaratBetType.PLAYER;
          break;
        case 1:
          fromBetType = BaccaratBetType.TIE;
          break;
        case 2:
          fromBetType = BaccaratBetType.BANKER;
          break;
        case 3:
          fromBetType = BaccaratBetType.PLAYER_PAIR;
          break;
        case 4:
          fromBetType = BaccaratBetType.PERFECT_PAIR;
          break;
        case 5:
          fromBetType = BaccaratBetType.BANKER_PAIR;
          break;
        case 6:
          fromBetType = BaccaratBetType.PLAYER_BONUS;
          break;
        case 7:
          fromBetType = BaccaratBetType.EITHER_PAIR;
          break;
        case 8:
          fromBetType = BaccaratBetType.BANKER_BONUS;
          break;
      }
      if (fromBetType !== undefined && fromBetType !== BaccaratBetType.NONE) {
        let fromBetAmount = 0;
        if (fromBetType === BaccaratBetType.PLAYER_BONUS || fromBetType === BaccaratBetType.BANKER_BONUS) {
          fromBetAmount = table?.curInfo[fromBetType]?.amount || 0;
        } else {
          fromBetAmount = table?.curInfo[fromBetType] || 0;
        }
        const toBetTypeId = over.id
        let toBetType;
        switch (toBetTypeId) {
          case 'player-bet':
            toBetType = BaccaratBetType.PLAYER;
            break;
          case 'tie-bet':
            toBetType = BaccaratBetType.TIE;
            break;
          case 'banker-bet':
            toBetType = BaccaratBetType.BANKER;
            break;
          case 'player-pair-bet':
            toBetType = BaccaratBetType.PLAYER_PAIR;
            break;
          case 'perfect-pair-bet':
            toBetType = BaccaratBetType.PERFECT_PAIR;
            break;
          case 'banker-pair-bet':
            toBetType = BaccaratBetType.BANKER_PAIR;
            break;
          case 'player-bet-option':
            toBetType = BaccaratBetType.PLAYER_BONUS;
            break;
          case 'either-pair-bet':
            toBetType = BaccaratBetType.EITHER_PAIR;
            break;
          case 'banker-bet-option':
            toBetType = BaccaratBetType.BANKER_BONUS;
            break;
        }
        if (toBetType !== undefined && toBetType !== BaccaratBetType.NONE) {
          if (fromBetType === toBetType) {
            console.log('fromBetType is same with toBetType')
            return;
          }
          if (fromBetAmount === 0) {
            console.log('fromBetAmount is 0')
            return;
          }
          let bonusType = BaccaratBonusType.NONE;
          if (toBetType === BaccaratBetType.PLAYER_BONUS) {
            bonusType = selectedOption.player.type;
          } else if (toBetType === BaccaratBetType.BANKER_BONUS) {
            bonusType = selectedOption.banker.type;
          }
          console.log('fromBetType:', fromBetType, 'toBetType:', toBetType, 'fromBetAmount:', fromBetAmount, 'bonusType:', bonusType)
          requestBaccaratAction(tableId, BaccaratActionType.MOVE, fromBetAmount, fromBetType, toBetType, bonusType);
        }
      }
    }
  }

  if (!table || !gameStatus) {
    return <></>
  }

  return <BaccaratWrapper>
    <MenuSheet show={showMenu} title={'스피드 바카라 A'} onClose={() => setShowMenu(false)} />
    <BetOption show={showOption === 'player'} type={'player'} onSelected={(option) => {
      setSelectedOption({ ...selectedOption, player: option });
      setShowOption(null);
    }} />
    <BetOption show={showOption === 'banker'} type={'banker'} onSelected={(option) => {
      setSelectedOption({ ...selectedOption, banker: option });
      setShowOption(null);
    }} />
    <div className='video-wrapper' id='video-wrapper'>
      {
        (gameStatus.status === 'result' && gameStatus.baccaratCards && gameStatus.baccaratCards.result && winAmount.amount > 0) &&
        <Result type={gameStatus.baccaratCards.result} money={winAmount.amount} currency={winAmount.currency} /> //Todo: My Bet Money 와 배율을 곱한 값. 서버에서 받아와도 됨
      }
      {
        remainBetTime[0] > 0 && <div className='timer'>
          <Timer current={remainBetTime[0]-1} max={remainBetTime[1]-1} />
        </div>
      }
      {
        (gameStatus.status === 'wait' && remainBetTime[0] === 0) && <div className='next-game'>
          {t("곧 다음 게임이 시작됩니다")}
        </div>
      }
      {
        (gameStatus.status === 'bet' && remainBetTime[0] === 0) && <div className='next-game'>
          {t("카드 셔플 중")}
        </div>
      }
      {
        (gameStatus.status === 'result' && gameStatus.baccaratCards) && <div className='card-wrapper'>
          <div className='card-list'>
            <div className='title'>플레이어</div>
            <div className='card-row'>
              {
                Array.from({ length: 3 - gameStatus.baccaratCards.playerCards.length }, (_, index) => {
                  return <img key={index} src='/images/card_back.svg' />
                })
              }
              {
                gameStatus.baccaratCards.playerCards.map((card: number, index: number) => {
                  return <img key={index} src={`/images/card/style-1/${card}.svg`} />
                })
              }
            </div>
          </div>
          <div className='card-list'>
            <div className='title'>뱅커</div>
            <div className='card-row'>
              {
                gameStatus.baccaratCards.bankerCards.map((card: number, index: number) => {
                  return <img key={index} src={`/images/card/style-1/${card}.svg`} />
                })
              }
              {
                Array.from({ length: 3 - gameStatus.baccaratCards.bankerCards.length }, (_, index) => {
                  return <img key={index} src='/images/card_back.svg' />
                })
              }
            </div>
          </div>
        </div>
      }
      <div className='horizontal-line' />
    </div>
    <div className='game-wrapper'>
      <div className='game-status-bar-row'>
        <div className='game-status-bar'>
          <div className='item'>
            <span>#{totalGameHistory.roomNumber}</span>
          </div>
          <div className='item'>
            <div className='tag player'>P</div>
            <span>{totalGameHistory.playerWin}</span>
          </div>
          <div className='item'>
            <div className='tag banker'>B</div>
            <span>{totalGameHistory.bankerWin}</span>
          </div>
          <div className='item'>
            <div className='tag tie'>T</div>
            <span>{totalGameHistory.tie}</span>
          </div>
        </div>
        <div className='hint-wrapper'>
          <div className='wrapper player'>
            <span>P?</span>
            <img src='/images/baccarat/player_icon.svg'/>
          </div>
          <div className='wrapper banker'>
            <span>B?</span>
            <img src='/images/baccarat/banker_icon.svg'/>
          </div>
        </div>
      </div>
      <Simulator resultList={resultList} layout="top1bottom3"/>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <div className='betting-wrapper'>
          <div className='row'>
            <BettingButton
              enableDrag={gameStatus.status === 'bet' && remainBetTime[0] > 0}
              id='player-bet'
              type='player' titleSize={'normal'}
              onClick={event => handleClickBetButton(event.target, BaccaratBetType.PLAYER)}>
              <div className='status'>
                <div className='percent'>
                  <Percent percentage={totalBetAmount.player.ratio} type='player'/>
                </div>
                <div className='info-wrapper'>
                  <div
                    className='money'>{UserCurrencyMark()}{fiatToFiat("USD", UserCurrency(), totalBetAmount.player.total).toLocaleString()}</div>
                  <div className='player'>
                    <img src='/images/baccarat/icon_player.svg'/><span
                    className='count'>{totalBetAmount.player.count}</span>
                  </div>
                </div>
              </div>
              <div className='title'>{t("플레이어")}</div>
              <BetChip amount={table.curInfo[BaccaratBetType.PLAYER]} position={0}/>
            </BettingButton>
            <BettingButton
              enableDrag={gameStatus.status === 'bet' && remainBetTime[0] > 0}
              id='tie-bet'
              type='tie' titleSize={'normal'}
              onClick={event => handleClickBetButton(event.target, BaccaratBetType.TIE)}>
              <div className='status'>
                <div className='percent'>
                  <Percent percentage={totalBetAmount.tie.ratio} type='tie'/>
                </div>
                <div className='info-wrapper'>
                  <div
                    className='money'>{UserCurrencyMark()}{fiatToFiat("USD", UserCurrency(), totalBetAmount.tie.total).toLocaleString()}</div>
                  <div className='player'>
                    <img src='/images/baccarat/icon_tie.svg'/><span className='count'>{totalBetAmount.tie.count}</span>
                  </div>
                </div>
              </div>
              <div className='title'>{t("무 8:1")}</div>
              <BetChip amount={table.curInfo[BaccaratBetType.TIE]} position={1}/>
            </BettingButton>
            <BettingButton
              enableDrag={gameStatus.status === 'bet' && remainBetTime[0] > 0}
              id='banker-bet'
              type='banker' titleSize={'normal'}
              onClick={event => handleClickBetButton(event.target, BaccaratBetType.BANKER)}>
              <div className='status'>
                <div className='info-wrapper'>
                  <div
                    className='money'>{UserCurrencyMark()}{fiatToFiat("USD", UserCurrency(), totalBetAmount.banker.total).toLocaleString()}</div>
                  <div className='player'>
                    <img src='/images/baccarat/icon_banker.svg'/><span
                    className='count'>{totalBetAmount.banker.count}</span>
                  </div>
                </div>
                <div className='percent'>
                  <Percent percentage={totalBetAmount.banker.ratio} type='banker'/>
                </div>
              </div>
              <div className='title'>뱅커</div>
              <BetChip amount={table.curInfo[BaccaratBetType.BANKER]} position={2}/>
            </BettingButton>
          </div>
          <div className='row'>
            <BettingButton
              enableDrag={gameStatus.status === 'bet' && remainBetTime[0] > 0}
              id='player-pair-bet'
              type='player' hasGap={true} titleSize={'small'}
              onClick={event => handleClickBetButton(event.target, BaccaratBetType.PLAYER_PAIR)}>
              <div className='title'>플레이어 페어</div>
              <div className='sub'>더블 200:1</div>
              <BetChip amount={table.curInfo[BaccaratBetType.PLAYER_PAIR]} position={3}/>
            </BettingButton>
            <BettingButton
              enableDrag={gameStatus.status === 'bet' && remainBetTime[0] > 0}
              id='perfect-pair-bet'
              type='pair' hasGap={true} titleSize={'small'}
              onClick={event => handleClickBetButton(event.target, BaccaratBetType.PERFECT_PAIR)}>
              <div className='title'>퍼펙트 페어</div>
              <div className='sub'>더블 200:1</div>
              <BetChip amount={table.curInfo[BaccaratBetType.PERFECT_PAIR]} position={4}/>
            </BettingButton>
            <BettingButton
              enableDrag={gameStatus.status === 'bet' && remainBetTime[0] > 0}
              id='banker-pair-bet'
              type='banker' hasGap={true} titleSize={'small'}
              onClick={event => handleClickBetButton(event.target, BaccaratBetType.BANKER_PAIR)}>
              <div className='title'>뱅커 페어</div>
              <div className='sub'>더블 200:1</div>
              <BetChip amount={table.curInfo[BaccaratBetType.BANKER_PAIR]} position={5}/>
            </BettingButton>
          </div>
          <div className='row'>
            <BettingButton
              hasGap={true}
              enableDrag={gameStatus.status === 'bet' && remainBetTime[0] > 0}
              id='player-bet-option' type='player' titleSize={'small'}
              showOption={showOption === 'player'}
              onClick={event => handleClickBetButton(event.target, BaccaratBetType.PLAYER_BONUS, BaccaratBonusType.PLAYER_MINUS_1)}>
              <div className='title'>{selectedOption.player.title}</div>
              <div className='sub'>{selectedOption.player.value}</div>
              <img onClick={() => handleClickBettingOption('player')}
                   className={'arrow ' + (showOption === 'player' ? 'show' : '')} src='/images/baccarat/Down.svg'/>
              <BetChip amount={table.curInfo[BaccaratBetType.PLAYER_BONUS]?.amount || 0} position={6}/>
            </BettingButton>
            <BettingButton
              hasGap={true}
              enableDrag={gameStatus.status === 'bet' && remainBetTime[0] > 0}
              id='either-pair-bet'
              type='pair' titleSize={'small'}
              onClick={event => handleClickBetButton(event.target, BaccaratBetType.EITHER_PAIR)}>
              <div className='title'>이더 페어</div>
              <div className='sub'>5:1</div>
              <BetChip amount={table.curInfo[BaccaratBetType.EITHER_PAIR]} position={7}/>
            </BettingButton>
            <BettingButton
              hasGap={true}
              enableDrag={gameStatus.status === 'bet' && remainBetTime[0] > 0}
              id='banker-bet-option' type='banker' titleSize={'small'}
              showOption={showOption === 'banker'}
              onClick={event => handleClickBetButton(event.target, BaccaratBetType.BANKER_BONUS, BaccaratBonusType.BANKER_MINUS_1)}>
              <div className='title'>{selectedOption.banker.title}</div>
              <div className='sub'>{selectedOption.banker.value}</div>
              <img onClick={() => handleClickBettingOption('banker')}
                   className={'arrow ' + (showOption === 'banker' ? 'show' : '')} src='/images/baccarat/Down.svg'/>
              <BetChip amount={table.curInfo[BaccaratBetType.BANKER_BONUS]?.amount || 0} position={8}/>
            </BettingButton>
          </div>
        </div>
      </DndContext>
      <BettingChipArea
        handleClickUndo={handleClickUndo}
        onChipSelect={(money) => setSelectedChip(money)}/>
      <BottomWrapper>
        <div className='money-row'>
          <div className='item'>
            <div className='title'>총 배팅금</div>
            <div className='value-wrapper'>
              <img className='icon' src='/images/baccarat/Coin.svg'/>
              <div className='value'>{UserCurrencyMark()}{Object.values(table.curInfo).reduce((a, b) => {
                if (typeof b == "number")
                  return a + Number(b);
                return a + Number(b.amount)
              }, 0).toLocaleString()}</div>
            </div>
          </div>
          <div className='item'>
            <div className='title'>잔고</div>
            <div className='value-wrapper'>
              <img className='icon' src='/images/baccarat/Coin.svg'/>
              <div className='value'>{UserCurrencyMark()}{Number(myBalance).toLocaleString()}</div>
            </div>
          </div>
        </div>
        <div className='menu-button' onClick={() => setShowMenu(true)}>
          <img src='/images/baccarat/Option.svg'/>
        </div>
      </BottomWrapper>
    </div>
  </BaccaratWrapper>
}

export default Baccarat;
