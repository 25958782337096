import styled from "styled-components";
import {useEffect} from "react";

const ResultWrapper = styled.div<{ type: 'player' | 'banker' | 'tie' }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  > .win-wrapper {
    position: absolute;
    width: 212px;
    padding: 8px 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${p => p.type === 'player' ? `
    border-top: 1px solid rgba(47, 153, 251, 0.5);
border-bottom: 1px solid rgba(47, 153, 251, 0.5);
background: linear-gradient(90deg, rgba(47, 153, 251, 0.00) 0%, rgba(47, 153, 251, 0.50) 50.5%, rgba(47, 153, 251, 0.00) 100%);
box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    ` : ``}
    ${p => p.type === 'banker' ? `
    border-top: 1px solid rgba(251, 55, 55, 0.5);
border-bottom: 1px solid rgba(251, 55, 55, 0.5);
background: linear-gradient(90deg, rgba(251, 55, 55, 0.00) 0%, rgba(251, 55, 55, 0.50) 50%, rgba(251, 55, 55, 0.00) 100%);
box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    ` : ``}
    ${p => p.type === 'tie' ? `
    border-top: 1px solid rgba(79, 207, 92, 0.5);
border-bottom: 1px solid rgba(79, 207, 92, 0.5);
background: linear-gradient(90deg, rgba(79, 207, 92, 0.00) 0%, rgba(79, 207, 92, 0.50) 50%, rgba(79, 207, 92, 0.00) 100%);
box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    ` : ``}
    > span {
      color: #FFF;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.2px;
    }
  }

  > .prize-wrapper {
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 212px;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.20) 50%, rgba(0, 0, 0, 0.00) 100%);
    color: #FFF;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;
  }
`;

interface ResultProps {
  type: 'player' | 'banker' | 'tie';
  money: number;
  currency: string;
}

const Result = ({type, money, currency}: ResultProps) => {
  useEffect(() => {
    //prize-wrapper는 win-wrapper 바로 아래에 오도록
    const winWrapper = document.querySelector('.win-wrapper') as HTMLDivElement;
    const prizeWrapper = document.querySelector('.prize-wrapper') as HTMLDivElement;
    if (winWrapper && prizeWrapper) {
      winWrapper.style.top = `calc(50% - ${winWrapper.clientHeight / 2}px)`;
      prizeWrapper.style.top = `${winWrapper.clientHeight
      + parseInt(window.getComputedStyle(winWrapper).top) + -10}px`;
      prizeWrapper.style.left = '50%';
      prizeWrapper.style.transform = 'translateX(-50%)';
    }
  }, []);
  return <ResultWrapper type={type}>
    <div className='win-wrapper'>
      {type === 'player' && <span>플레이어 승리 !</span>}
      {type === 'banker' && <span>뱅커 승리 !</span>}
      {type === 'tie' && <span>무승부</span>}
    </div>
    {money && <div className='prize-wrapper'>{currency} {money.toLocaleString()}</div>}
  </ResultWrapper>
}
export default Result;
