import styled from "styled-components";

const ChatWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  > .title-row {
    flex-shrink: 0;
    padding: 16px 20px 13px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    >.title {
      color: #FFF;
      text-align: center;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;
    }
    >.user {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 2px;
      >span {
        color: #FFF;
        text-align: center;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.2px;
      }
    }
  }
  >.message-list{
    width: 100%;
    background: #2D2D36;
    flex-grow: 1;
    padding: 12px 20px;
    overflow-y: auto;
    >.inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;  
    }
  }
  >.message-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 20px;
    flex-shrink: 0;
    background: #17171B;
    padding-bottom: 42px;
    >input {
      width: 100%;
      padding: 10px 12px;
      border-radius: 20px;
      border: 1px solid #1F1F1F;
      color: #FFF;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.7px;
      background: transparent;
      outline: none;
      &::placeholder {
        opacity: 0.4;
      }
    }
  }
`
const ChatItemWrapper = styled.div<{me: boolean}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${p => p.me ? `
    align-items: flex-end;
  `: `
    align-items: flex-start;
  `}
  >.content {
    max-width: calc(100% - 76px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    >.name-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 4px;
      >.name {
        color: #FFF;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.165px;
      }
      >.time {
        color: #FFF;
        font-family: Pretendard;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.5px;
      }
    }
    >.content {
      padding: 8px 12px;
      border-radius: 4px;
      ${p => p.me ? `
        background: #2F99FB;
      `: `
        background: #1F1F1F;
      `};
      color: #FFF;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.165px;
      white-space: pre-wrap;
      word-break: break-all;
    }
  }
`

const PrizeWrapper = styled.div`
  width: 100%;
  
`
const Chat = () => {
  return <ChatWrapper>
    <div className='title-row'>
      <div className='title'>스피드 바카라 A</div>
      <div className='user'>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M7.99996 8.66667C9.84091 8.66667 11.3333 7.17428 11.3333 5.33333C11.3333 3.49238 9.84091 2 7.99996 2C6.15901 2 4.66663 3.49238 4.66663 5.33333C4.66663 7.17428 6.15901 8.66667 7.99996 8.66667ZM7.99996 8.66667C9.41445 8.66667 10.771 9.22857 11.7712 10.2288C12.7714 11.229 13.3333 12.5855 13.3333 14M7.99996 8.66667C6.58547 8.66667 5.22892 9.22857 4.22872 10.2288C3.22853 11.229 2.66663 12.5855 2.66663 14"
            stroke="white" stroke-width="0.933333" stroke-linecap="round" stroke-linejoin="round"/>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M7.99996 12.5399L6.16663 13.5984C5.9444 13.7267 5.66663 13.5663 5.66663 13.3097L5.66663 11.0003C5.66663 10.7437 5.9444 10.5834 6.16663 10.7117L7.99996 11.7701L9.83329 10.7117C10.0555 10.5834 10.3333 10.7437 10.3333 11.0003V13.3097C10.3333 13.5663 10.0555 13.7267 9.83329 13.5984L7.99996 12.5399Z"
                fill="white"/>
        </svg>
        <span>엘리스</span>
      </div>
    </div>
    <div className='message-list'>
      <div className='inner'>
        {
          Array.from({length: 10}).map((_, i) => {
            const me  = i % 2 === 0;
            return <ChatItemWrapper me={me}>
              <div className='content'>
                {
                  !me && <div className='name-row'>
                    <div className='name'>엘리스</div>
                    <div className='time'>12:00</div>
                  </div>
                }
                <div className='content'>
                  안녕하세요.123456789123456789123456789123456789123456712345612345678912345678945612345678945612345612345678945612
                </div>
              </div>
            </ChatItemWrapper>
          })
        }
      </div>
    </div>
    <div className='message-box'>
      <input placeholder='메시지 작성하기'/>
    </div>
  </ChatWrapper>
}
export default Chat;
