import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {client, requestCurrentRooms, requestIsServerLive, requestJoinRoom, requestWillShutdown, useSocketIsConnect} from "../api";
import {useRecoilState, useSetRecoilState} from "recoil";
import {globalLoadingState} from "../recoil/Loading";
import useDialog from "../hooks/useDialog";
import {connectionState} from "../recoil/Connection";
import {useTranslation} from "react-i18next";
import styled from 'styled-components';
import { setAckListener as setServerLiveListener } from '../api/from_server_game_serverLive';
import { setAckListener as setServerDownListener } from '../api/from_server_game_serverDown';
import useQueryParams from '../hooks/useQueryParams';

const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loading = styled.div`
  color: white;
`

const RejoinCheck: React.FC = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const [conn] = useSocketIsConnect();
    const {
      openDialog,
      closeDialog
    } = useDialog();
    const queryParams = useQueryParams();

    const setGlobalLoading = useSetRecoilState(globalLoadingState);
    const [connected, setConnected] = useRecoilState(connectionState);
    const [serverLive, setServerLive] = useState(true);
    const [serverWillDown, setServerWillDown] = useState(false);

    useEffect(()=>{
      requestIsServerLive();
      requestWillShutdown().then(v=>{
        setServerWillDown(v.result)
      });

      setServerLiveListener(()=>{
        setServerLive(true);
        setServerWillDown(false)
      });
      setServerDownListener(()=>{
        setServerLive(false);
      });
      return ()=>{
        setServerLiveListener(null);
        setServerDownListener(null);
      }
    },[])

    useEffect(() => {
      (async () => {
        console.log(connected, conn.isConnected, conn.isLogin, serverLive, !serverWillDown)
        if (connected) {
          if (!conn.isConnected || !serverLive || serverWillDown) {
            const dialogId = openDialog({
              title: t('재접속'),
              text: t('서버와의 연결이 끊겨 재접속 중입니다...'),
              confirmText: t('연결 취소'),
              disableBackdropClick: true,
              onConfirm: () => {
                history.replace('/');
              }
            });

            client.once('connect', () => {
              closeDialog(dialogId);
            });

            setConnected(false);
          }
        } else {
          if (conn.isConnected && conn.isLogin && serverLive && !serverWillDown) {
            setGlobalLoading(true);

            if(history.location.pathname === '/game'){
              const roomId = queryParams.get("id");
              if (roomId) {
                await requestJoinRoom(roomId);
              }
            }
            
            setConnected(true);
            setGlobalLoading(false);
          }

          setTimeout(()=>{
            requestIsServerLive();
            requestWillShutdown().then(v=>{
              setServerWillDown(v.result)
            });
          },1000 * 1)
        }
      })();
    }, [connected, conn.isConnected, conn.isLogin, serverLive, serverWillDown, queryParams]);

    if(!conn.isConnected || !conn.isLogin || !serverLive || serverWillDown){
      return <LoadingContainer>
        <Loading>Loading...</Loading>
      </LoadingContainer>;
    } 
    return <></>
  }
;
export default RejoinCheck;