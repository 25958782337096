import styled from "styled-components";
import {RefObject, useState} from "react";

const BettingChipAreaWrapper = styled.div<{ show: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  flex-shrink: 0;
  margin: 12px 0;

  > .center-button {
    width: 42px;
    height: 36px;
    position: relative;
    > img {
      position: absolute;
      width: 30px;
      height: 27.5px;
      z-index: 4;
      transition: all 0.5s;
    }
    >.selectable-chip {
      transition: all 0.1s;
      ${p => p.show ? `
       &:nth-child(1) {
        top: 40px;
        left: -36px;
      }
      &:nth-child(2) {
        top: 3px;
        left: -56px;
      }
      &:nth-child(3) {
        top: -33px;
        left: -36px;
      }
      &:nth-child(4) {
        top: -55px;
        left: 50%;
        transform: translateX(-50%);
      }
      &:nth-child(5) {
        top: -33px;
        left: 46px;
      }
      &:nth-child(6) {
        top: 3px;
        left: 66px;
      }
      &:nth-child(7) {
        top: 40px;
        left: 46px;
      }
      &:nth-child(8) {
        width: 36px;
        height: 33px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }  
      `:`
       width: 36px;
        height: 33px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      `}
     
      ${p => p.show ? `
        opacity: 1;
      `: `
        opacity: 0;
        pointer-events: none;
      `}
      
    }
    >.main.left {
      top: unset;
      right: unset;
      left: 0;
      bottom: 0;
      z-index: 4;
      transform: none;
      ${p => p.show ? `
        opacity: 0;
        pointer-events: none;
      `: `
        opacity: 1;
      `}
      
    }
    >.main.right {
      top: unset;
      left: unset;
      right: 0;
      bottom: 0;
      z-index: 5;
      transform: none;
      ${p => p.show ? `
        opacity: 0;
        pointer-events: none;
      `: `
        opacity: 1;
      `}
    }
    >.main.top {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 6;
      ${p => p.show ? `
        opacity: 0;
        pointer-events: none;
      `: `
        opacity: 1;
      `}
    }
    > .chip-background {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 112px;
      height: 112px;
      background: #181A1D;
      border-radius: 50%;
      transition: all 0.5s;
      ${p => p.show ? `
        opacity: 1;
      ` : `
        opacity: 0;
        pointer-events: none;
      `}
    }
  }

  > .button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 15px;
    background: #181A1D;

    > img {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  }

  > .dim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100svw;
    height: 100svh;
    background: #101012;
    z-index: 3;
    ${p => p.show ? `
      opacity: 0.5;
    ` : `
      opacity: 0;
      pointer-events: none;
    `}
  }
`
interface BettingChipAreaProps {
  onChipSelect: (money: number) => void;
  handleClickUndo: () => void;
}
const BettingChipArea = ({onChipSelect, handleClickUndo}:BettingChipAreaProps) => {
  const [showChips, setShowChips] = useState(false);
  const [selectedChipImage, setSelectedChipImage] = useState('Blue.png');
  const [previewChip, setPreviewChip] = useState({
    left: 'Pink.png',
    right: 'Purple.png',
  });
  const handleClickChip = (money: number) => {
    onChipSelect(money);
    if(money === 5) {
      setSelectedChipImage('Red.png');
      setPreviewChip({
        left: 'Yellow.png',
        right: 'Pink.png',
      });
    }else if(money === 10) {
      setSelectedChipImage('Yellow.png');
      setPreviewChip({
        left: 'Green.png',
        right: 'Red.png',
      });
    }else if(money === 100) {
      setSelectedChipImage('Grey.png');
      setPreviewChip({
        left: 'Pink.png',
        right: 'Yellow.png',
      });
    }else if(money === 500) {
      setSelectedChipImage('Pink.png');
      setPreviewChip({
        left: 'Blue.png',
        right: 'Green.png',
      });
    }else if(money === 5000) {
      setSelectedChipImage('Blue.png');
      setPreviewChip({
        left: 'Purple.png',
        right: 'Pink.png',
      });
    }else if(money === 25000) {
      setSelectedChipImage('Purple.png');
      setPreviewChip({
        left: 'Grey.png',
        right: 'Blue.png',
      });
    }else if(money === 100000) {
      setSelectedChipImage('Green.png');
      setPreviewChip({
        left: 'Red.png',
        right: 'Purple.png',
      });
    }
    setShowChips(false)
  }
  const handleClickCenter = () => {
    if(!showChips) {
      setShowChips(true)
    }
  }
  const handleClickCenterChip = () => {
    if(showChips){
      setShowChips(false)
    }
  }
  return <BettingChipAreaWrapper id='bettingChipArea' show={showChips}>
    <div className='button' onClick={handleClickUndo}>
      <img src='/images/baccarat/Undo.svg'/>
    </div>
    <div className='center-button' onClick={handleClickCenter}>
      <img className='selectable-chip' src='/images/baccarat/Chip2/Red.png' onClick={() => handleClickChip(5)}/>
      <img className='selectable-chip' src='/images/baccarat/Chip2/Yellow.png' onClick={() => handleClickChip(10)}/>
      <img className='selectable-chip' src='/images/baccarat/Chip2/Grey.png' onClick={() => handleClickChip(100)}/>
      <img className='selectable-chip' src='/images/baccarat/Chip2/Pink.png' onClick={() => handleClickChip(500)}/>
      <img className='selectable-chip' src='/images/baccarat/Chip2/Blue.png' onClick={() => handleClickChip(5000)}/>
      <img className='selectable-chip' src='/images/baccarat/Chip2/Purple.png' onClick={() => handleClickChip(25000)}/>
      <img className='selectable-chip' src='/images/baccarat/Chip2/Green.png' onClick={() => handleClickChip(100000)}/>
      <img className='selectable-chip' src={`/images/baccarat/Chip2/${selectedChipImage}`} onClick={() => handleClickCenterChip()}/>

      <img className='main left' src={`/images/baccarat/Chip2/${previewChip.left}`} />
      <img  id='center-button' className='main top' src={`/images/baccarat/Chip2/${selectedChipImage}`}/>
      <img className='main right' src={`/images/baccarat/Chip2/${previewChip.right}`}/>

      <div className='chip-background'>
      </div>
    </div>
    <div className='button'>
      <img src='/images/baccarat/Double.svg'/>
    </div>
    <div className='dim'/>
  </BettingChipAreaWrapper>
}
export default BettingChipArea;
