import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {
  connectionData,
  requestCurrentRooms,
  requestDirectLinkLogin,
  requestJoinRoom,
  requestLeaveRoom,
  requestLogin,
  useSocketIsConnect
} from "../api";
import {sha256} from "js-sha256";
import useDialog from "../hooks/useDialog";
import useQueryParams from "../hooks/useQueryParams";
import {useHistory} from "react-router-dom";
import useScreenOrientation from "../hooks/useScreenOrientation";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

function MoveRoom() {
  const {t} = useTranslation();
  return <Wrapper>
    {t('방 이동 중...')}
  </Wrapper>
}

export default MoveRoom;
