import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { BaccaratResultInfo } from '../dataset';
import { use } from 'i18next';
import Player from './game/Player';

export type LoadSimulatorType = 'bigload' | 'sixload' | 'bigeye' | 'smalleye' | 'stick';
const cellsize = {
    ["bigload"]:18,
    ["sixload"]:40,
    ["bigeye"]:22,
    ["smalleye"]:22,
    ["stick"]:22
}
const dotSize = {
    ["bigload"]:18,
    ["sixload"]:40,
    ["bigeye"]:11,
    ["smalleye"]:11,
    ["stick"]:11
}

const LoadContainer = styled.div<{
    cellSize:number
}>`
    overflow: hidden;
    width: 100%;
    flex:1;
    border-radius: 5px;
    overflow: hidden;

    background-color: white;
    background-image: 
        linear-gradient(0deg, #ccc 1px, transparent 1px),
        linear-gradient(90deg, #ccc 1px, transparent 1px);
    background-size: ${p=>p.cellSize}px ${p=>p.cellSize}px;
    position: relative;
`

const Stick = styled.div<{
    cellSize:number,
    widthSize:number,
    color:string,
    x:number,
    y:number,
}>`
    height: ${p=>p.cellSize}px;

    position: absolute;
    transform:translate(${p=>p.x+p.cellSize/2 - p.widthSize/2}px, ${p=>p.y}px) rotate(45deg) scaleY(${p=>Math.sqrt(2)*0.85});

    border-right: ${p=>p.widthSize}px solid ${p=>p.color} ;
`

const TieNum = styled.div<{
    cellSize:number,
    x:number,
    y:number,
}>`
    position: absolute;
    transform:translate(${p=>p.x}px, ${p=>p.y}px);

    width: ${p=>p.cellSize}px;
    height: ${p=>p.cellSize}px;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-weight: bold;

    font-size: 12px;
    padding: 0 5px 5px 0;
`

const Circle = styled.div<{
    cellSize:number,
    widthSize:number,
    color:string,
    x:number,
    y:number,
}>`
    position: absolute;
    transform:translate(${p=>p.x+1}px, ${p=>p.y+1}px);

    width: ${p=>p.cellSize-2}px;
    height: ${p=>p.cellSize-2}px;

    border-radius: 50%;
    background-color:${p=>p.color};
    border: 2px solid rgba(255,255,255,0.2);
    display: flex;

    justify-content: center;
    align-items: center;

    font-weight: bold;
    color: white;
`

const Ring = styled.div<{
    cellSize:number,
    widthSize:number,
    color:string,
    x:number,
    y:number,
}>`
    position: absolute;
    transform:translate(${p=>p.x+2}px, ${p=>p.y+2}px);

    width: ${p=>p.cellSize-4}px;
    height: ${p=>p.cellSize-4}px;

    border-radius: 50%;
    border: ${p=>p.widthSize}px solid ${p=>p.color} ;
`

function bigload(_results:BaccaratResultInfo[],columnsCount:number) {
    let results: (BaccaratResultInfo|null)[] = [..._results];
    let row:number = 0;
    let column:number = -1;
    let prev:BaccaratResultInfo = results[0]!;
    let maxTops:{[key:number]:number} = {};
    let maxAddRow = 0;

    let maxX = 0;
    let list = results.map((result, index) => {
        if(result == null) return <></>;

        maxX = Math.max(maxX, row * dotSize['bigload'])

        if(result.result == "tie"){
            let count = 0;
            for(let i=index+1; i<results.length; i++){
                let q = results[i];
                if( q != null ){
                    if(q.result != "tie") break;
                    results[i] = null;
                    count++;
                }else{
                    break;
                }
            }
            return <><Stick
                key={index}
                widthSize={2}
                cellSize={cellsize['bigload']}
                color={"rgba(33, 176, 47, 1)"}
                x={row * cellsize['bigload']}
                y={column * cellsize['bigload']}
            ></Stick>
            {count ? <TieNum
                key={index+"tie"}
                x={row * cellsize['bigload']}
                y={column * cellsize['bigload']}
                cellSize={cellsize['bigload']}
            >{count}</TieNum> : null }
            </>
        }

        if(prev.result != result.result){
            row++;
            column = -1;
            maxAddRow = 0;
        }

        const maxTop = maxTops[column] || columnsCount;
        prev = result;
        column++;

        if(maxTop <= column){
            column = maxTop-1;
            maxAddRow++;
            maxTops[row+maxAddRow] = column-1;
        }

        return <Ring
            key={index}
            cellSize={dotSize['bigload']}
            widthSize={2}
            color={result.result == "banker" ? "red" : "blue"}
            x={(row+maxAddRow) * dotSize['bigload']}
            y={column * dotSize['bigload']}
        />
    }).filter(v=>!!v);

    return {
        list:list,
        maxX
    }
}

function sixload(results:BaccaratResultInfo[],columnsCount:number) {
    let row:number = 0;
    let column:number = -1;
    let maxX = 0;
    let list = results.map((result, index) => {
        if(column == columnsCount-1){
            column = -1;
            row ++;
        }
        column++;

        maxX = Math.max(maxX, row * dotSize['sixload'])

        const P = result.result == "banker" ? "P" : (result.result == "tie" ? "T" : "B");

        return <Circle
            key={index}
            cellSize={dotSize['sixload']}
            widthSize={2}
            color={result.result == "banker" ? "red" : (result.result == "tie" ? "green":"blue" )}
            x={row * dotSize['sixload']}
            y={column * dotSize['sixload']}
        >{P}</Circle>
    }).filter(v=>!!v);

    return {
        list:list,
        maxX
    }
}

function ChineseLoad(type:LoadSimulatorType, results:BaccaratResultInfo[], Component:(index:number, row:number, column:number, color:string)=>JSX.Element, pivotLeft:number, columnsCount:number){
    results = results.filter(v=>v.result != 'tie');
    const pivots = [];
    let rows:BaccaratResultInfo[] = []
    for(let key in results){
        let r1 = results[Number(key)];
        let r2 = results[Number(key)+1];

        rows.push(r1);

        if(r2 == null || r1.result != r2.result){
            pivots.push(rows);
            rows = [];
        }
    }

    if(pivots.length < pivotLeft) return {maxX:0, list:[<></>]};

    const load:string[] = []
    const stable:string = "red";
    const unstable:string = "blue";
    for(let x = pivotLeft; x < pivots.length; x++){
        let rows = pivots[x];
        let pivot = pivots[x-pivotLeft];

        for(let y = 1;y<rows.length;y++){
            const ly1 = pivot[y];
            const ly2 = pivot[y-1];

            let y1 = ly1 ? ly1.result : "";
            let y2 = ly2 ? ly2.result : "";

            load.push(y1 == y2 ? stable : unstable)
            console.log(load)
        }

        load.push(rows.length == pivot.length ? stable : unstable)
    }

    let maxX = 0;
    let row:number = 0;
    let column:number = -1;
    let maxTops:{[key:number]:number} = {};
    let maxAddRow = 0;
    let list = load.map((result, idx) => {
        let r1 = load[Number(idx)];
        let r2 = load[Number(idx)-1];

        if(r1 != r2){
            row++;
            column = -1;
            maxAddRow = 0;
        }

        maxX = Math.max(maxX, row * dotSize[type])

        const maxTop = maxTops[column] || columnsCount;
        column++;

        if(maxTop <= column){
            column = maxTop-1;
            maxAddRow++;
            maxTops[row+maxAddRow] = column-1;
        }

        return Component(idx, row+maxAddRow-1, column, r1)
    });

    return {
        maxX:maxX,
        list:list
    }
}

function bigeye(results:BaccaratResultInfo[], columnsCount:number){
    return ChineseLoad(
        'bigeye',
        results,
        (idx:number, row:number, column:number, color:string)=>{
            return <Ring
                key={idx}
                cellSize={dotSize['bigeye']}
                widthSize={2}
                color={color}
                x={row * dotSize['bigeye']}
                y={column * dotSize['bigeye']}
            />
        },
        1,
        columnsCount
    );
}

function smalleye(results:BaccaratResultInfo[], columnsCount:number) {
    return ChineseLoad(
        'smalleye',
        results,
        (idx:number, row:number, column:number, color:string)=>{
            return <Circle
                key={idx}
                cellSize={dotSize['smalleye']}
                widthSize={4}
                color={color}
                x={row * dotSize['smalleye']}
                y={column * dotSize['smalleye']}
            />
        },
        2,
        columnsCount
    );
}

function stick(results:BaccaratResultInfo[], columnsCount:number) {
    return ChineseLoad(
        'stick',
        results,
        (idx:number, row:number, column:number, color:string)=>{
            return <Stick
                key={idx}
                cellSize={dotSize['stick']}
                widthSize={2}
                color={color}
                x={row * dotSize['stick']}
                y={column * dotSize['stick']}
            />
        },
        3,
        columnsCount
    );
}


const LoadSimulator = ({
    results,
    columnsCount,
    simulatorType,
}:{
    results:BaccaratResultInfo[],
    columnsCount:number,
    simulatorType:LoadSimulatorType,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [dots, setDots] = useState<{list:JSX.Element[], maxX:number}>({list:[], maxX:0});

    useEffect(() => {
        if(containerRef.current == null) return ;
        if(simulatorType === 'bigload') {
            setDots(bigload(results, columnsCount));
        } else if(simulatorType === 'sixload') {
            setDots(sixload(results, columnsCount));
        } else if(simulatorType === 'bigeye') {
            setDots(bigeye(results, columnsCount));
        } else if(simulatorType === 'smalleye') {
            setDots(smalleye(results, columnsCount));
        } else if(simulatorType === 'stick') {
            setDots(stick(results, columnsCount));
        }
    }, [results, simulatorType, columnsCount]);


    const translateX = useMemo(function(){
        const containerWidth = containerRef.current?.clientWidth || 0;
        const maxCell = Math.floor(containerWidth / dotSize[simulatorType])-4;
        const curCell = Math.floor(dots.maxX/ dotSize[simulatorType])

        const result = Math.min(0, maxCell - curCell) * dotSize[simulatorType];
        console.log({
            simulatorType,
            maxCell,
            curCell,
            containerWidth,
            result
        })

        return result;
    },[dots, containerRef.current?.clientWidth]) 

    return <LoadContainer ref={containerRef} cellSize={cellsize[simulatorType]}>
        <div style={{transform:`translateX(${translateX}px)`}}>
            {dots.list}
        </div>
    </LoadContainer>;
};


export default LoadSimulator;
