import {useRecoilState} from "recoil";
import {DialogProps, dialogState, DialogState} from "../recoil/Dialog";
import {useCallback} from "react";
import produce from 'immer';

let nextDialogId = 1;

function useDialog() {
  const [dialogs, setDialogs] = useRecoilState(dialogState);

  const handleClose = useCallback((dialogId: number) => {
    // setDialogs(produce(d => {
    //   const idx = d.findIndex(x => x.id === dialogId);
    //   if (idx !== -1) {
    //     d[idx].visibility = false;
    //   }
    // }));
    //화면에서 가리는게 아니라 삭제하기.
    setDialogs(produce(d => {
      const idx = d.findIndex(x => x.id === dialogId);
      if (idx !== -1) {
        d.splice(idx, 1);
      }
    }));
  }, []);

  const handleCloseAll = useCallback(() => {
    // setDialogs(produce(d => {
    //   d.forEach(x => {
    //     x.visibility = false;
    //   });
    // }));
    //화면에서 가리는게 아니라 삭제하기.
    setDialogs([]);
  }, []);

  const handleCloseByPopupId = useCallback((popupId: string) => {
    setDialogs(produce(d => {
      const idx = d.findIndex(x => x.popupId === popupId);
      if (idx !== -1) {
        d.splice(idx, 1);
      }
    }));
  }, []);

  const handleOpen = useCallback((props: DialogProps): number => {
    if(props.popupId){
      const idx = dialogs.findIndex(x => x.popupId === props.popupId);
      if (idx !== -1) {
        setDialogs(produce(d => {
          d[idx].visibility = true;
        }));
        console.log("already open dialog", dialogs[idx].id);
        return dialogs[idx].id;
      }
    }
    
    const id = nextDialogId++;
    const dialogProps: DialogState = {
      ...props,
      id: id,
      visibility: true,
      disableCloseButton: props.disableCloseButton
    };

    dialogProps.onConfirm = () => {
      props.onConfirm && props.onConfirm(() => handleClose(id));
      if (!props.disableAutoClose) {
        handleClose(id);
      }
    };

    dialogProps.onCancel = () => {
      props.onCancel && props.onCancel();
      handleClose(id);
    };

    if (!props.disableBackdropClick) {
      dialogProps.onBackdropClick = () => {
        handleClose(id);
      };
    }

    setDialogs(produce(d => {
      d.push(dialogProps);
    }));

    return id;
  }, [dialogs, handleClose]);

  return {
    dialogs: dialogs,
    openDialog: handleOpen,
    closeDialog: handleClose,
    closeAllDialog : handleCloseAll,
    closeDialogByPopupId: handleCloseByPopupId
  };
}

export default useDialog;