import styled from "styled-components";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {emoticonBanState, myInfoState} from "../../recoil/MyInfo";
import React, {useCallback, useEffect, useState} from "react";
import RadioButton from "../common/RadioButton";
import {AlwaysBBType, gameOptionState} from "../../recoil/GameOption";
import ModalContainer from "../common/ModalContainer";
import ProfileModal, {ModifyType} from "../ProfileModal";
import {useTranslation} from "react-i18next";
import SettingModal from "../SettingModal";
import ToggleSwitch from "../ToggleSwitch";
import {requestLogOut, requestToggleEmoticonBan, USER_TOKEN} from "../../api";
import {globalLoadingState} from "../../recoil/Loading";
import MyGameHistory from "../game/MyGameHistory";
import { ROOM_TYPE } from "../../dataset";

const Wrapper = styled.div`
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  color: #FFF;
  font-size: 14px;

  ul {
    padding: 0 16px;

    li {
      padding: 12px 0;
      border-bottom: 1px solid #17171B;
      min-height: 44px;
      cursor: pointer;

      &[data-nopointer="true"] {
        cursor: initial;
      }
    }
  }
`;

const User = styled.div`
  display: flex;
  gap: 16px;
  padding: 12px 8px;
  border-radius: 4px;
  background: #17171B;

  > img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
  }

  > .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div:first-child {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    > div:last-child {
      display: flex;
      gap: 8px;

      > div {
        font-weight: 400;
        display: flex;
        padding: 8px;
        border-radius: 6px;
        background: #1F1F1F;
        cursor: pointer;
        transition: all 0.1s linear;

        &:hover, &:active {
          background: #505050;
        }
      }
    }
  }
`;

const UserProfileBtn = styled.div`
  font-size : 12px;
`;


const SettingItem = styled.div`
  display: flex;
  padding: 0 8px;

  .name {
    flex: 1;
  }

  .checkbox-gap {
    padding-left: 20px;
  }
`;

function Setting() {
  const {
    t,
    i18n
  } = useTranslation();
  const setGlobalLoading = useSetRecoilState(globalLoadingState);
  const myInfo = useRecoilValue(myInfoState);
  const emoticonBanList = useRecoilValue(emoticonBanState);
  const [gameOption, setGameOption] = useRecoilState(gameOptionState);
  const [currentLang, setCurrentLang] = useState('');
  const [modifyProfileType, setModifyProfileType] = useState<ModifyType>()
  const [myRingPlayRecordsPopup, setMyRingPlayRecordsPopup] = useState<boolean>(false)
  const [myTournamentPlayRecordsPopup, setMyTournamentPlayRecordsPopup] = useState<boolean>(false)
  const [showChangeCardStyle, setShowChangeCardStyle] = useState<boolean>(false);

  console.log(emoticonBanList)

  const setAlwaysBB = useCallback((alwaysBB: AlwaysBBType) => {
    setGameOption((prev) => ({
      ...prev,
      alwaysBB: alwaysBB
    }));

    if(alwaysBB != AlwaysBBType.bb){
      localStorage.setItem("toggleViewFiat", (alwaysBB == AlwaysBBType.fiat).toString());
    }
  }, []);

  const setMuteBGM = useCallback((flag: boolean) => {
    setGameOption((prev) => ({
      ...prev,
      bgmMuted: flag
    }));
  }, []);

  const setMuteSFX = useCallback((flag: boolean) => {
    setGameOption((prev) => ({
      ...prev,
      sfxMuted: flag
    }));
  }, []);

  const setEmoticonBan = useCallback((flag: boolean) => {
    requestToggleEmoticonBan(0);
  },[]);

  const setLang = useCallback(async (lang: string) => {
    await i18n.changeLanguage(lang);
    localStorage.setItem('i18nextLng', lang);
    setCurrentLang(lang);
  }, []);

  const openTelegram = useCallback(() => {
    window.open('https://t.me/RevoL119', '_blank')
  }, []);

  const signOut = useCallback(async () => {
    setGlobalLoading(true);
    await requestLogOut();
    setGlobalLoading(false);
    localStorage.removeItem(USER_TOKEN)
    window.location.href = 'about:blank';
    window.close();
  }, []);

  useEffect(() => {
    setCurrentLang(localStorage.getItem('i18nextLng') || 'ko-KR');
  }, []);

  return <Wrapper>
    <ul>
      <li>
        <User>
          <img src={`/images/profile/${myInfo?.profileImg}.png`}/>
          <div className="content">
            <div>{myInfo?.nickname}</div>
            <div>
              {/*<div onClick={() => setModifyProfileType('nickname')}>{t('닉네임 변경')}</div>*/}
              <UserProfileBtn onClick={() => setModifyProfileType('image')}>{t('프로필 변경')}</UserProfileBtn>
              <UserProfileBtn onClick={() => setMyRingPlayRecordsPopup(true)}>{t('캐시게임기록')}</UserProfileBtn>
              <UserProfileBtn onClick={() => setMyTournamentPlayRecordsPopup(true)}>{t('토너먼트기록')}</UserProfileBtn>
            </div>
          </div>
        </User>
      </li>
      <li data-nopointer="true">
        <SettingItem>
          <div className="name">{t('칩 표시 변경')}</div>
          <RadioButton checked={gameOption.alwaysBB == AlwaysBBType.chip} onChecked={() => setAlwaysBB(AlwaysBBType.chip)}>{t('금액 표시')}</RadioButton>
          <div className="checkbox-gap"/>
          <RadioButton checked={gameOption.alwaysBB == AlwaysBBType.fiat} onChecked={() => setAlwaysBB(AlwaysBBType.fiat)}>{t('화폐 표시')}</RadioButton>
          <div className="checkbox-gap"/>
          <RadioButton checked={gameOption.alwaysBB == AlwaysBBType.bb} onChecked={() => setAlwaysBB(AlwaysBBType.bb)}>{t('BB 표시')}</RadioButton>
        </SettingItem>
      </li>
      <li>
        <SettingItem onClick={() => setShowChangeCardStyle(true)}>
          <div className="name">{t('카드 스킨 설정')}</div>
          <img src="/images/ic_setting_arrow.svg"/>
        </SettingItem>
      </li>
      <li onClick={() => setMuteBGM(!gameOption.bgmMuted)}>
        <SettingItem>
          <div className="name">{t("BGM 끄기")}</div>
          <ToggleSwitch checked={gameOption.bgmMuted} onChange={setMuteBGM} />
        </SettingItem>
      </li>
      <li onClick={() => setMuteSFX(!gameOption.sfxMuted)}>
        <SettingItem>
          <div className="name">{t("효과음 끄기")}</div>
          <ToggleSwitch checked={gameOption.sfxMuted} onChange={setMuteSFX} />
        </SettingItem>
      </li>
      <li onClick={() => setEmoticonBan(!(emoticonBanList.filter(e=>e == 0).length != 0))}>
        <SettingItem>
          <div className="name">{t("이모티콘 끄기")}</div>
          <ToggleSwitch checked={emoticonBanList.filter(e=>e == 0).length != 0} onChange={setEmoticonBan} />
        </SettingItem>
      </li>
      {/*<li>*/}
      {/*  <SettingItem onClick={() => alert("내 데이터 (전적,손익금액,플레이 데이터)")}>*/}
      {/*    <div className="name">{t('내 데이터 (전적,손익금액,플레이 데이터)')}</div>*/}
      {/*    <img src="/images/ic_setting_arrow.svg"/>*/}
      {/*  </SettingItem>*/}
      {/*</li>*/}
      {/*<li>*/}
      {/*  <SettingItem onClick={() => alert("게임 기록")}>*/}
      {/*    <div className="name">{t('게임기록')}</div>*/}
      {/*    <img src="/images/ic_setting_arrow.svg"/>*/}
      {/*  </SettingItem>*/}
      {/*</li>*/}
      <li onClick={openTelegram}>
        <SettingItem>
          <div className="name">{t('오류제보')}</div>
          <img src="/images/ic_setting_arrow.svg"/>
        </SettingItem>
      </li>
      <li data-nopointer="true">
        <SettingItem>
          <div className="name">{t('언어')}</div>
          <RadioButton checked={currentLang === 'en-US'} onChecked={() => setLang('en-US')}>ENGLISH</RadioButton>
          <div className="checkbox-gap"/>
          <RadioButton checked={currentLang === 'jp-JP'} onChecked={() => setLang('jp-JP')}>日本語</RadioButton>
          <div className="checkbox-gap"/>
          <RadioButton checked={currentLang === 'ko-KR'} onChecked={() => setLang('ko-KR')}>한국어</RadioButton>
          <div className="checkbox-gap"/>
          <RadioButton checked={currentLang === 'th-TH'} onChecked={() => setLang('th-TH')}>ภาษาไทย</RadioButton>
        </SettingItem>
      </li>
      <li onClick={signOut}>
        <SettingItem>
          <div className="name">{t('로그아웃')}</div>
        </SettingItem>
      </li>
    </ul>
    {
      myInfo?.userId && (
        <ModalContainer show={Boolean(modifyProfileType)}>
          <ProfileModal
            userId={myInfo.userId}
            onClose={() => setModifyProfileType(undefined)}
            modifyType={modifyProfileType}
          />
        </ModalContainer>
      )
    }
    {
      myRingPlayRecordsPopup && (
        <ModalContainer show={myRingPlayRecordsPopup}>
          <MyGameHistory 
            type={ROOM_TYPE.RING}
            onClose={()=>setMyRingPlayRecordsPopup(false)}
            />
        </ModalContainer>
      )
    }
    {
      myTournamentPlayRecordsPopup && (
        <ModalContainer show={myTournamentPlayRecordsPopup}>
          <MyGameHistory 
            type={ROOM_TYPE.TOURNAMENT}
            onClose={()=>setMyTournamentPlayRecordsPopup(false)}
            />
        </ModalContainer>
      )
    }
    <ModalContainer show={showChangeCardStyle} onBackdropClick={() => setShowChangeCardStyle(false)}>
      <SettingModal
        onClose={() => setShowChangeCardStyle(false)}
        defaultStep={'card'}
      />
    </ModalContainer>
  </Wrapper>
}

export default Setting;