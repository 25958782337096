export enum ROOM_JOIN_STATUS {
    OBSERVE, // JUST JOINER // can leave room
    BUYIN_READY, // buyin, // can not leave room forcely
    PLAYING, // play in game // can not leave room forcely
    FOLD, // playing status but now die // can not leave room forcely
}

export enum RAKE_TYPE {
    NONE,
    POT_RAKE,
    HAND_RAKE
}

export enum PROFIT_TYPE {
    NONE,
    POT_RAKE,
    HAND_RAKE,
    LEAVEROOM_HAND_LOSS,
    TOURNAMENT_BUYIN
}

export enum ROOM_STATUS {
    READY,
    INGAME,
}

export enum ROOM_TYPE {
    TOURNAMENT,
    RING,
}

export type CreateRingRoomInterface = {
    type: ROOM_TYPE.RING;
    buyIn: number;
    smallBlind: number;
    bigBlind: number;
    blind: number[];
    endDate: string;
    minBuyin: number;
    maxBuyin: number;
    maxTableMember: number;
    isStraddle: boolean;
    isAnte: boolean;
    ante: number;
    rakeType: RAKE_TYPE;
    rake: number;
}
export interface CreateTournamentRoomInterface {
    type: ROOM_TYPE.TOURNAMENT;
    buyIn: number;
    smallBlind: number;
    bigBlind: number;
    blindStructure: number[][];
    name: string;
    startedAt: string;
    buyinPrice: number;
    addonPrice: number;
    beginChip: number;
    rebuyinChip: number;
    addOnChip: number;
    preRegisterBenefitTimeMinutes: number;
    preRegisterBenefitChip: number;
    prize: number[];
    availableRegisterLevel: number;
    minTotalMember: number;
    minStartMember: number;
    maxTotalMember: number;
    maxTableMember: number;
    finalTableMember: number;
    rebuyinLimit: number;
    timeStructure: {
        playTimeSeconds: number;
        restTimeSeconds: number;
    }, // 플레이 시간 구조
    isAnte: boolean,
    isStraddle: boolean,
    rakeType: RAKE_TYPE,

    isGuarantee: boolean,
    guaranteeMinAmount: number,
    guaranteeBuyinSplitRatio: number,

    isNormalPrize: boolean,
    normalPrize: { max: number, grade: number[] }[],
    normalPrizeBuyinRatio: number,
}
export type AddRoomInterface = CreateRingRoomInterface | CreateTournamentRoomInterface;

export enum GAME_STATUS {
    NO_PLAYING,
    BETTING,
    FLAP_CARD,
    CHOOSE_WINNER
}

export enum BET_TYPE {
    BLIND,
    CHECK,
    CALL,
    FOLD,
    RAISE,
    ALLIN,
    BET,
    SB,
    BB,
    STR,
    ANTE
}

export interface Text {
    ko: string;
    en: string;
}

export interface MyInfo {
    userId: number;
    money: number;
    nickname: string;
    profileImg: string;
    currency: string;
}

export interface GamePlayer {
    status: ROOM_JOIN_STATUS;
    userId: number;
    nickname: string;
    profileImg: string;
    seat: number;
    buyin: number;
    stackSize: number;
    prevStackSize: number;
    handsCount: number;
    bet: number;
    ante: number;
    rake: number;
    blindWait: boolean;
    lastAction: BET_TYPE;
    left: boolean;
    leaveRoomTime: string;
    waitGame: boolean;
    vpip: number;
    extraData: Object;
    currency: string;
}

export interface SimpleTournamentUser {
    userId: number,
    stackSize: number,
    nickname: string,
    profileImg: string,
    ranking: number
}

export interface RingGameInfo {
    groupId: number;
    roomId: number;
    roomName: string;
    data: any;
    playerCount: number;
    viewerCount: number;
    players: number[];
}

export interface GroupInfo {
    groupId: number;
    type: ROOM_TYPE;
    data: any;
    isOpen: boolean;
    isEnd: boolean;
    addedAt: string;
    tableCount: number;

    totalPlayerCount: number;
    totalRegisterCount: number;
    totalBuyin: number;
    buyinCount: number;
    isRegister: boolean;
    ranking: number;
    stackSize: number;
}

export interface RoomStatus {
    roomId: number;
    groupId: number;
    gameName: string;
    roomStatus: ROOM_STATUS;
    gameStatus: GAME_STATUS;
    groupData: any;
}

export interface WaitBuyin {
    userId: number;
    amount: number;
}

export interface RoomInfo extends RoomStatus {
    type: ROOM_TYPE;
    players: GamePlayer[];
    buttonPosition: number;
    cards: number[];
    pots: number[];
    minRaise: number;
    handNumber: number;
    currentBet: number;
    currentRound: string;
    willShutdown: boolean;
    noticeText: {
        KR:string,
        JP:string,
        EN:string,
        THAI:string,
    };
    waitBuyins: WaitBuyin[];
}

export interface MyPlayStatus extends GamePlayer {

}

export interface NoticeSimpleInfo {
    noticeId: number;
    title: string;
    thumb: string;
    addedAt: string;
    langData: {
        [key: string]: {
            title: string;
            thumb: string;
        }
    }
}

export type RakePercentage = {
    "BET_RAKE": {
        subhq: { agencyId: number, percentage: number }[];
        hq: number;
        agency: number;
        my: number;
    },
    "HANDS_PENALTY": {
        subhq: { agencyId: number, percentage: number }[];
        hq: number;
        agency: number;
        my: number;
    },
    "TOURNAMENT_BUYIN": {
        subhq: { agencyId: number, percentage: number }[];
        hq: number;
        agency: number;
        my: number;
    }
}

export interface NoticeInfo {
    noticeId: number;
    title: string;
    thumb: string;
    image: string;
    text: string;
    addedAt: string;

    langData: {
        [key: string]: {
            title: string;
            thumb: string;
            img: string;
            text: string;
        }
    }
}

export interface winnerListModel {
    userId: number;
    nickname: string;
    prize: number;
    gtd: number;
    suppliedPrize: number;
    currency: string;
}

export interface winnerModel {
    userId: number;
    amount: number;
}

export interface userCardModel {
    userId: number;
    cards: Array<number>;
}

export interface tournamentParticipateModel {
    roomId: number;
    userIds: Array<number>;
}

export interface emitAfterBetModel {
    lastBetStatus: {
        userId: number;
        type: BET_TYPE;
        bet: number;
    };
    playerBettings: Array<PlayersBettings>;
    pots: Array<{
        amount: number;
        potRake: number;
        eligiblePlayers: Array<number>;
    }>;
    myStatus: MyPlayStatus;
    isAllIn: boolean;
}

export interface PlayersBettings {
    id: number;
    bet: number;
    ante: number;
    rake: number;
    stackSize: number;
    prevStackSize: number;
    folded: boolean;
}

export interface requestBetModel {
    roomId: number,
    leftSec: number,
    lastPlayer: number,
    userId: number,
    legalAct: string[]
}

export interface TableCompactModel {
    groupId: number;
    roomType: ROOM_TYPE;
}

export interface handHistoryModel {
    betId: number;
    groupId: number;
    roomId: number;
    game: number;
    round: number;
    userId: number;
    type: BET_TYPE;
    bet: number;
    fold: boolean;
    bettedAt: string;
    nickname: string;
    agencyName: string;
    profileImg: string;
    seat: number;
    position: string;
    lastGameData: {
        cards: Array<{
            userId: number;
            cards: Array<number>;
        }>;
        winners: Array<{
            userId: number;
            amount: number;
        }>;
        changeStackSizeList: Array<{
            userId: number;
            stackSize: number;
            prevStackSize: number;
        }>;
        communityCards: Array<number>;
        pots: Array<{
            amount: number;
            eligiblePlayers: Array<number>;
        }>;
    } | null;
}

export interface userProfileModel {
    userId: number;
    nickname: string;
    profileImg: string;
    handCount: number;
    vpip: number;
    winRate: number;
    pfr: number;
    bet3: number;
    ats: number;
}

export interface tournamentRankingModel {
    userId: number;
    stackSize: number;
    ranking: number;
}

export const CARD_INFO = [
    "2c",
    "3c",
    "4c",
    "5c",
    "6c",
    "7c",
    "8c",
    "9c",
    "Tc",
    "Jc",
    "Qc",
    "Kc",
    "Ac",
    "2d",
    "3d",
    "4d",
    "5d",
    "6d",
    "7d",
    "8d",
    "9d",
    "Td",
    "Jd",
    "Qd",
    "Kd",
    "Ad",
    "2h",
    "3h",
    "4h",
    "5h",
    "6h",
    "7h",
    "8h",
    "9h",
    "Th",
    "Jh",
    "Qh",
    "Kh",
    "Ah",
    "2s",
    "3s",
    "4s",
    "5s",
    "6s",
    "7s",
    "8s",
    "9s",
    "Ts",
    "Js",
    "Qs",
    "Ks",
    "As",
]

export type EXCHANGE_RATE = { [SYMBOL: string]: number };

export interface DeckStatusInterface {
    id: number,
    status: string,
    requested: string,
    received: string,
    txHash: string,
    card: number[]
}

export interface RecentHistoryItem {
    deckId: string,
    seed: string,
    playerCards: number[],
    playerScore: number,
    bankerCards: number[],
    bankerScore: number,
    result: 'player' | 'banker' | 'tie' | null,
    txHash: string
}

export interface BaccaratTable{
    tableId:number,
}

export interface BaccaratGameStatus {
    status: 'bet' | 'shuffle' | 'result' | 'clear' | 'wait'
    baccaratCards: {
        deckId: string,
        seed: string,
        txHash: string,
        playerCards: number[],
        playerScore: number,
        bankerCards: number[],
        bankerScore: number,
        status: 'normal' | 'natural' | 'stand'
        result: 'player' | 'banker' | 'tie' | null
    } | null,
    // history: RecentHistoryItem[]
}

export interface BaccaratTotalBet {
    player:{
        total: number,
        ratio: number,
        count: number,
    },
    tie:{
        total: number,
        ratio: number,
        count: number,
    },
    banker:{
        total: number,
        ratio: number,
        count: number,
    }
}

export enum BaccaratBetType{
    NONE = -1,

    PLAYER,
    BANKER,
    TIE,
    
    PLAYER_BONUS,
    BANKER_BONUS,
    PERFECT_PAIR,

    EITHER_PAIR,
    PLAYER_PAIR,
    BANKER_PAIR,
}

export enum BaccaratBonusType{
    NONE = -1,

    PLAYER_PLUS_1,
    PLAYER_PLUS_2,
    PLAYER_PLUS_3,
    PLAYER_PLUS_4,
    PLAYER_PLUS_5,
    PLAYER_PLUS_6,
    PLAYER_PLUS_7,
    PLAYER_MINUS_1,
    PLAYER_MINUS_2,
    PLAYER_MINUS_3,
    PLAYER_MINUS_4,
    PLAYER_MINUS_5,
    PLAYER_MINUS_6,
    PLAYER_MINUS_7,

    BANKER_PLUS_1,
    BANKER_PLUS_2,
    BANKER_PLUS_3,
    BANKER_PLUS_4,
    BANKER_PLUS_5,
    BANKER_PLUS_6,
    BANKER_PLUS_7,
    BANKER_MINUS_1,
    BANKER_MINUS_2,
    BANKER_MINUS_3,
    BANKER_MINUS_4,
    BANKER_MINUS_5,
    BANKER_MINUS_6,
    BANKER_MINUS_7,
}

export const BaccaratBonusTypeList:{
    [key:number]:{name:string, score:number, bonus:number}
} = {
    [BaccaratBonusType.PLAYER_PLUS_1]:{name:'플레이어 7.5 플러스 핸드', score:7.5, bonus:1.10},
    [BaccaratBonusType.PLAYER_PLUS_2]:{name:'플레이어 6.5 플러스 핸드', score:6.5, bonus:1.13},
    [BaccaratBonusType.PLAYER_PLUS_3]:{name:'플레이어 5.5 플러스 핸드', score:5.5, bonus:1.15},
    [BaccaratBonusType.PLAYER_PLUS_4]:{name:'플레이어 4.5 플러스 핸드', score:4.5, bonus:1.20},
    [BaccaratBonusType.PLAYER_PLUS_5]:{name:'플레이어 3.5 플러스 핸드', score:3.5, bonus:1.40},
    [BaccaratBonusType.PLAYER_PLUS_6]:{name:'플레이어 2.5 플러스 핸드', score:2.5, bonus:1.60},
    [BaccaratBonusType.PLAYER_PLUS_7]:{name:'플레이어 1.5 플러스 핸드', score:1.5, bonus:1.80},
    [BaccaratBonusType.PLAYER_MINUS_1]:{name:'플레이어 -1.5 마이너스 핸드', score:-1.5, bonus:2.30},
    [BaccaratBonusType.PLAYER_MINUS_2]:{name:'플레이어 -2.5 마이너스 핸드', score:-2.5, bonus:2.80},
    [BaccaratBonusType.PLAYER_MINUS_3]:{name:'플레이어 -3.5 마이너스 핸드', score:-3.5, bonus:3.70},
    [BaccaratBonusType.PLAYER_MINUS_4]:{name:'플레이어 -4.5 마이너스 핸드', score:-4.5, bonus:4.80},
    [BaccaratBonusType.PLAYER_MINUS_5]:{name:'플레이어 -5.5 마이너스 핸드', score:-5.5, bonus:6.30},
    [BaccaratBonusType.PLAYER_MINUS_6]:{name:'플레이어 -6.5 마이너스 핸드', score:-6.5, bonus:8.30},
    [BaccaratBonusType.PLAYER_MINUS_7]:{name:'플레이어 -7.5 마이너스 핸드', score:-7.5, bonus:11.30},

    [BaccaratBonusType.BANKER_PLUS_1]:{name:'뱅커 7.5 플러스 핸드', score:7.5, bonus:1.10},
    [BaccaratBonusType.BANKER_PLUS_2]:{name:'뱅커 6.5 플러스 핸드', score:6.5, bonus:1.13},
    [BaccaratBonusType.BANKER_PLUS_3]:{name:'뱅커 5.5 플러스 핸드', score:5.5, bonus:1.15},
    [BaccaratBonusType.BANKER_PLUS_4]:{name:'뱅커 4.5 플러스 핸드', score:4.5, bonus:1.20},
    [BaccaratBonusType.BANKER_PLUS_5]:{name:'뱅커 3.5 플러스 핸드', score:3.5, bonus:1.40},
    [BaccaratBonusType.BANKER_PLUS_6]:{name:'뱅커 2.5 플러스 핸드', score:2.5, bonus:1.60},
    [BaccaratBonusType.BANKER_PLUS_7]:{name:'뱅커 1.5 플러스 핸드', score:1.5, bonus:1.80},
    [BaccaratBonusType.BANKER_MINUS_1]:{name:'뱅커 -1.5 마이너스 핸드', score:-1.5, bonus:2.30},
    [BaccaratBonusType.BANKER_MINUS_2]:{name:'뱅커 -2.5 마이너스 핸드', score:-2.5, bonus:2.80},
    [BaccaratBonusType.BANKER_MINUS_3]:{name:'뱅커 -3.5 마이너스 핸드', score:-3.5, bonus:3.70},
    [BaccaratBonusType.BANKER_MINUS_4]:{name:'뱅커 -4.5 마이너스 핸드', score:-4.5, bonus:4.80},
    [BaccaratBonusType.BANKER_MINUS_5]:{name:'뱅커 -5.5 마이너스 핸드', score:-5.5, bonus:6.30},
    [BaccaratBonusType.BANKER_MINUS_6]:{name:'뱅커 -6.5 마이너스 핸드', score:-6.5, bonus:8.30},
    [BaccaratBonusType.BANKER_MINUS_7]:{name:'뱅커 -7.5 마이너스 핸드', score:-7.5, bonus:11.30},
}

export interface BaccaratBetInfo{
    [BaccaratBetType.PLAYER]:number;
    [BaccaratBetType.BANKER]:number;
    [BaccaratBetType.TIE]:number;
    [BaccaratBetType.PLAYER_BONUS]:{amount:number, opt:BaccaratBonusType};
    [BaccaratBetType.BANKER_BONUS]:{amount:number, opt:BaccaratBonusType};
    [BaccaratBetType.PERFECT_PAIR]:number;
    [BaccaratBetType.EITHER_PAIR]:number;
    [BaccaratBetType.PLAYER_PAIR]:number;
    [BaccaratBetType.BANKER_PAIR]:number;
}

export enum BaccaratActionType{
    BET, 
    MOVE,
    DOUBLE
}

export interface BaccaratBetAction{
    type:BaccaratActionType,
    payload:{
        amount:number,
        fromType:BaccaratBetType,
        toType:BaccaratBetType,
    }
}

export interface BaccaratBetActionHistory{
    history:BaccaratBetAction[],
    curInfo:BaccaratBetInfo,
}

export enum ActionCodeStatus{
    SUCCESS,
    NO_MORE_SIDEBET,
    ONLY_ONE_SIDEBET,
    NO_BET_TIME,
    INVALID_BONUS_OPTION,
    INVALID_BALANCE,
    INEFFICIENT_BALANCE,
    INVALID_MOVE
}

export type BaccaratResultType = 'player'|'banker'|'tie';
export type BaccaratResultInfo = {
    result:BaccaratResultType;
    playerScore:number;
    bankerScore:number;
}

export interface BaccaratHistoryLog {
    historyId:number;
    tableId:number;
    games:number;
    seed:string;
    result:BaccaratResultType;
    txHash:string;
    data:any;
    addedAt:string;
}