import styled from "styled-components";
import React from "react";
import useScreenOrientation, {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import {useRecoilValue} from "recoil";
import {gameOptionState} from "../../recoil/GameOption";
import {convertChipToTargetCurrencyString} from "../../conversionChip";
import {ExchangeRateItem} from "../../recoil/ExchangeRateList";
import {Textfit} from "react-textfit";

const Wrapper = styled.div<{
  folded: boolean
}>`
  position: absolute;
  left: -100%;
  top: -100%;
  width: 58px;
  height: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  color: #FFF;

  @media ${MEDIA_DESKTOP} {
    width: 64px;
    height: 78px;
  }

  ${p => p.folded && `
    opacity: 0.6;
  `};

  > div.top {
    width: 100%;
    position: relative;

    > .changeStackSize {
      width: 100%;
      text-align: center;
      max-width: 100%;
      position: absolute;
      top: -14px;
      left: 50%;
      transform: translateX(-50%);
      font-family: Pretendard;
      font-size: 9px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.60);
      padding: 2px;
    }

    > .changeStackSize.red {
      color: #FF2525;
    }

    > .changeStackSize.green {
      color: #37FF25;
    }

    > .profileImg {
      width: 34px;
      height: 34px;
      background-size: cover;
      background-position: center center;
      border-radius: 50%;
      margin: 0 auto;

      @media ${MEDIA_DESKTOP} {
        width: 48px;
        height: 48px;
      }

      &[data-winner=true] {
        border: 2px solid #FFEDAC;
        filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.60));
      }
    }

    .winner-badge {
      position: absolute;
      top: 0;
      right: 0;

      @media ${MEDIA_DESKTOP} {
        top: -4px;
      }
    }
  }

  > div.bottom {
    width: 100%;
    position: relative;
    margin-top: -6px;

    > .profileName {
      border-radius: 5px;
      border-top: 0.4px solid rgba(255, 255, 255, 0.00);
      background: radial-gradient(95.31% 95.31% at 50% 100%, rgba(182, 182, 182, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), #181A1D;
      padding: 2px 4px;
      position: relative;
      z-index: 1;
      text-align: center;

      > .nickname {
        font-size: 10px;
        font-weight: 400;
        transform: scale(0.8);
        overflow: hidden;
        text-overflow: ellipsis;
      }

      > .agency {
        font-size: 10px;
        font-weight: 500;
        transform: scale(0.8);
        line-height: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      > .prize {
        color: ${p => p.folded ? '#FFFFFF' : '#2F99FB'};
        text-align: center;
        font-size: 10px;
        font-weight: 600;

        @media ${MEDIA_DESKTOP} {
          font-size: 12px;
        }
      }
    }
  }
`;

const CardDeck = styled.div`
  position: absolute;
  bottom: -8px;
  right: 2px;

  > img {
    width: 50%;
    height: auto;
    box-shadow: -1px 1px 3px 0px rgba(0, 0, 0, 0.40);

    &:first-child {
      transform: translate(3px, 0) rotate(-5deg);
    }

    &:last-child {
      transform: translate(-3px, 0) rotate(5deg);
    }
  }
`;


function HistoryPlayer(
  {
    userId,
    seat,
    nickname,
    agencyName,
    profileImg,
    folded,
    winner,
    prize,
    cards,
    changeStackSize,
    targetExchangeRate
  }: {
    userId: number;
    seat: number;
    nickname: string;
    agencyName: string;
    profileImg: string;
    folded: boolean;
    winner: boolean;
    prize: number;
    cards: number[];
    changeStackSize: number;
    targetExchangeRate: ExchangeRateItem
  }
): JSX.Element {
  const gameOption = useRecoilValue(gameOptionState);
  const orientation = useScreenOrientation();
  return <Wrapper className="history-player" data-seat={seat} data-user={userId} folded={folded}>
    <div className="top">
      {
        changeStackSize !== 0 && <div className={'changeStackSize ' + (changeStackSize > 0 ? 'green' : 'red')}>
          <Textfit mode="single" max={9}>
                <span>
                  {changeStackSize > 0 ? '+' : '-'}{convertChipToTargetCurrencyString(Math.abs(changeStackSize), targetExchangeRate)}
                </span>
          </Textfit>
        </div>
      }

      <div className="profileImg"
           data-winner={winner}
           style={{backgroundImage: `url(/images/profile/${profileImg}.png)`}}
      />
      {
        cards.length === 2 && (
          <CardDeck>
            <img src={`/images/card/style-${gameOption.cardStyle}/${cards[0]}.svg`}/>
            <img src={`/images/card/style-${gameOption.cardStyle}/${cards[1]}.svg`}/>
          </CardDeck>
        )
      }
      {
        winner && (
          <img className="winner-badge" src={"/images/winner_badge.svg"}/>
        )
      }
    </div>
    <div className="bottom">
      <div className="profileName">
        <div className="nickname">{nickname}</div>
        {
          agencyName && (
            <div className="agency">#{agencyName}</div>
          )
        }
        {
          folded ? (
            <div className="prize">FOLD</div>
          ) : (
            <div className="prize">
              <Textfit mode="single" max={orientation === 'landscape' ? 12 : 10}>
                <span>{convertChipToTargetCurrencyString(prize, targetExchangeRate)}</span>
              </Textfit>
            </div>
          )
        }
      </div>
    </div>
  </Wrapper>;
}

export default HistoryPlayer;
