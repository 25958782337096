import styled from "styled-components";
import {useEffect, useRef} from "react";
import {BaccaratBonusType} from "../../dataset";

const BetOptionWrapper = styled.div<{ type: 'player' | 'banker', show: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  z-index: 11;
  ${p => p.type === 'player' ? `
  left: 0;
  `: ``}
  ${p => p.type === 'banker' ? `
  right: 0;
  `: ``}
  ${p => p.show ? `` : `
    ${p.type === 'player' ? `` : `right: -100vw;`}
    ${p.type === 'banker' ? `` : `left: -100vw;`}
  `}
  transition: all 0.5s;

  > .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 30px;
    ${p => p.type === 'player' ? `
    border-radius: 0px 8px 8px 0px;
    border-top: 0.5px solid #FFF;
    border-right: 0.5px solid #FFF;
    border-bottom: 0.5px solid #FFF;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.20) 100%), #317ACF;
    box-shadow: 2px 2px 10px 0px rgba(16, 16, 18, 0.50);
    `: ``}
    ${p => p.type === 'banker' ? `
    border-radius: 8px 0px 0px 8px;
    border-top: 0.5px solid #FFF;
    border-bottom: 0.5px solid #FFF;
    border-left: 0.5px solid #FFF;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.20) 100%), #D93C33;
    box-shadow: 2px 2px 10px 0px rgba(16, 16, 18, 0.50);
    `:``}

    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Pretendard;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`
export interface BetOptionInterface {
  id: number;
  direction: 'player' | 'banker';
  type: BaccaratBonusType,
  title: string;
  value: number;
}
export const BetOptionArr: BetOptionInterface[] = [
  {
    id: 1,
    direction: 'player',
    title: '플레이어 1.5 마핸',
    type: BaccaratBonusType.PLAYER_MINUS_1,
    value: 2.30
  },
  {
    id: 2,
    direction: 'player',
    title: '플레이어 1.5 플핸',
    type: BaccaratBonusType.PLAYER_PLUS_1,
    value: 1.80
  },
  {
    id: 3,
    direction: 'banker',
    title: '뱅커 1.5 마핸',
    type: BaccaratBonusType.BANKER_MINUS_1,
    value: 2.25
  },
  {
    id: 4,
    direction: 'banker',
    title: '뱅커 1.5 플핸',
    type: BaccaratBonusType.BANKER_PLUS_1,
    value: 1.75
  },
  {
    id: 5,
    direction: 'player',
    title: '플레이어 2.5 마핸',
    type: BaccaratBonusType.PLAYER_MINUS_2,
    value: 2.80
  },
  {
    id: 6,
    direction: 'player',
    title: '플레이어 2.5 플핸',
    type: BaccaratBonusType.PLAYER_PLUS_2,
    value: 1.60
  },
  {
    id: 7,
    direction: 'banker',
    title: '뱅커 2.5 마핸',
    type: BaccaratBonusType.BANKER_MINUS_2,
    value: 2.75
  },
  {
    id: 8,
    direction: 'banker',
    title: '뱅커 2.5 플핸',
    type: BaccaratBonusType.BANKER_PLUS_2,
    value: 1.55
  },
  {
    id: 9,
    direction: 'player',
    title: '플레이어 3.5 마핸',
    type: BaccaratBonusType.PLAYER_MINUS_3,
    value: 3.70
  },
  {
    id: 10,
    direction: 'player',
    title: '플레이어 3.5 플핸',
    type: BaccaratBonusType.PLAYER_PLUS_3,
    value: 1.40
  },
  {
    id: 11,
    direction: 'banker',
    title: '뱅커 3.5 마핸',
    type: BaccaratBonusType.BANKER_MINUS_3,
    value: 3.65
  },
  {
    id: 12,
    direction: 'banker',
    title: '뱅커 3.5 플핸',
    type: BaccaratBonusType.BANKER_PLUS_3,
    value: 1.35
  },
  {
    id: 13,
    direction: 'player',
    title: '플레이어 4.5 마핸',
    type: BaccaratBonusType.PLAYER_MINUS_4,
    value: 4.80
  },
  {
    id: 14,
    direction: 'player',
    title: '플레이어 4.5 플핸',
    type: BaccaratBonusType.PLAYER_PLUS_4,
    value: 1.20
  },
  {
    id: 15,
    direction: 'banker',
    title: '뱅커 4.5 마핸',
    type: BaccaratBonusType.BANKER_MINUS_4,
    value: 4.75
  },
  {
    id: 16,
    direction: 'banker',
    title: '뱅커 4.5 플핸',
    type: BaccaratBonusType.BANKER_PLUS_4,
    value: 1.20
  },
  {
    id: 17,
    direction: 'player',
    title: '플레이어 5.5 마핸',
    type: BaccaratBonusType.PLAYER_MINUS_5,
    value: 6,
  },
  {
    id: 18,
    direction: 'player',
    title: '플레이어 5.5 플핸',
    type: BaccaratBonusType.PLAYER_PLUS_5,
    value: 1.15
  },
  {
    id: 19,
    direction: 'banker',
    title: '뱅커 5.5 마핸',
    type: BaccaratBonusType.BANKER_MINUS_5,
    value: 6.25
  },
  {
    id: 20,
    direction: 'banker',
    title: '뱅커 5.5 플핸',
    type: BaccaratBonusType.BANKER_PLUS_5,
    value: 1.15
  },
  {
    id: 21,
    direction: 'player',
    title: '플레이어 6.5 마핸',
    type: BaccaratBonusType.PLAYER_MINUS_6,
    value: 8.30
  },
  {
    id: 22,
    direction: 'player',
    title: '플레이어 6.5 플핸',
    type: BaccaratBonusType.PLAYER_PLUS_6,
    value: 1.13
  },
  {
    id: 23,
    direction: 'banker',
    title: '뱅커 6.5 마핸',
    type: BaccaratBonusType.BANKER_MINUS_6,
    value: 8.25
  },
  {
    id: 24,
    direction: 'banker',
    title: '뱅커 6.5 플핸',
    type: BaccaratBonusType.BANKER_PLUS_6,
    value: 1.13
  },
  {
    id: 25,
    direction: 'player',
    title: '플레이어 7.5 마핸',
    type: BaccaratBonusType.PLAYER_MINUS_7,
    value: 11.30
  },
  {
    id: 26,
    direction: 'player',
    title: '플레이어 7.5 플핸',
    type: BaccaratBonusType.PLAYER_PLUS_7,
    value: 1.10
  },
  {
    id: 27,
    direction: 'banker',
    title: '뱅커 7.5 마핸',
    type: BaccaratBonusType.BANKER_MINUS_7,
    value: 11.25
  },
  {
    id: 28,
    direction: 'banker',
    title: '뱅커 7.5 플핸',
    type: BaccaratBonusType.BANKER_PLUS_7,
    value: 1.10
  }
]

interface BetOptionProps {
  show: boolean;
  type: 'player' | 'banker';
  onSelected: (value: BetOptionInterface) => void;
}

const BetOption = ({show, type, onSelected}: BetOptionProps) => {
  const currentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const target = document.getElementById(type+"-bet-option") as HTMLDivElement;
    if (target && currentRef.current) {
      currentRef.current.style.bottom = `calc(100svh - ${target.offsetTop}px + 8px)`;
    }
  }, [type]);
  return <BetOptionWrapper show={show} type={type} ref={currentRef}>
    {
      BetOptionArr.filter(option => option.title.includes(type === 'player' ? '플레이어' : '뱅커')).map(option => {
        return <div className='item' onClick={() => onSelected(option)}>
          {option.title}<br/>
          {option.value}
        </div>
      })
    }
  </BetOptionWrapper>
}

export default BetOption;
