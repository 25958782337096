function DigitsColorWrapper({
  number,
  color,
  fontSize,
  digitScale,
  digitOpacity,
}: {
  number: number,
  color?:string,
  fontSize?:number,
  digitScale?:number,
  digitOpacity?:number,
}) {
  if(isNaN(number)) return <>{number}</>;

    const str = number.toLocaleString();
    const split = str.split(".");
    if(split.length == 1) return <>{str}</>;

    return <span style={{display:"inline-flex", "alignItems":"flex-end"}}>
        {split[0]} <span style={{
          color:color||"orange",
          fontSize:
            (fontSize ? `${fontSize}px`: (digitScale ? `${digitScale}em` : "")),
          // transform:`scale(${digitScale||1})`,
          opacity:digitOpacity||1,
          // transformOrigin: "bottom left"
        }}>.{split[1]}</span>
    </span>
}

export default DigitsColorWrapper;