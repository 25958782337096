import styled from "styled-components";
import {useEffect} from "react";

const VerificationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 42px;

  > .wrapper {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 40px);
    gap: 10px;

    > .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 10px;

      > .title {
        width: 60px;
        color: #FFF;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        flex-shrink: 0;
      }

      > input {
        border: none;
        outline: none;
        flex-grow: 1;
        padding: 4px 8px;
        border-radius: 30px;
        background: #1F1F1F;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
        color: #FFF;

        &::placeholder {
          text-align: center;
          color: #777;
        }
      }
    }
  }

  > .button {
    margin-top: 20px;
    cursor: not-allowed;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: calc(100% - 40px);
    height: 42px;
    border-radius: 12px;
    opacity: 0.5;
    background: rgba(255, 255, 255, 0.10);
    color: #FFF;
    text-align: right;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: all 0.3s;
  }

  > .button.active {
    cursor: pointer;
    opacity: 1;
    background: #2F99FB;
  }
`
const Verification = () => {
  useEffect(() => {
    //각 input이 모두 채워졌을 때 버튼 활성화
    const inputRoomNumber = document.getElementById('input-room-number') as HTMLInputElement;
    const inputHash1 = document.getElementById('input-hash-1') as HTMLInputElement;
    const inputHash2 = document.getElementById('input-hash-2') as HTMLInputElement;
    const inputHash3 = document.getElementById('input-hash-3') as HTMLInputElement;
    const inputHash4 = document.getElementById('input-hash-4') as HTMLInputElement;
    const button = document.getElementById('verification-button') as HTMLDivElement;
    const checkButton = () => {
      const isCheck = Boolean(inputRoomNumber.value && inputHash1.value && inputHash2.value && inputHash3.value && inputHash4.value)
      if (isCheck) {
        button.classList.add('active');
      } else {
        button.classList.remove('active');
      }
    }
    inputRoomNumber.addEventListener('input', checkButton);
    inputHash1.addEventListener('input', checkButton);
    inputHash2.addEventListener('input', checkButton);
    inputHash3.addEventListener('input', checkButton);
    inputHash4.addEventListener('input', checkButton);
  }, []);
  return <VerificationWrapper>
    <div className='wrapper'>
      <div className='row'>
        <div className='title'>방 번호</div>
        <input id='input-room-number' type='text' placeholder='방 번호를 입력해주세요'/>
      </div>
      <div className='row'>
        <div className='title'>블록해시1</div>
        <input id='input-hash-1' type='text' placeholder='1번째 블록해시 값을 입력해주세요'/>
      </div>
      <div className='row'>
        <div className='title'>블록해시2</div>
        <input id='input-hash-2' type='text' placeholder='2번째 블록해시 값을 입력해주세요'/>
      </div>
      <div className='row'>
        <div className='title'>블록해시3</div>
        <input id='input-hash-3' type='text' placeholder='3번째 블록해시 값을 입력해주세요'/>
      </div>
      <div className='row'>
        <div className='title'>블록해시4</div>
        <input id='input-hash-4' type='text' placeholder='4번째 블록해시 값을 입력해주세요'/>
      </div>
    </div>
    <div id='verification-button'className='button'>
      <img src='/images/baccarat/CTA/Icon/Mini/Search.svg'/>
      검증
    </div>
  </VerificationWrapper>
}
export default Verification;
