import {useCallback, useLayoutEffect, useState} from "react";

export const MIN_MOBILE_HEIGHT = 600;
export const MIN_WIDTH_DESKTOP = 1080;
export const MIN_HEIGHT_DESKTOP = 880;
export const MEDIA_DESKTOP = `(min-width: ${MIN_WIDTH_DESKTOP}px) and (min-height: ${MIN_HEIGHT_DESKTOP}px)`;
export const MEDIA_MOBILE_MINI = `(max-height: ${MIN_MOBILE_HEIGHT}px)`;
export type ScreenOrientation = 'portrait' | 'portrait_mini' | 'landscape';
function useScreenOrientation() {
  const [orientation, setOrientation] = useState<ScreenOrientation>('portrait');

  const handleResize = useCallback(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    let orientation: ScreenOrientation = 'portrait';
    if (width >= MIN_WIDTH_DESKTOP && height >= MIN_HEIGHT_DESKTOP) {
      orientation = 'landscape';
    }else if(height < MIN_MOBILE_HEIGHT) {
      orientation = 'portrait_mini';
    }
    setOrientation(orientation);
  }, []);

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [handleResize]);

  return orientation;
}

export default useScreenOrientation;
