import styled from "styled-components";
import Flex1 from "./common/Flex";
import { useCallback, useEffect, useMemo, useState } from "react";
import { GroupInfo, MyInfo } from "../dataset";
import { requestMyGroupVaultList, requestMyInfo } from "../api";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { globalLoadingState } from "../recoil/Loading";
import { nFormatter } from "../pages/Lobby";
import { UserCurrencyMark, UserToCurrency } from "../conversionChip";
import DigitsColorWrapper from "./DigitsColor";

const Wrapper = styled.div`
  width: 100%;
  min-width: 350px;
  border-radius: 12px;
  background: #17171B;
  padding: 16px;
`;

const Title = styled.div`
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 0;
`;


const List = styled.ul`
    margin-top: 10px;
    padding: 0;
    list-style: none;
    max-height: 400px;
    overflow-y: scroll;

    >li{
        background-color: rgba(31, 31, 31, 1);
        border-radius: 8px;
        display: flex;
        margin-bottom: 10px;
        padding: 20px 15px;

        >.amount{
            display: flex;
            justify-content: space-between;
            flex:1;
            flex-direction: column;


            .blinds{
                font-size: 18px;
                font-weight: 500;
                color: rgba(255, 255, 255, 1);
                margin-top: 5px;
            }


            .title{
                font-size: 13px;
                font-weight: 500;
                color: rgba(255, 255, 255, 0.8);
            }

            .number{
                font-size: 16px;
                font-weight: 500;
                color: #999;
                display: flex;
                align-items: center;
                gap:5px;
                span{
                    display: flex;
                    align-items: center;
                    color: white;
                    img{
                        width: 20px;
                    }
                }
            }

            >div{
                font-size: 18px;
                font-weight: 500;
                color: #fff;
            }
        }

        >.right{
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
        }
        
    }
`;

const Hands = styled.div<{ active: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    ${p => p.active ? `
        color: rgba(55, 255, 37, 1);
        background-color: rgba(55, 255, 37, 0.1);
    `: `
        color: rgba(255, 37, 37, 1);
        background-color: rgba(255, 37, 37, 0.1);
    `}
    
    padding: 7px 10px;
    border-radius: 6px;
    margin-bottom: 15px;
`;

const BuyInButton = styled.div`
    border-radius: 8px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(116deg, rgba(253, 203, 75, 1) 23.2%, rgba(230, 143, 12, 1) 98.42%);
    border: 1px solid rgba(255, 190, 22, 1);
    color: #FFF;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    padding: 8px 16px;
    cursor: pointer;
    box-shadow:0 0 5px rgba(235, 156, 26, 0.5);

    &:hover{
        opacity: 0.8;
    }

    &:active{
        opacity: 0.6;
    }
`;

export interface VaultInfo {
    groupId: number;
    roomId: number;
    amount: number;
    buyin: number;
    hands: number;
    vaultId: number;
}

interface BuyInModalProps {
    groups: GroupInfo[];
    onAdjusted: (vault: VaultInfo) => void;
}

function AdjustVaultModal({
    groups,
    onAdjusted
}: BuyInModalProps) {
    const { t } = useTranslation();
    const [vaults, setVaults] = useState<VaultInfo[]>([]);

    useEffect(() => {
        requestMyGroupVaultList().then((v) => {
            setVaults(v.list || []);
        });
    }, []);

    return <Wrapper>
        <Title>{t('정산하기')}</Title>
        <List>
            {vaults.map((item: VaultInfo) => {
                let group = groups.find((group: GroupInfo) => group.groupId === item.groupId);
                if (!group) return null;

                return <li key={item.vaultId}>
                    <div className="amount">
                        <div className="blinds">{nFormatter(Number(group.data.blind[0]), 1)} - {nFormatter(Number(group.data.blind[1]), 1)}</div>
                        <div style={{ flex: "1" }}></div>
                        <div>
                            <div className="title">{t("정산금액")}</div>
                            <div className="number">
                                <span>{UserCurrencyMark()} <DigitsColorWrapper number={UserToCurrency(item.amount)} fontSize={13} /></span> |
                                <span><img src="/images/ic_buyin_chips.svg" style={{ marginRight: "5px" }} /> {Number(item.amount).toLocaleString()}</span>
                            </div>
                        </div>
                    </div>

                    <div className="right">
                        <Hands active={item.hands >= 100}>
                            {t("핸드 수")} {item.hands}
                        </Hands>
                        <BuyInButton onClick={() => onAdjusted(item)}>
                            {t("정산")}
                        </BuyInButton>
                    </div>
                </li>
            })}
        </List>
    </Wrapper>
}

export default AdjustVaultModal