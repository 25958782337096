import styled from "styled-components";
import {useEffect, useState} from "react";

const HowToWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
  gap: 4px;

  > .content-wrapper {
    width: 100%;
    overflow-y: auto;
    max-height: calc(100vh - 265px);

    > .content {
      padding: 0 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;

      > .item {
        border-radius: 4px;
        background: #1F1F1F;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 6px 8px;
        gap: 5px;

        > .title-row {
          width: 100%;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          > .title {
            color: #FFF;
            font-family: Pretendard;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          > .fold {
            width: 16px;
            height: 16px;
            opacity: 0.6;
          }
          >.fold.open {
            transform: rotate(180deg);
          }
        }
        >.desc {
          color: #FFF;
          font-family: Pretendard;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 14px */
          letter-spacing: -0.2px;
          opacity: 0.5;
        }

      }
    }
  }
`

const HowtoItem = () => {
  const [isOpen, setIsOpen] = useState(false);
  const onClick = () => {
    setIsOpen(!isOpen);
  }
  return <div className='item'>
    <div className='title-row' onClick={onClick}>
      <span className='title'>How to play</span>
      <img src='/images/baccarat/Mini/Down.svg' alt='fold' className={'fold '+(isOpen ? 'open' : '')} />
    </div>
    {
      isOpen && <div className='desc'>
        <span>1. Select the betting amount and the betting option.</span>
        <span>2. Click the 'Confirm' button to place a bet.</span>
        <span>3. The game will start automatically.</span>
      </div>
    }
  </div>
}
const Howto = () => {
  return <HowToWrapper>
    <div className='content-wrapper'>
      <div className='content'>
        {
          Array(10).fill(0).map((_, i) => {
            return <HowtoItem key={i} />
          })
        }
      </div>
    </div>
  </HowToWrapper>
}

export default Howto;
