import React from 'react';
import styled from "styled-components";

const ProgressCircleWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FFF;
    font-family: Pretendard;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
  }
`

interface ProgressCircleProps {
  percentage: number;
  type: 'player' | 'banker' | 'tie';
}

const ProgressCircle = ({percentage, type}: ProgressCircleProps) => {
  const size = 30;
  const strokeWidth = 2;
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;
  let color = '#000';
  if (type === 'player') {
    color = '#2F99FB';
  } else if (type === 'banker') {
    color = '#FB3737';
  } else if (type === 'tie') {
    color = '#4FCF5C';
  }

  return (
    <ProgressCircleWrapper>
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          stroke="#0C0C0C"
          strokeWidth={strokeWidth}
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
          strokeLinecap="round"
        />
      </svg>
      <span>
        {percentage}%
      </span>
    </ProgressCircleWrapper>

  );
};

export default ProgressCircle;
