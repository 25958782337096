import styled from "styled-components";
import {DialogProps} from "../recoil/Dialog";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  min-width: 311px;
  min-height: 236px;
  width: 100%;
  border-radius: 12px;
  background: #17171B;
  padding: 16px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Title = styled.div`
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding: 8px;
  word-break: break-word;
`;

const Content = styled.div`
  flex: 1;
  color: #8B8B8D;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  word-break: break-word;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 11px;
`;

const Button = styled.div<{
  cancel?: boolean,
  disable?: boolean
}>`
  flex: 1;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  padding: 16px;
  transition: all 0.1s linear;
  cursor: pointer;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(116deg, #2F99FB 23.2%, #2464C3 98.42%);

  ${p => p.cancel && `
    background: #2D2D2D;
  `};

  &:hover {
    filter: brightness(1.2);
  }

  &:active {
    filter: brightness(0.7);
  }

  ${p => p.disable && `
    filter: grayscale(1);
    cursor: not-allowed;
    pointer-events: none;
  `};
`;

const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: #2D2D2D;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img{
    width: 20px;
    height: 20px;
  }

  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
`

function Dialog({
  dialog,
  onBackdropClick,
  disableCloseButton
}: {
  dialog: DialogProps,
  onBackdropClick?: () => void;
  disableCloseButton?: boolean;
}) {
  const {t} = useTranslation();
  const handleConfirm = useCallback(() => {
    dialog?.onConfirm && dialog.onConfirm();
  }, [dialog?.onConfirm]);

  return <Wrapper>
    <Title>{dialog.title}</Title>
    {onBackdropClick && !disableCloseButton ? <Close onClick={onBackdropClick}><img src="/images/ic_close.png" alt=""/></Close> : null} 
    {
      dialog.component ? (
        <Content>{dialog.component}</Content>
      ) : (
        <Content dangerouslySetInnerHTML={{__html: dialog.text || ''}}></Content>
      )
    }
    <ButtonWrapper>
      {
        dialog.confirm && (
          <Button cancel onClick={dialog.onCancel}>{dialog.cancelText || t('취소')}</Button>
        )
      }
      <Button disable={dialog.confirmDisable == true} onClick={handleConfirm}>{dialog.confirmText || t('확인')}</Button>
    </ButtonWrapper>
  </Wrapper>;
}

export default Dialog;