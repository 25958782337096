import styled from "styled-components";

const BottomWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 4px 0;
  flex-shrink: 0;
  z-index: 2;

  > .money-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    > .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 4px;

      > .title {
        color: #FFF;
        text-align: center;
        font-family: Pretendard;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.2px;
      }

      > .value-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 2px;
        border-radius: 8px;
        border: 1px solid rgba(47, 153, 251, 0.20);
        background: linear-gradient(180deg, #1F1F1F 0%, #03284A 100%);
        padding: 2px 4px;

        > .icon {
          width: 12px;
          height: 12px;
        }

        > .value {
          color: var(--Primary-blue, #2F99FB);
          text-align: center;
          font-family: Pretendard;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.2px;
        }
      }
    }

  }

  > .menu-button {
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #333;
    background: #181A1D;

    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`
export default BottomWrapper;
