import {atom} from "recoil";

export enum AlwaysBBType{
  chip,
  bb,
  fiat
}
export interface GameOptionProps {
  cardStyle: 1 | 2 | 3
  alwaysBB: AlwaysBBType
  bgmMuted: boolean
  bgmVolume: number
  sfxMuted: boolean
  sfxVolume: number
}

const localStorageEffect =
  (key: string) =>
    ({ setSelf, onSet }: any) => {
      const savedValue = localStorage.getItem(key);
      // setSelf -> Callbacks to set or reset the value of the atom.
      if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
      }

      // onSet -> Subscribe to changes in the atom value.
      onSet((newValue: any, _: any, isReset: boolean) => {
        isReset
          ? localStorage.removeItem(key)
          : localStorage.setItem(key, JSON.stringify(newValue));
      });
    };


export const gameOptionState = atom<GameOptionProps>({
  key: 'gameOptionState',
  default: {
    cardStyle: 1,
    alwaysBB: AlwaysBBType.fiat,
    bgmMuted: false,
    bgmVolume: 100,
    sfxMuted: false,
    sfxVolume: 100,
  },
  effects: [localStorageEffect('gameOptionState')],
});
