import styled from "styled-components";
import {useEffect} from "react";
import ToggleSwitch from "../../ToggleSwitch";

const SettingWrapper = styled.div`
  width: 100%;
  overflow-y: auto;
  height: 100%;
  >.inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    padding: 16px 20px 42px;
  }
`
const SectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  > .title {
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
  }
`
const RowWrapper = styled.div<{ paddingMode?: 'small' | 'default' }>`
  width: 100%;
  border-radius: 4px;
  background: #1F1F1F;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${p => p.paddingMode === 'small' && `
    padding: 4px 8px;
  `};
  ${p => (p.paddingMode === 'default' || p.paddingMode === undefined) && `
    padding: 4px 8px;
  `};

  > .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > input {
      width: 128px;
      padding: 4px 26px;
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.10);
      outline: none;
      border: none;
      text-align: center;
      color: #FFF;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &::placeholder {
        opacity: 0.9;
      }
    }

    > .title-col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 8px;

      > .title {
        color: #FFF;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      > .desc {
        color: #FFF;
        font-family: Pretendard;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity: 0.6;
      }
    }
  }
`
const Setting = () => {
  return <SettingWrapper>
    <div className='inner'>
      <SectionWrapper>
        <div className="title">일반</div>
        <RowWrapper>
          <div className="row">
            <div className="title-col">
              <div className="title">다른 플레이어의 채팅 숨기기</div>
            </div>
            <ToggleSwitch mode={'dark'} checked={false} onChange={() => {
            }}/>
          </div>
        </RowWrapper>
        <RowWrapper>
          <div className="row">
            <div className="title-col">
              <div className="title">모든 사이드 베팅 표시</div>
            </div>
            <ToggleSwitch mode={'dark'} checked={false} onChange={() => {
            }}/>
          </div>
        </RowWrapper>
        <RowWrapper>
          <div className="row">
            <div className="title-col">
              <div className="title">배팅 통계</div>
              <div className="desc">각 베팅 스폿에 대해 플레이어의 베팅 활동 표시</div>
            </div>
            <ToggleSwitch mode={'dark'} checked={false} onChange={() => {
            }}/>
          </div>
        </RowWrapper>
        <RowWrapper paddingMode={'small'}>
          <div className="row">
            <div className="title-col">
              <div className="title">대화명</div>
            </div>
            <input type='text' placeholder='대회명 입력'/>

          </div>
        </RowWrapper>
      </SectionWrapper>
      <SectionWrapper>
        <div className="title">동영상</div>
        <RowWrapper>
          <div className="row">
            <div className="title-col">
              <div className="title">동영상 품질 자동 조정</div>
            </div>
            <ToggleSwitch mode={'dark'} checked={false} onChange={() => {
            }}/>
          </div>
        </RowWrapper>
        <RowWrapper>
          <div className="row">
            <div className="title-col">
              <div className="title">동영상 품질 수동 조정</div>
            </div>
            <ToggleSwitch mode={'dark'} checked={false} onChange={() => {
            }}/>
          </div>
        </RowWrapper>
        <RowWrapper>
          <div className="row">
            <div className="title-col">
              <div className="title">동영상 콘텐츠</div>
            </div>
            <ToggleSwitch mode={'dark'} checked={false} onChange={() => {
            }}/>
          </div>
        </RowWrapper>
      </SectionWrapper>
      <SectionWrapper>
        <div className="title">음향 조절</div>
        <RowWrapper>
          <div className="row">
            <div className="title-col">
              <div className="title">모든 사운드</div>
            </div>
            <ToggleSwitch mode={'dark'} checked={false} onChange={() => {
            }}/>
          </div>
        </RowWrapper>
        <RowWrapper>
          <div className="row">
            <div className="title-col">
              <div className="title">스튜디오 사운드</div>
              <div className="desc">스튜디오에서 나오는 잔잔한 사운드</div>
            </div>
            <ToggleSwitch mode={'dark'} checked={false} onChange={() => {
            }}/>
          </div>
        </RowWrapper>
        <RowWrapper>
          <div className="row">
            <div className="title-col">
              <div className="title">게임 효과 사운드</div>
              <div className="desc">게임 버튼 및 이벤트용 사운드</div>
            </div>
            <ToggleSwitch mode={'dark'} checked={false} onChange={() => {
            }}/>
          </div>
        </RowWrapper>
      </SectionWrapper>
      <SectionWrapper>
        <div className="title">스코어 카드</div>
        <RowWrapper>
          <div className="row">
            <div className="title-col">
              <div className="title">배경</div>
            </div>
            <ToggleSwitch mode={'dark'} checked={false} onChange={() => {
            }}/>
          </div>
        </RowWrapper>
      </SectionWrapper>
    </div>
  </SettingWrapper>
}
export default Setting;
