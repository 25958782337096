import React, {MouseEventHandler, useCallback, useEffect, useMemo} from "react";
import styled from "styled-components";
import Flex from "../common/Flex";
import Prize from "./Prize";
import PlayerCount from "./PlayerCount";
import TournamentStatus from "./TournamentStatus";

import {GroupInfo} from "../../dataset";
import {parseLocalDateTime} from "../../constants/moment";
import useTournamentTimer from "../../hooks/useTournamentTimer";
import {Link} from "react-router-dom";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import {useTranslation} from "react-i18next";
import { currencyToChip, fiatToFiat, PrintPointOrFiat, UserCurrency, UserCurrencyMark, UserToCurrency } from "../../conversionChip";


const Wrapper = styled.li<{
  isRegistered: boolean
}>`
  display: flex;
  align-items: center;
  margin: 20px 16px;
  padding: 12px 8px;
  border-radius: 16px;
  position: relative;

  ${p => p.isRegistered ? `
    border: 1px solid #FF6E5A;
  ` : ``}
  &:hover {
    text-decoration: none;
    border-radius: 8px;
    background: #1F1F25;
  }

  &:active {
    border-radius: 8px;
    background: #1F1F25;
  }

  .info {
    flex: 1;
    margin-left: 8px;

    > .title {
      color: #FFF;
      font-size: 12px;
      font-weight: 500;
      line-height: 132%; /* 15.84px */
      padding-bottom: 4px;
      border-bottom: 1px #2d2d2d solid;
      margin-bottom: 12px;
    }

    > div:last-child {
      display: flex;
      align-items: center;
      gap: 8px;

      > .detail {
        font-size: 10px;

        > div {
          color: #FFF;

          &:not(:last-child) {
            margin-bottom: 4px;
          }

          span {
            opacity: 0.5;
            color: #FFF;
            margin-right: 2px;
          }
        }
      }
    }
  }
`;

const WideWrapper = styled.div<{
  isRegistered: boolean
}>`
  position: relative;
  margin-top: 10px;

  ${p => p.isRegistered && `
    border: 1px solid #FF6E5A;
    border-radius: 10px;
    background: rgba(255, 38, 38, 0.10);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.50);
  `};
  
  .title {
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 16.8px */
    letter-spacing: -0.2px;
  }
  .normal {
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.2px;
    opacity: 0.8;
  }
  
`;


const RegisterTag = styled.div`
  position: absolute;
  top: -10px;
  left: 0;
  padding: 4px 8px;
  color: #FFF;
  font-size: 10px;
  font-weight: 600;
  border-radius: 20px;
  background: linear-gradient(180deg, #FF6E5A 0%, #F6421A 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.20);

  @media ${MEDIA_DESKTOP} {
    left: 8px;
  }
`


function TournamentItem(
  {
    className,
    group,
    landscape,
    onClick
  }: {
    className?: string,
    group: GroupInfo,
    landscape?: boolean,
    onClick?: (groupId: number) => void,
  }
) {
  const {t} = useTranslation();
  const {
    timerText,
    isTournamentStarted
  } = useTournamentTimer({
    data: group.data
  });

  const handleClick = useCallback(() => {
    if (group?.groupId) {
      onClick && onClick(group?.groupId)
    }
  }, [group?.groupId]);

  const gtd = useMemo(function(){
    if(group.data.isGuarantee){
      //gtd 계산 코드
      const gtd = Math.max(group.totalBuyin / 100 * group.data.guaranteeBuyinSplitRatio - group.data.guaranteeMinAmount,0);
      return gtd;
    }
    return 0;
  },[group.totalBuyin, group.data.isGuarantee])

  let r = localStorage.getItem("toggleViewFiat");

  const buyinPriceUSD = useMemo(() => {
    let chip = currencyToChip("USD", group.data.buyinPrice);
    let fiat = UserToCurrency(chip);

    let desc = UserCurrencyMark() != "$" ? `($${fiatToFiat(UserCurrency(), "USD", fiat)})` : "";
    return desc;
  }, [group.data.buyinPrice, r]);

  if (!landscape) {
    return <Wrapper className={className} isRegistered={(group.isRegister && !group.isEnd)} onClick={handleClick}>
      {
        Boolean(group.isRegister && !group.isEnd) &&
        <RegisterTag>{t('참여')}</RegisterTag>
      }
      <TournamentStatus ended={group.isEnd} started={isTournamentStarted} timerText={timerText}/>
      <div className="info">
        <div className="title">
          {group.data.name}
        </div>
        <div>
          <div className="detail">
            <div>
              <span>{t('시작')}</span>{parseLocalDateTime(group.data.startedAt).format('MM/DD, HH:mm')}
            </div>
            <div>
              <span>{t('바이인')}</span>
              {group.data.buyinPrice == 0 ? "FREE" : <>
                {PrintPointOrFiat(currencyToChip("USD", group.data.buyinPrice), (n:number)=>n.toLocaleString()+"Hp")}{buyinPriceUSD}
              </>}
            </div>
          </div>
          <Flex/>

          <PlayerCount count={group.totalRegisterCount} max={group.data.rebuyinLimit == 1 ? undefined : group.buyinCount}/>
          <Prize prizes={group.data.prize} button center gtd={gtd}/>
        </div>
      </div>
    </Wrapper>;
  } else {
    return <WideWrapper isRegistered={group.isRegister && !group.isEnd} onClick={handleClick}>
      {
        Boolean(group.isRegister && !group.isEnd) &&
        <RegisterTag>{t('참여')}</RegisterTag>
      }
      <div className={className}>
        <div>
          <TournamentStatus ended={group.isEnd} started={isTournamentStarted} timerText={timerText} landscape/>
        </div>
        <div className='title'>{group.data.name}</div>
        <div className='normal'>
          {parseLocalDateTime(group.data.startedAt).format('MM/DD, HH:mm')}
        </div>
        <div className='normal'>
          {group.data.buyinPrice == 0 ? "FREE" : <>
            {PrintPointOrFiat(currencyToChip("USD", group.data.buyinPrice), (n: number) => n.toLocaleString())}<br/>
            {buyinPriceUSD}
          </>}
        </div>
        <div>
        <PlayerCount count={group.totalRegisterCount} max={group.data.rebuyinLimit == 1 ? undefined : group.buyinCount}/>
        </div>
        <div>
          <Prize prizes={group.data.prize} gtd={gtd} button center/>
        </div>
      </div>
    </WideWrapper>
  }

}

export default TournamentItem;
