import {chipToCurrency, UserCurrency, UserCurrencyMark} from "../conversionChip";
import {ROOM_TYPE} from "../dataset";
import {AlwaysBBType} from "../recoil/GameOption";
import {fixedNumber, toEasternNumeral} from "../utils/common";
import DigitsColorWrapper from "./DigitsColor";


export function PrintAlwaysBBStr(gameType:ROOM_TYPE, alwaysBB:AlwaysBBType, chip:number, bb:number, eastern:boolean = false, fiatFixed?:number) : string{
    if(gameType == ROOM_TYPE.TOURNAMENT && alwaysBB == AlwaysBBType.fiat){
        alwaysBB = AlwaysBBType.bb;
    }

    if(alwaysBB === AlwaysBBType.chip){
        if(eastern){
            const lang = localStorage.getItem('i18nextLng');
            return toEasternNumeral(chip, lang || "ko-KR")
        }else{
            return chip.toLocaleString()
        }
    }else if(alwaysBB === AlwaysBBType.fiat){
        const userCurrency = UserCurrency();
        return UserCurrencyMark()+chipToCurrency(userCurrency, chip, fiatFixed === undefined ? 1000 : fiatFixed, true).toLocaleString()
    }else{
        return `${fixedNumber(chip / (bb||1), 2).toLocaleString()} BB`
    }
}

export function PrintAlwaysBB(gameType:ROOM_TYPE, alwaysBB:AlwaysBBType, chip:number, bb:number, eastern:boolean = false, fiatFixed?:number, opt?:{
    digitColor:string
}) : JSX.Element{
    if(gameType == ROOM_TYPE.TOURNAMENT && alwaysBB == AlwaysBBType.fiat){
        alwaysBB = AlwaysBBType.bb;
    }

    if(alwaysBB === AlwaysBBType.chip){
        if(eastern){
            const lang = localStorage.getItem('i18nextLng');
            return <>
                {toEasternNumeral(chip, lang || "ko-KR")}
            </>
        }else{
            return <>
                {chip.toLocaleString()}
            </>
        }
    }else if(alwaysBB === AlwaysBBType.fiat){
        const userCurrency = UserCurrency();
        return <>
            {UserCurrencyMark()}
            <DigitsColorWrapper color={opt?opt.digitColor : ""}  digitScale={0.8} number={chipToCurrency(userCurrency, chip, fiatFixed === undefined ? 1000 : fiatFixed, true)}/>
        </>
    }else{
        return <>
            {fixedNumber(chip / (bb||1), 2).toLocaleString()}BB
        </>
    }
}

export function PrintFiatOrChip(num:number) : JSX.Element{
    let rr = localStorage.getItem("toggleViewFiat") || "true";
    let is_fiat = rr === "true";

    return PrintAlwaysBB(ROOM_TYPE.RING, is_fiat ? AlwaysBBType.fiat : AlwaysBBType.chip, num, 0, false)
}
