import {atom, selector} from "recoil";

export interface ExchangeRateItem {
  date: string,
  data: { [key: string]: number }
}

export const exchangeRateListState = atom<ExchangeRateItem[]>({
  key: 'exchangeRateListState',
  default: []
});
