import {useRecoilValue} from "recoil";
import {userIdSelector} from "../recoil/MyInfo";
import {useHistory, useLocation} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import useRefreshBaccaratTable from "../api/from_server_baccarat_refreshBaccaratMyTableInfo";
import {
  setAckListener as setBaccratTotalGameResult
} from "../api/from_server_baccarat_refreshBaccaratTableResultSummary";
import {
  BaccaratBetInfo,
  BaccaratBetType,
  BaccaratBonusType,
  BaccaratGameStatus,
  BaccaratResultInfo,
  BaccaratTotalBet,
} from "../dataset";
import {
  requestBaccaratJoinTable,
  requestBaccaratMyAllTableInfo,
  requestBaccaratMyTableInfo,
  requestBaccaratTableResultSummary,
  requestBaccaratTotalBetAmount,
  requestRequestBaccaratGameStatus,
  requestBaccaratResultList,
  requestMyMoney
} from "../api";
import useRefreshTotalBetAmount from "../api/from_server_baccarat_refreshBaccaratTotalBetAmount";
import {setAckListener as setBaccaratGameStatus} from "../api/from_server_baccarat_baccaratGameStatus";
import {setAckListener as setBaccaratRemainBetTime} from "../api/from_server_baccarat_baccaratRemainBetTime";
import {setAckListener as setBaccaratMyWinAmount} from "../api/from_server_baccarat_baccaratMyWinAmount";

interface BaccaratTableInfo {
  tableId: number;
  curInfo: BaccaratBetInfo;
}

interface BaccaratMyTableInfo {
  tableId: number;
  gameStatus: BaccaratGameStatus;
}

interface GameStatus {
  roomNumber: number;
  playerWin: number;
  bankerWin: number;
  tie: number;
}

function useLoadBaccaratGame() {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = useRecoilValue(userIdSelector);
  const updatedTotalBetAmount = useRefreshTotalBetAmount();
  const [tableData] = useRefreshBaccaratTable();
  const [table, setTable] = useState<BaccaratTableInfo>();
  const [myRooms, setMyRooms] = useState<BaccaratMyTableInfo[]>();
  const [remainBetTime, setRemainBetTime] = useState<[number,number]>([0,0]);
  const [myBalance, setMyBalance] = useState(0);
  const [totalBetAmount, setTotalBetAmount] = useState<BaccaratTotalBet>({
    player: {count: 0, total: 0, ratio: 0},
    banker: {count: 0, total: 0, ratio: 0},
    tie: {count: 0, total: 0, ratio: 0}
  });
  const [winAmount, setWinAmount] = useState<{
    amount: number;
    currency: string;
  }>({
    amount: 0,
    currency: '￦'
  });
  const [totalGameHistory, setTotalGameHistory] = useState<GameStatus>({
    roomNumber: 0,
    playerWin: 0,
    bankerWin: 0,
    tie: 0
  });
  const [resultList, setResultList] = useState<BaccaratResultInfo[]>([]);
  const [gameStatus, setGameStatus] = useState<BaccaratGameStatus | null>(null);

  const tableId = useMemo<number>(() => {
    return Number(searchParams.get("id")!);
  }, [searchParams]);

  useEffect(() => {
    requestBaccaratTableResultSummary(tableId);
  }, [])

  useEffect(() => {
    if (gameStatus && gameStatus.status === 'wait' && table) {
      //curInfo를 초기화한다.
      setTable({
        ...table,
        curInfo: {
          [BaccaratBetType.PLAYER]: 0,
          [BaccaratBetType.BANKER]: 0,
          [BaccaratBetType.TIE]: 0,
          [BaccaratBetType.PLAYER_PAIR]: 0,
          [BaccaratBetType.BANKER_PAIR]: 0,
          [BaccaratBetType.BANKER_BONUS]: {amount: 0, opt: BaccaratBonusType.NONE},
          [BaccaratBetType.PLAYER_BONUS]: {amount: 0, opt: BaccaratBonusType.NONE},
          [BaccaratBetType.EITHER_PAIR]: 0,
          [BaccaratBetType.PERFECT_PAIR]: 0,
        }
      })
    }
  }, [gameStatus])

  useEffect(() => {
    setBaccratTotalGameResult(async (data: {
      tie: number;
      tableId: number;
      games: number;
      player: number;
      banker: number;
    }) => {
      setTotalGameHistory({
        roomNumber: data.tableId,
        playerWin: data.player,
        bankerWin: data.banker,
        tie: data.tie
      })
    });
    setBaccaratGameStatus(async (data: { tableId: number; gameStatus: BaccaratGameStatus; }) => {
      setGameStatus(data.gameStatus)
      if(data.gameStatus.baccaratCards?.result){
        requestBaccaratResultList(tableId).then(res => {
          setResultList(res.results)
        })
      }
    });
    setBaccaratMyWinAmount(async (data: { tableId: number; userId: number; amount: number; currency: string; }) => {
      if (tableId === data.tableId) {
        setWinAmount({
          amount: data.amount,
          currency: data.currency
        })
      }
    });
    setBaccaratRemainBetTime(async (data: { tableId: number; remainTime: number; max:number }) => {
      setRemainBetTime([data.remainTime, data.max])
    });
    return () => {
      setBaccaratRemainBetTime(null)
      setBaccaratGameStatus(null);
      setBaccratTotalGameResult(null);
      setBaccaratMyWinAmount(null);
    }
  }, [tableId])

  useEffect(() => {
    console.log('tableData', tableData)
    if (tableData && tableData.tableId === tableId) {
      setTable(tableData);
    }
  }, [tableData, tableId]);

  useEffect(()=>{
    if(gameStatus?.status == "wait" || gameStatus?.status == "shuffle"){
      requestMyMoney().then(v=>{
        setMyBalance(v.balance);
      })
    }
  },[ gameStatus?.status ])

  useEffect(() => {
    if (tableId > 0) {
      const target = updatedTotalBetAmount.find(x => {
        if (x) {
          if (x.tableId === tableId) {
            return true;
          }
        }
        return false;
      })
      if (target) {
        setTotalBetAmount(target.totalBet);
      }
    }
  }, [updatedTotalBetAmount, tableId]);

  // 방이 존재하지 않으면 로비로 보내기
  useEffect(() => {
    if (tableId > 0) {
      requestBaccaratJoinTable(tableId).then((res) => {
        if (res) {
          requestBaccaratTotalBetAmount(tableId)
          requestBaccaratMyTableInfo(tableId)
          requestBaccaratMyAllTableInfo().then(res => {
            setMyRooms(res.list);
          })
          requestRequestBaccaratGameStatus(tableId)
          requestBaccaratResultList(tableId).then(res => {
            setResultList(res.results)
          })
        } else {
          // 참가 불가하면 로비로 보냄
          history.replace('/');
        }
      });
    }
  }, [tableId]);

  return {
    tableId,
    table,
    myRooms,
    totalBetAmount,
    gameStatus,
    totalGameHistory,
    remainBetTime,
    winAmount,
    resultList,
    myBalance
  };
}

export default useLoadBaccaratGame;
