import styled from "styled-components";
import {useEffect, useState} from "react";

const ToggleSwitchWrapper = styled.div<{ mode: 'default' | 'dark'; isChecked: boolean }>`{
  width: 34px;
  height: 20px;
  position: relative;
  border-radius: 10px;
  ${p => p.mode === 'default' && `
    background: #1F1F1F;
  `};
  ${p => p.mode === 'dark' && `
    ${p.isChecked ? `
      background: #2F99FB;
    ` : `
      background: rgba(255, 255, 255, 0.6);
    `};
  `};

  > .toggle {
    position: absolute;
    top: 50%;
    ${p => p.isChecked ? `
        transform: translate(18px, -50%); 
      ` : `
        transform: translate(4px, -50%);
      `};
    ${p => p.mode === 'default' && `
      ${p.isChecked ? `
        background: #2F99FB; 
      ` : `
        background: #FFF;
      `};
    `};
    ${p => p.mode === 'dark' && `
      background: #FFF;
    `};
    transition: all 0.2s ease-in-out;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
}`;

interface ToggleSwitchProps {
  mode?: 'default' | 'dark';
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const ToggleSwitch = ({mode = 'default', checked, onChange}: ToggleSwitchProps) => {
  const [isChecked, setIsChecked] = useState(checked);
  useEffect(() => {
    setIsChecked(checked)
  }, [checked])
  return <ToggleSwitchWrapper mode={mode} isChecked={isChecked}>
    <div className='toggle' onClick={() => {
      onChange(!isChecked);
      setIsChecked(!isChecked)
    }}/>
  </ToggleSwitchWrapper>
}

export default ToggleSwitch;
