import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import useRingGroupListRefresh from "../api/from_server_game_refreshRingGroupList";
import useRingGameListRefresh from "../api/from_server_game_refreshRingGameList";
import useTournamentGroupListRefresh from "../api/from_server_game_refreshTournamentGroupList";
import useNoticeListRefresh from "../api/game_getNoticelist";
import {
  requestGetNoticelist,
  requestJoinLobby,
  requestJoinRingGroup,
  requestRingGroupList,
  requestTournamentGroupList,
  requestMyInfo,
  requestMyGroupVaultList,
  requestGetHandCuttingList,
  requestDoCashoutVault,
  requestCanCashoutVault,
  requestRingGameList,
  requestJoinRoom, requestBaccaratTableList
} from '../api';
import { Link, useHistory } from 'react-router-dom';
import { BaccaratTable, NoticeSimpleInfo, RAKE_TYPE } from '../dataset';
import useDialog from "../hooks/useDialog";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { myInfoState } from "../recoil/MyInfo";
import Flex from "../components/common/Flex";
import PlayerCount from "../components/lobby/PlayerCount";
import TournamentItem from "../components/lobby/TournamentItem";
import ModalContainer from "../components/common/ModalContainer";
import ProfileModal from "../components/ProfileModal";
import useQueryParams from "../hooks/useQueryParams";
import useScreenOrientation, { MEDIA_DESKTOP } from "../hooks/useScreenOrientation";
import RightDrawer from "../components/common/RightDrawer";
import TournamentDetail from "../components/lobby/TournamentDetail";
import { globalLoadingState } from "../recoil/Loading";
import Menu from "../components/lobby/Menu";
import { bigBlindToGameStyle } from "../utils/ring";
import Setting from "../components/lobby/Setting";
import { useTranslation } from "react-i18next";
import LeaveSettlement from '../components/game/LeaveSettlement';
import { request } from 'http';
import { parseDatetime } from '../constants/moment';
import moment from 'moment';
import { title } from 'process';
import AdjustVaultModal, { VaultInfo } from '../components/AdjustVaultModal';
import { CurrencyMark, PrintPointOrFiat, UserCurrency, UserCurrencyMark, UserToChip, UserToCurrency } from '../conversionChip';
import LoadSimulator from '../components/BaccaratSimulator';
import { AlwaysBBType, gameOptionState } from '../recoil/GameOption';

export function nFormatter(num: number, digits: number) {
  const suffixes = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "k" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "G" },
    { value: 1E12, symbol: "T" }
  ];

  const suffix = suffixes.slice().reverse().find(suffix => num >= suffix.value);
  if (!suffix) return "0";

  const formattedNumber = (num / suffix.value).toFixed(digits).replace(/\.0$/, '');
  return `${formattedNumber}${suffix.symbol}`;
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-width: 320px;
  color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media ${MEDIA_DESKTOP} {
    padding: 0 32px;
  }
`;

const MyInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  padding: 12px 20px;
  margin-top: 10px; // 임시
  color: white;

  @media ${MEDIA_DESKTOP} {
    padding: 41px 0 0 0;
    margin-top: 0;
    align-items: center;
  }
`;

const Title = styled(Flex)`
  color: #FFF;
  font-size: 18px;
  margin-right: 16px;

  @media ${MEDIA_DESKTOP} {
    font-size: 24px;

    > * {
      display: inline-block;
    }
  }
`;

const TableCountDiv = styled.div`
  display: flex;
  margin-left: 10px;
  gap: 4px;
  color: rgb(47, 153, 251);
  align-items: center;

  >img{
    height: 100%;
    margin-left: 5px;
    margin-right: 5px;
  }
`

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;


  @media ${MEDIA_DESKTOP} {
    position: relative;
    flex-direction: row;
  }

  .container{
    display: flex;
    align-items: center;

    .profile {
      display: inline-flex;
      gap: 4px;
      align-items: center;
    }
  }
`;

const ProfileImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: 4px;
  object-fit: cover;
  cursor: pointer;

  @media ${MEDIA_DESKTOP} {
    width: 32px;
    height: 32px;
    margin-left: 10px;
  }
`;

const UserName = styled.span`
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
`;

const MyPoint = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 8px;
  border: 1px solid rgba(47, 153, 251, 0.20);
  background: linear-gradient(180deg, #1F1F1F 0%, #03284A 100%);
  padding: 2px 4px;

  @media ${MEDIA_DESKTOP} {
    padding: 11px 12px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    transform: translateX(-100%);
    font-size: 12px;
    border-radius: 28px;
    border: 1px solid #373737;
    flex-shrink: 0;
  }

  img {
    width: 16px;
    height: 16px;
    @media ${MEDIA_DESKTOP} {
      width: 20px;
      height: 20px;
    }
  }
`;

const PointText = styled.div`
  color: var(--Primary-blue, #2F99FB);
  text-align: center;
  font-family: Pretendard;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.2px;
  @media ${MEDIA_DESKTOP} {
    font-size: 14px;
  }
`;

const MenuTitle = styled.div`
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  
  @media ${MEDIA_DESKTOP} {
    justify-content: space-between;
  }
`;

const AdjustButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border-radius: 15px;
  border:1px solid #FAC23D;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(116deg, #ECB322 23.2%, #C38424 98.42%);

  cursor: pointer;

  &:hover{
    opacity: 0.8;
  }

  &:active{
    opacity: 0.6;
  }
`

const AdjustBar = styled.div`
  width: 100%;
  height: 60px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 2px solid #FFBE16;
  background: linear-gradient(103deg, #FDCB4B 9.68%, #E68F0C 90.32%);
  box-shadow: 0 0 16px 0 rgba(235, 156, 26, 0.50);
  cursor: pointer;
  position: relative;
  margin-bottom: 16px;
  
  >.background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: url("/images/adjust_bar.png") no-repeat;
    background-size: cover;
  }
  >.coin {
    position: absolute;
    width: 72px;
    height: 72px;
    top: 4px;
    right: 60px;
    z-index: 2;
  }
  span {
    z-index: 3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.2px;
  }
`

const RefreshButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  border-radius: 6px;
  background: #1F1F1F;
  padding: 8px;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.5px;
  cursor: pointer;

  img {
    width: 12px;
    height: 12px;
  }
`;
const CashGameMenu = styled.div`
  display: flex;
  gap:10px;
  padding: 0 16px;
`

const RingDropDown = styled.div<{
  select: boolean
}>`
  min-width: 100px;
  padding:8px 20px;
  border-radius: 20px;
  border: 1px solid gray;
  display:flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .title{
    font-size: 14px;
    font-weight: 400;
    img{
      margin-left: 10px;
      transform: rotate(90deg);
    }
  }

  ul.select{
    display: ${p => p.select ? 'block' : 'none'};
    position: absolute;
    right: 0;
    top:40px;
    background-color: #161619;
    z-index: 99999;
    padding: 10px;

    li{
      min-width: 100px;
      background-color: #1E1E1E;
      padding: 10px 0px;
      border-radius: 10px;
      font-size:13px;
      font-weight: 400;
      margin: 5px 0;
      text-align: center;

      &:hover{
        opacity: 0.7;
      }
      &:active{
        background: #111;
      }
    }
  }

  cursor: pointer;
  &:hover{
    background: #1F1F1F;
  }
  &:active{
    background: #111;
  }
`

const CategoryMenu = styled.ul`
  display: flex;
  gap: 10px;
  flex:1;
  overflow: scroll;
  white-space: nowrap;
  > li{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    border-radius: 8px;
    background: #1F1F1F;
    padding: 0 10px;
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.5px;
    cursor: pointer;
    height: 30px;
    @media ${MEDIA_DESKTOP} {

      font-size: 14px;
    }

    &.active{
      background: #3098F9;
    }
  }
`

const Content = styled.div`
  flex: 1;
  width: 100%;
  margin: 0 auto;
  font-size: 28px;
  font-weight: bold;
  overflow: auto;
  display: flex;
  flex-direction: column;

  > ul, > table {
    width: 100%;
  }

  > ul > li {
    &:hover {
      cursor: pointer;
    }
  }
`;

const LobbyTable = styled.div`
  flex: 1;
  width: 100%;
  font-size: 14px;
  margin: 0 auto;

  .table-row {
    display: flex;

    > div {
      text-align: center;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .table-head {
    font-weight: 500;
    background: #17171B;
    border-radius: 4px;

    > .table-row {
      border-right: 4px;

      > div {
        position: relative;

        &:not(:last-of-type) {
          &::after {
            position: absolute;
            right: 0;
            top: calc(50% - 6px);
            width: 2px;
            height: 12px;
            background-color: #2D2D2D;
            content: ' ';
          }
        }
      }
    }
  }

  .table-body {
    border-radius: 4px;

    .table-row {
      cursor: pointer;
      border-bottom: 1px solid #17171B;
      font-size: 14px;
      font-weight: 400;
      transition: all 0.1s linear;

      &:hover {
        border-radius: 8px;
        background: #1F1F25;
      }
    }
  }

  .lobby-ring {
    .table-row {
      > div {
        padding: 10px 35px;

        &:nth-of-type(1) {
          justify-content: flex-start;
          min-width: 136px;
          padding-left: 24px;
          font-weight: 600;
        }

        &:nth-of-type(2) {
          justify-content: flex-start;
          min-width: 230px;
          font-weight: 600;
        }

        &:nth-of-type(3) {
          min-width: 190px;
        }

        &:nth-of-type(4) {
          min-width: 158px;
        }

        &:nth-of-type(5) {
          min-width: 190px;
        }

        &:nth-of-type(6) {
          min-width: 111px;
        }
      }
    }
  }

  .lobby-tournament {
    .table-row {
      > div {
        padding: 10px 8px;

        &:nth-of-type(1) {
          min-width: 104px;
          font-weight: 600;
        }

        &:nth-of-type(2) {
          justify-content: flex-start;
          min-width: 497px;
          font-weight: 600;
        }

        &:nth-of-type(3) {
          min-width: 89px;
        }

        &:nth-of-type(4) {
          min-width: 105px;
        }

        &:nth-of-type(5) {
          min-width: 82px;
        }

        &:nth-of-type(6) {
          min-width: 136px;
        }
      }
    }
  }
`;

const Grid = styled.div<{
  cellWidth?: number
}>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(168px, auto));
  grid-auto-flow: row;
  grid-gap: 16px 7px;
  padding: 16px;
  justify-content: start;
  
  @media ${MEDIA_DESKTOP} {
    grid-template-columns: repeat(auto-fit, minmax(324px, auto));
  }
`;

const BaccaratItem = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  >.thumbnail {
    width: 100%;
  }
  >.content {
    padding: 0 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`

const RingItem = styled.div<{isJoined?: boolean}>`
  position: relative;
  max-width: 400px;
  ${p => p.isJoined ? `
    border-radius: 8px;
    border: 1px solid #FF6E5A;
  `: ``};
  overflow: hidden;
  img.cover {
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
    background: #343434;
    overflow: hidden;
    object-fit: cover;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  }
  .lighting {
    position: absolute;
    top: 0%;
    left: -150%;
    width: 150%;
    height: 240%;
    opacity: 0.5;
    background: linear-gradient(110deg, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 70%);
    animation: lighting-animation 3s infinite;
  }

  @keyframes lighting-animation {
    0% {
      left: -150%;
    }
    40% {
      left: 100%;
    }
    100% {
      left: 100%;
    }
  }
`;

const RingMyJoin = styled.div`
  position: absolute;
  left: 5px;
  top: 5px;
  padding: 4px 8px;
  z-index: 1000;
  color: #FFF;
  font-family: Pretendard;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.2px;
  border-radius: 20px;
  background: linear-gradient(180deg, #FF6E5A 0%, #F6421A 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.20);
`

const RingInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 12px;
  color: #FFF;
  font-weight: 600;
  padding: 4px;

  > div.row {
    > span {
      opacity: 0.5;
      font-weight: 500;
      margin-right: 2px;

      &:not(:first-of-type) {
        margin-left: 6px;
      }
    }
  }

  >div.title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    >div{
      &:first-child{
        font-size: 15px;
        font-weight: bold;
        text-transform: uppercase;
      }
      display: flex;
      align-items: center;
      gap: 2px;
    }
  }
`;

const TableCount = styled.div`
  position: relative;
  width: 20px;
  height: 14px;

  background-image: url(/images/table_count_bg.svg?v=1);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 12px;
  color: #FFF;
  padding: 6px;
  margin-bottom: 2px;
`;

const RingStatus = styled.div<{
  maxTableMember: number
}>`
  text-align: center;

  .max-seat {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    position: absolute;
    top: 0px;
    left: -2px;
    border-radius: 50%;
    background-color: ${p => p.maxTableMember > 6 ? '#8343D3' : '#3C72BA'};
    font-size: 9px;
    color: #FFF;
  }

  @media ${MEDIA_DESKTOP} {
    text-align: left;
    color: ${p => p.maxTableMember > 6 ? '#8343D3' : '#3C8FFF'};

    * {
      display: inline-block;

      &.table-count {
        margin-left: 8px;
        font-size: 14px;
        padding: 8px;
      }
    }
  }
`;

const RippleAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.4;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
`;

const Ripple = styled.div<{ delayed: number }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #906CEE;
  position: absolute;
  top: 10px;
  right: 10px;
  /* transform: translate(-50%, -50%); */
  opacity: 0;
  
  animation: ${RippleAnimation} 2s linear infinite;
  animation-delay: ${props => props.delayed}s;
  pointer-events: none;
  /* z-index: -1; */
`;

const NoticeTitle = styled.div`
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  line-height: 132%; /* 15.84px */
`;

const EmptyView = styled.div`
  height: calc(100% - 51px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;

  img {
    margin-bottom: 20px;
  }


  @media ${MEDIA_DESKTOP} {
    height: 100%;
  }
`;

const JoinRoomButtons = styled.div`
  width: 100%;
  display: flex;
  gap:3px;
  margin-top: 5px;

  >div{
    cursor: pointer;
    flex:1;
    padding: 8px 0;
    text-align: center;
    border-radius: 8px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.adjust{
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(116deg, #ECB322 23.2%, #C38424 98.42%);
    }

    &:hover{
      opacity: 0.8;
    }

    &:active{
      opacity: 0.6;
    }
  }
`

export const ToggleButton = styled.div<{
  active: boolean
}>`
  display: flex;
  padding: 5px 8px;
  border-radius: 20px;
  margin-left: 10px;
  background: #353337;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  cursor: pointer;
  width: 50px;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }

  .status{
    border-radius: 50%;
    background-color: #2A92F1;
    position: absolute;

    transition: all 0.1s;
    ${p=>p.active ? `transform:translate(-7px, -6px);`: `transform:translate(17px, -7px);`}

    width: 26px;
    height: 26px;
    z-index: 1;
  }

  .txt{
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight:bold;
  }
`;

export function getShortLang(dd: string) {
  switch (dd) {
    case "ko-KR":
      return "KR";
    case "jp-JP":
      return "JP";
    case "en-US":
      return "EN";
    case "th-TH":
      return "THAI";
    default:
      return "KR";
  }
}

const MENU_CASH = 'cash';
const MENU_TOURNAMENT = 'tournament';
const MENU_NOTICE = 'notice';
const MENU_SETTING = 'setting'
const MENU_BACCARAT = 'baccarat'

// eslint-disable-next-line import/no-anonymous-default-export
export default (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const queryParams = useQueryParams();
  const { openDialog } = useDialog();
  const orientation = useScreenOrientation();
  const myInfo = useRecoilValue(myInfoState);
  const setMyInfo = useSetRecoilState(myInfoState);
  const setGlobalLoading = useSetRecoilState(globalLoadingState);
  const [ringGroups] = useRingGroupListRefresh();
  const [ringGames] = useRingGameListRefresh();
  const [baccaratTableList, setBaccaratTableList] = useState<BaccaratTable[]>([]);
  const [setting, setSetting] = useRecoilState(gameOptionState);

  const [tournamentGroups] = useTournamentGroupListRefresh();
  const [notices] = useNoticeListRefresh();
  const [selectedGroupId, setSelectedGroupId] = useState<number>();
  const [myVaults, setMyVaults] = useState<VaultInfo[]>([]);
  const [showProfile, setShowProfile] = useState<boolean>(false);
  const [showVaultAdjust, setShowVaultAdjust] = useState<boolean>(false);
  const [selectGroupId, setSelectGroupId] = useState<number>(-1);
  const [selectSort, setSelectSort] = useState<number>(0);
  const [openSort, setOpenSort] = useState<boolean>(false);

  const [toggleViewFiat, setToggleViewFiat] = useState<boolean>(true);
  const tournamentId = queryParams.get("tournamentId");
  console.log('tournamentId', tournamentId, window.location.href)
  useEffect(() => {
    if(tournamentId){
      console.log('>>>> aaaa',5)
      setSelectedGroupId(Number(tournamentId));
    }
  }, [tournamentId]);

  useEffect(function () {
    console.log("selectGroupId, selectSort", selectGroupId, selectSort)
    requestRingGameList(selectGroupId, selectSort);
  }, [selectGroupId, selectSort])

  const menu = useMemo<string>(() => {
    return queryParams.get('menu') || MENU_CASH;
  }, [queryParams]);

  const handleClickMenu = useCallback((menu: string) => {
    queryParams.set("menu", menu);
    history.replace({ search: queryParams.toString() });
  }, [queryParams]);

  const handleRefresh = useCallback(async (menu: string) => {
    setGlobalLoading(true);
    if (menu === MENU_CASH) {
      await requestRingGroupList();
      await requestRingGameList(selectGroupId, selectSort);
    } else if (menu === MENU_TOURNAMENT) {
      await requestTournamentGroupList();
    } else if (menu === MENU_NOTICE) {
      await requestGetNoticelist();
    }
    requestMyInfo().then(v => {
      setMyInfo(v.info);
    });
    setGlobalLoading(false);
  }, [selectGroupId, selectSort]);

  const handleClickJoin = useCallback(async function (groupId: number) {
    setGlobalLoading(true);
    let r = await requestJoinRingGroup(groupId);
    setGlobalLoading(false);
    if (typeof (r.result) == "number") {
      if (r.result == -1) {
        openDialog({
          title: t('오류'),
          text: t('현재 진행중인 게임이 있어 입장할 수 없습니다.'),
          onConfirm: () => {
            window.location.reload();
          }
        });
      } else
        history.push("/game?id=" + r.result);
    }
  }, []);

  const handleClickJoinRoom = useCallback(async function (roomId: number) {
    setGlobalLoading(true);
    let r = await requestJoinRoom(roomId);
    setGlobalLoading(false);
    if (typeof (r.result) == "number") {
      if (r.result) {
        history.push("/game?id=" + roomId);
      }
    }
  }, []);

  const handleClickBaccaratTable = useCallback(async function (tableId: number) {
    history.push("/baccarat?id=" + tableId);
  }, []);


  useEffect(function () {
    handleRefresh(menu);
    if (menu === MENU_BACCARAT) {
      requestBaccaratTableList().then(res => {
        setBaccaratTableList(res.tables);
      })
    }
  }, [menu]);

  useEffect(function () {
    requestJoinLobby();
    requestMyGroupVaultList().then(v => {
      setMyVaults(v.list || []);
    });
    return () => {
      requestJoinLobby();
    };
  }, []);

  useEffect(function () {
    requestMyInfo().then(v => {
      setMyInfo(v.info);
    });
    requestTournamentGroupList();
  }, [])

  const handleClickTournament = useCallback((groupId: number) => {
    setSelectedGroupId(groupId);
    queryParams.set("tournamentId", groupId.toString());
    history.replace({ search: queryParams.toString() });
  }, []);

  const onClickCashOut = useCallback(async (vaultId: number, groupId: number, roomId: number) => {
    const check = await requestCanCashoutVault(groupId, roomId, vaultId);
    if (check.result == -1) {
      return openDialog({
        title: t('게임 진행 중'),
        text: t('현재 게임 진행 중입니다. 게임이 끝난 뒤 정산하실 수 있습니다.'),
        onConfirm: () => {
        }
      });
    }

    const _vaults = await requestMyGroupVaultList()
    const vaults = _vaults.list || [];
    setMyVaults(vaults);

    const res = await requestGetHandCuttingList();
    const vault = vaults.find(v => v.vaultId == vaultId);

    if (vault == null) return alert(t("정산이 없습니다."));

    const handCuttingList = res.list;
    let settle = vault.amount;
    let buyin = vault.buyin;
    let winAmount = settle - buyin;
    let lossPercentage = 0;
    let maxHands = 0;
    let cuttingList = res.list.sort((a, b) => {
      return a.handCount - b.handCount
    })
    maxHands = cuttingList[cuttingList.length - 1].handCount;

    for (let v of handCuttingList) {
      if (vault.hands <= v.handCount) {
        lossPercentage = v.lossPercentage
        let lossAmount = Math.floor(winAmount * (lossPercentage) / 100);
        settle = settle - lossAmount;
        break;
      }
    }
    if (winAmount < 0) {
      settle = vault.amount;
      winAmount = 0;
    }
    const leave = await new Promise<boolean>((r) => {
      openDialog({
        // title: t("정산"),
        component: <LeaveSettlement
          hands={vault.hands}
          stack={vault.amount}
          buyin={buyin}
          winAmount={winAmount}
          lossPercentage={lossPercentage}
          settle={settle}
          maxHands={maxHands + 1}
          cuttingList={cuttingList}
          lack={maxHands >= vault.hands}
        />,
        disableCloseButton: true,
        confirm: true,
        confirmText: t('정산하기'),
        cancelText: t('취소'),
        onConfirm: () => {
          r(true);
        },
        onCancel: () => {
          r(false);
        }
      });
    });

    if (leave) {
      const fiat = UserToCurrency(settle);
      requestDoCashoutVault(groupId, roomId, vaultId, fiat).then(v => {
        if (v.result == 1) {
          openDialog({
            title: t('정산 완료'),
            text: t('정산이 완료되었습니다.'),
            onConfirm: () => {
            }
          });

          requestRingGameList(selectGroupId, selectSort);
          requestMyInfo().then(v => {
            setMyInfo(v.info);
          });
          requestMyGroupVaultList().then(v => {
            setMyVaults(v.list || []);
          });
        } else if (v.result == -1) {
          openDialog({
            title: t('게임 진행 중'),
            text: t('현재 게임 진행 중입니다. 게임 종료 후 정산하실 수 있습니다.'),
            onConfirm: () => {
            }
          });
        } else if (v.result == -2) {
          openDialog({
            title: t('정산 실패'),
            text: t('정산 할 내용이 없습니다.'),
            onConfirm: () => {
              requestMyInfo().then(v => {
                setMyInfo(v.info);
              });
              requestMyGroupVaultList().then(v => {
                setMyVaults(v.list || []);
              });
            }
          });
        } else if (v.result == 0) {
          openDialog({
            title: t('정산 실패'),
            text: t('정산에 실패했습니다. 관리자에게 문의해주세요.'),
            onConfirm: () => {
            }
          });
        }
      });
    }
  }, [myVaults, selectGroupId, selectSort]);

  const handleAdjustVault = useCallback(async (vault: VaultInfo) => {
    onClickCashOut(vault.vaultId, vault.groupId, vault.roomId);
    setShowVaultAdjust(false);
  }, []);

  const onClickRingGameSort = useCallback((event: any, sort: number) => {
    event.stopPropagation();
    setSelectSort(sort);
    setOpenSort(false);
  }, [])

  const sortType = [
    t("추천순"),
    t("인원 많은 순"),
    t("인원 적은 순"),
    t("바이인 높은 순"),
    t("바이인 적은 순"),
  ]

  useEffect(function(){
    let r = localStorage.getItem("toggleViewFiat");
    if(r){
      setToggleViewFiat(r == "true");
    }
  })

  const onClickToggleViewFiat = useCallback(() => {
    let v = !toggleViewFiat;
    setToggleViewFiat(v);
    localStorage.setItem("toggleViewFiat", (v).toString());

    setSetting((prev) => ({
      ...prev,
      alwaysBB: v ? AlwaysBBType.fiat : AlwaysBBType.chip
    }));
  }, [toggleViewFiat])

  const handleCloseTournamentDetail = useCallback(()=>{
    setSelectedGroupId(undefined)
    queryParams.delete("tournamentId");
    history.replace({ search: queryParams.toString() });
  },[])

  return <Wrapper>
    <MyInfoContainer>
      <Title>
        <img src="/images/app_logo.svg" />
      </Title>
      {
        myInfo ? <>
          <ProfileWrapper>
            <div className='container'>
              <div className='profile'>
                <ProfileImage src={`/images/profile/${myInfo.profileImg}.png`} onClick={() => setShowProfile(true)} />
                <UserName>{myInfo.nickname}</UserName>
              </div>
              <div>
              <ToggleButton active={toggleViewFiat} onClick={onClickToggleViewFiat}>
                <div className='status'/>
                <div className='txt'>
                  <div>{UserCurrencyMark()}</div>
                  <div>P</div>
                </div>
              </ToggleButton>
              </div>
            </div>
            <MyPoint>
              {
                toggleViewFiat ? <>
                  {
                    UserCurrency() === 'KRW' && <img className='coin' src='/images/coin/Coin_KRW.svg'/>
                  }
                  {
                    UserCurrency() === 'USD' && <img className='coin' src='/images/coin/Coin_USD.svg'/>
                  }
                  {
                    UserCurrency() === 'JPY' && <img className='coin' src='/images/coin/Coin_JPY.svg'/>
                  }
                  {
                    UserCurrency() === 'PHP' && <img className='coin' src='/images/coin/Coin_PHP.svg'/>
                  }
                  {
                    UserCurrency() === 'THB' && <img className='coin' src='/images/coin/Coin_THB.svg'/>
                  }
                </> : <img className='coin' src='/images/coin/Coin_Point.svg'/>
              }
              <PointText>{
                toggleViewFiat ? myInfo.money.toLocaleString() : UserToChip(myInfo.money).toLocaleString()
              }</PointText>
            </MyPoint>
          </ProfileWrapper>
        </> : <>
          Loading...
        </>
      }
    </MyInfoContainer>
    <Menu current={menu} onClickMenu={handleClickMenu} tournamentGroups={tournamentGroups?.list || []} />
    <Content>
      {
        <MenuTitle>
          {
            menu === MENU_CASH && t("캐시게임")
          }
          {
            menu === MENU_BACCARAT && "BACCARAT"
          }
          {
            menu === MENU_TOURNAMENT && t("토너먼트")
          }
          {
            menu === MENU_NOTICE && t("공지사항")
          }
          {
            menu !== MENU_SETTING && (
              <RefreshButton onClick={() => handleRefresh(menu)}>
                <img src="/images/ic_refresh_list.svg" />{t("새로고침")}
              </RefreshButton>
            )
          }
          {
            menu === MENU_CASH && <>
              {
                orientation !== 'landscape' && <>
                <div style={{flex: 1}}/>
                {myVaults && myVaults.length > 0 &&
                  <AdjustButton onClick={() => setShowVaultAdjust(true)}>{t("정산하기")}</AdjustButton>}
                </>
              }
            </>

          }

        </MenuTitle>
      }
      {
        (menu === MENU_CASH && orientation === 'landscape' && myVaults && myVaults.length > 0) && (
          <AdjustBar onClick={() => setShowVaultAdjust(true)}>
            <div className='background'/>
            <span>{t('정산받을 내용이 있어요!')}</span>
            <img className='coin' src="/images/adjust_coin.png" />
          </AdjustBar>
        )
      }
      {menu === MENU_CASH && <>
        <CashGameMenu>
          <CategoryMenu>
            <li className={selectGroupId == -1 ? "active" : ""} onClick={() => setSelectGroupId(-1)}>NOW🔥</li>
            {/*<li className={selectGroupId == -2 ? "active" : ""} onClick={() => setSelectGroupId(-2)}>{t("입장가능")}</li>*/}
            {ringGroups?.list.map(e => {
              return <li key={e.groupId} className={selectGroupId == e.groupId ? "active" : ""} onClick={() => setSelectGroupId(e.groupId)}>
                {nFormatter(Number(e.data.blind[0]), 1).toUpperCase()} - {nFormatter(Number(e.data.blind[1]), 1).toUpperCase()}
              </li>
            })}
          </CategoryMenu>
          <RingDropDown select={openSort} onClick={() => setOpenSort(!openSort)}>
            <div className='title'>
              {sortType[selectSort]}
              <img src="/images/ic_arrow_right.svg" />
            </div>

            <ul className='select'>
              {sortType.map((e, i) => {
                return <li onClick={(e) => onClickRingGameSort(e, i)}>{e}</li>
              })}
            </ul>
          </RingDropDown>
        </CashGameMenu>
      </>}

      {menu === MENU_CASH && (
        <Grid>
          {ringGames?.list.sort((a, b) => {
            const myJoin1 = a.players.find(v => v == myInfo?.userId) || 0;
            const myJoin2 = b.players.find(v => v == myInfo?.userId) || 0;

            return myJoin2 - myJoin1;
          }).map(e => {
            const myJoin = e.players.find(v => v == myInfo?.userId);

            return <RingItem key={e.roomId} isJoined={Boolean(myJoin)}>
              {myJoin && <div className="lighting" />}
              <img onClick={() => handleClickJoinRoom(e.roomId)} className="cover" src={`/images/ring_thumb_${bigBlindToGameStyle(e.data.blind[1])}.png`} />
              {myJoin && <RingMyJoin>{t("참여 중")}</RingMyJoin>}
              <RingInfo>
                <div className='title'>
                  <div>
                    {nFormatter(Number(e.data.blind[0]),1)} -
                    {nFormatter(Number(e.data.blind[1]),1)}({e.roomName})
                  </div>
                  <div className="row" style={{ display: "flex" }}>
                    <PlayerCount count={e.playerCount} />
                    <PlayerCount count={e.viewerCount || 0} fontColor="#8f8f8f" img='/images/eye_open_gray.png?v=1' />
                  </div>
                </div>
                <div className="row">
                  <span>{t("바이인")}</span>
                  {PrintPointOrFiat(e.data.minBuyin,null)} ~ {PrintPointOrFiat(e.data.maxBuyin,null)}
                </div>
                {
                  e.data.isAnte && (
                    <div className="row">
                      <span>{t('앤티')}</span>
                      {PrintPointOrFiat(e.data.ante, null)}
                      {
                        e.data.isStraddle && <>
                          <span>{t('스트래들')}</span> {t('있음')}
                        </>
                      }
                    </div>
                  )
                }
                <div className="row">
                  <span>SB/BB</span>
                  {PrintPointOrFiat(e.data.blind[0],null)} / {PrintPointOrFiat(e.data.blind[1],null)}
                </div>
                {/*<JoinRoomButtons>
                  {!myVaults.find(v => v.groupId == e.groupId && v.roomId == e.roomId) && <div className='empty' />}
                  {myVaults.find(v => v.groupId == e.groupId  && v.roomId == e.roomId) && <div className='adjust' onClick={() => onClickCashOut(e.groupId, e.roomId)}>{t("정산하기")}</div>}
                </JoinRoomButtons>*/}
              </RingInfo>
            </RingItem>
          })}
        </Grid>
      )}

      {/*  old style */ /*
        menu === MENU_CASH && (
          <Grid>
            {ringGroups?.list.map(e => (
                <RingItem key={e.groupId}>
                  <img onClick={() => handleClickJoin(e.groupId)} className="cover" src={`/images/ring_thumb_${bigBlindToGameStyle(e.data.blind[1])}.png`} />
                  <RingInfo>
                    <div className="row">
                      <span>BUY IN</span>
                      {e.data.minBuyin.toLocaleString()} ~ {e.data.maxBuyin.toLocaleString()}
                    </div>
                    {
                      e.data.isAnte && (
                        <div className="row">
                          <span>{t('앤티')}</span>
                          {e.data.ante.toLocaleString()}
                          {
                            e.data.isStraddle && <>
                              <span>{t('스트래들')}</span> {t('있음')}
                            </>
                          }
                        </div>
                      )
                    }
                    <div className="row">
                      <span>SB/BB</span>
                      {e.data.blind[0].toLocaleString()} / {e.data.blind[1].toLocaleString()}
                    </div>
                    <div className="row" style={{ display: "flex" }}>
                      <PlayerCount count={e.totalPlayerCount} />
                      <PlayerCount count={e.tableCount} img='/images/table_count_bg.svg?v=1' />
                    </div>
                    <JoinRoomButtons>
                      {!myVaults.find(v => v.groupId == e.groupId) && <div className='empty' />}
                      {myVaults.find(v => v.groupId == e.groupId) && <div className='adjust' onClick={() => onClickCashOut(e.groupId)}>{t("정산하기")}</div>}
                    </JoinRoomButtons>
                  </RingInfo>
                </RingItem>
              ))
            }
          </Grid>
        )
      */}
      {
        (menu === MENU_TOURNAMENT && orientation.indexOf('portrait') >= 0) && (
          <ul>
            {
              tournamentGroups?.list.map((group, i) => (
                <Link key={group.groupId} to={`/tournament/${group.groupId}`}>
                  <TournamentItem group={group} />
                </Link>
              ))
            }
            {
              tournamentGroups?.list.length === 0 && (
                <EmptyView>
                  <img src="/images/ic_tournament_empty.svg" />
                  {t('아직 열려있는 토너먼트가 없습니다.')}
                </EmptyView>
              )
            }
          </ul>
        )
      }
      {
        (menu === MENU_TOURNAMENT && orientation.indexOf('landscape') >= 0) && (
          <LobbyTable>
            <div className="table-head lobby-tournament">
              <div className="table-row">
                <div>{t('상태')}</div>
                <div>{t('이벤트이름')}</div>
                <div>{t('시작')}</div>
                <div>{t('바이인')}</div>
                <div>{t('인원')}</div>
                <div>{t('상금')}</div>
              </div>
            </div>
            <div className="table-body lobby-tournament">

              {
                tournamentGroups?.list.filter(v => {
                  const startedAt = parseDatetime(v.data.startedAt);
                  const isStarted = moment().utc().isSameOrAfter(startedAt);

                  return isStarted && !v.isEnd;
                }).map((group, i) => (
                  <TournamentItem
                    key={group.groupId}
                    className="table-row"
                    group={group}
                    landscape
                    onClick={handleClickTournament}
                  />
                ))
              }

              {
                tournamentGroups?.list.filter(v => {
                  const startedAt = parseDatetime(v.data.startedAt);
                  const isStarted = moment().utc().isSameOrAfter(startedAt);

                  return !isStarted || v.isEnd;
                }).map((group, i) => (
                  <TournamentItem
                    key={group.groupId}
                    className="table-row"
                    group={group}
                    landscape
                    onClick={handleClickTournament}
                  />
                ))
              }

              {
                tournamentGroups?.list.length === 0 && (
                  <EmptyView>
                    <img src="/images/ic_tournament_empty.svg" />
                    {t('아직 열려있는 토너먼트가 없습니다.')}
                  </EmptyView>
                )
              }
            </div>
          </LobbyTable>
        )
      }
      {
        menu === MENU_BACCARAT && (
          <>
            <CashGameMenu>
              <CategoryMenu>
                <li>HOT</li>
                <li>신규</li>
              </CategoryMenu>
              <RingDropDown select={openSort} onClick={() => setOpenSort(!openSort)}>
                <div className='title'>
                  {sortType[selectSort]}
                  <img src="/images/ic_arrow_right.svg" />
                </div>

                <ul className='select'>
                  {sortType.map((e, i) => {
                    return <li onClick={(e) => onClickRingGameSort(e, i)}>{e}</li>
                  })}
                </ul>
              </RingDropDown>
            </CashGameMenu>
          </>
        )
      }
      {
        menu === MENU_BACCARAT && (
          baccaratTableList.map((table, i) => {
            return <BaccaratItem key={i} onClick={() => handleClickBaccaratTable(table.tableId)}>
              <img className="thumbnail" src='/images/baccarat/Thumbnail.png' />
              <div className="content">
                <div>스피드 바카라{table.tableId}</div>
              </div>
            </BaccaratItem>
          })
        )
      }
      {
        menu === MENU_NOTICE && (
          <Grid>
            {
              notices?.list.map((x: NoticeSimpleInfo) => {
                const lang = getShortLang(localStorage.getItem('i18nextLng') || "ko-KR");
                const r = JSON.parse(localStorage.getItem('notices') || '[]');
                const c = x.noticeId <= 30 || r.includes(x.noticeId);
                const ll = x.langData && x.langData[lang] ? x.langData[lang] : {
                  thumb: x.thumb,
                  title: x.title,
                };
                return <Link key={x.noticeId} to={`/notice/${x.noticeId}`}>
                  <RingItem>
                    <img className="cover" src={ll.thumb} />
                    <NoticeTitle>{ll.title}</NoticeTitle>
                    {!c ? <>
                      <Ripple delayed={0} />
                      <Ripple delayed={0.7} />
                      <Ripple delayed={1.4} />
                    </> : <></>}
                  </RingItem>
                </Link>
              })
            }
            {
              notices?.list.length === 0 && (
                <EmptyView>
                  <img src="/images/ic_event_empty.svg" />
                  {t('아직 열려있는 이벤트가 없습니다.')}
                </EmptyView>
              )
            }
          </Grid>
        )
      }
      {
        menu === MENU_SETTING && <>
          <Setting />
        </>
      }
    </Content>
    {
      (showProfile && myInfo?.userId) && (
        <ModalContainer show>
          <ProfileModal userId={myInfo.userId} onClose={() => setShowProfile(false)} />
        </ModalContainer>
      )
    }
    {
      (showVaultAdjust && myVaults.length > 0) && (
        <ModalContainer show onBackdropClick={() => setShowVaultAdjust(false)}>
          <AdjustVaultModal onAdjusted={handleAdjustVault} groups={ringGroups?.list || []} />
        </ModalContainer>
      )
    }
    {
      <RightDrawer opened={selectedGroupId !== undefined} noSwipe onClose={handleCloseTournamentDetail}>
        {
          selectedGroupId !== undefined && (
            <TournamentDetail groupId={selectedGroupId} onClose={handleCloseTournamentDetail} />
          )
        }
      </RightDrawer>
    }
  </Wrapper>;
};
