import moment from "moment/moment";
import i18n from "./i18n";
import i18next from "i18next";

export const wait = async (ms: number) => {
  await new Promise<void>((r) => setTimeout(() => r(), ms));
}

export const convertMinutes = (time: number) => {
  if ((time % 60) === 0) {
    return i18n.t("{{m}}분", {m:moment(time * 1000).format("m")})
  } else {
    return i18n.t("{{m}}분 {{s}}초", {
      m:moment(time * 1000).format("m"),
      s:moment(time * 1000).format("s")
    })
  }
}


export const fixedNumber = (value: number, fixed: number) => {
  //fixed 자리수까지 소수점
  return Number(value.toFixed(fixed))
}

export const numCardsByRank = (rank: number): number => {
  if (rank === 1) {
    return 1;
  } else if (rank === 2) {
    return 2;
  } else if (rank === 3 || rank === 8) {
    return 4;
  } else if (rank === 4) {
    return 3;
  } else {
    return 5;
  }
}

export const toCardString = (value: string, suit: string) => {
  return value.replace('1', 'A') + suit;
}

export const toEasternNumeral = (n: number, lang: string): string => {
  if (n <= 0) {
    return '0';
  }

  if (lang == 'en-US' || lang == 'th-TH') {
    return n.toLocaleString();
  }

  const chunks = [];
  const units = [i18n.t("조"), i18n.t("억"), i18n.t("만"), ""];

  let num = n;
  while (num > 0) {
    const mod = Math.floor(num % 10000);
    const modStr = mod.toLocaleString();
    num = Math.floor(num / 10000);
    if (mod === 0) {
      units.pop();
      continue;
    }

    const unit = units.pop();
    chunks.unshift(`${modStr}${unit}`);
  }

  return chunks.slice(0, 2).join(' ');
}

export const numberToCard = (n: number): { value: string, suit: 'Spade' | 'Heart' | 'Diamond' | 'Clover' } => {
  type Suit = 'Spade' | 'Heart' | 'Diamond' | 'Clover';
  const suits: Suit[] = ['Clover', 'Diamond', 'Heart', 'Spade'];
  const values = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];

  const suit = suits[Math.floor(n / 13)];
  const value = values[n % 13];

  return {value, suit};
}

export const formatNumber = (num: number): string => {
  const format = (n: number): string => {
    return n % 1 === 0 ? n.toString() : n.toFixed(2).replace(/\.?0+$/, '');
  };

  if (num >= 1_000_000) {
    return format(num / 1_000_000) + 'M';
  } else if (num >= 1_000) {
    return format(num / 1_000) + 'K';
  } else {
    return format(num);
  }
}
