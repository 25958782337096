import styled from "styled-components";
import {useRecoilValue} from "recoil";
import {gameOptionState} from "../../recoil/GameOption";
import useScreenOrientation, {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import Chips from "./Chips";
import {useMemo} from "react";
import { ROOM_TYPE } from "../../dataset";
import { PrintAlwaysBB } from "../PrintAlwaysBB";
import {Textfit} from "react-textfit";

const Wrapper = styled.div`
  position: absolute;
  left: -100%;
  top: -100%;
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PotItems = styled.div`
  gap: 2px;
  margin-bottom: 4px;
  text-align: center;

  @media ${MEDIA_DESKTOP} {
    flex-direction: row;
    gap: 2px;
  }
  
  > div {
    margin: 0 auto;
    min-width: 50px;
    width: fit-content;
    .label {
      color: #FFF;
      font-size: 10px;
      font-weight: 600;
      border-radius: 15px;
      background: rgba(24, 26, 29, 0.50);
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12) inset;
      padding: 2px 8px;
      transform: scale(0.9);

      @media ${MEDIA_DESKTOP} {
        font-size: 12px;
        transform: none;
      }
    }
  }
`;

const TotalPot = styled.div`
  min-width: 95px;
  border-radius: 4px;
  background: rgba(24, 26, 29, 0.50);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12) inset;
  padding: 2px 10px;
  color: #2F99FB;
  font-size: 10px;
  font-weight: 700;

  > div {
    color: #FFF;
    font-weight: 500;
  }
  
  @media ${MEDIA_DESKTOP} {
    min-width: 168px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 4px 12px;
    font-size: 16px;
    
    > div {
      font-size: 12px;
    }
  }
`;

function FieldPots(
  {
    pots,
    total,
    BB,
    children,
    roomType
  }: {
    roomType:ROOM_TYPE
    pots: number[];
    total: number,
    BB: number;
    children?: any
  }
): JSX.Element {
  const orientation = useScreenOrientation()
  const gameOption = useRecoilValue(gameOptionState);
  const lang = localStorage.getItem('i18nextLng');

  const potSize = useMemo(() => {
    return pots.reduce((a, v) => a + v, 0);
  }, [pots]);

  return <Wrapper className="field-pots">
    <Chips className="field-pot" amount={potSize}/>
    <PotItems>
      {
        pots.slice(0, 4).filter(pot => pot > 0).map((amount, i) => (
          <div key={i}>
            <div className="label">
              <Textfit mode="single" max={orientation === 'landscape' ? 12 : 10}>
                <span>
                  {PrintAlwaysBB(roomType, gameOption.alwaysBB, amount, BB, true)}
                </span>
                {/* <span>{gameOption.alwaysBB ? `${fixedNumber(amount / BB, 2).toLocaleString()} BB` : toEasternNumeral(amount, lang || "ko-KR")}</span> */}
              </Textfit>
            </div>
          </div>
        ))
      }
    </PotItems>
    <TotalPot>
      <div>POT</div>
      <Textfit mode="single" max={orientation === 'landscape' ? 16 : 10}>
        <span>
          {PrintAlwaysBB(roomType, gameOption.alwaysBB, total, BB, true)}
        </span>
        {/* <span>{gameOption.alwaysBB ? `${fixedNumber(total / BB, 2).toLocaleString()} BB` : toEasternNumeral(total, lang || "ko-KR")}</span> */}
      </Textfit>
    </TotalPot>
    {children}
  </Wrapper>;
}

export default FieldPots;
