import React from "react";
import styled from "styled-components";
import useScreenOrientation, {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import Chips from "./Chips";
import {gameOptionState} from "../../recoil/GameOption";
import {useRecoilState} from "recoil";
import {fixedNumber} from "../../utils/common";
import { ROOM_TYPE } from "../../dataset";
import { PrintAlwaysBB } from "../PrintAlwaysBB";
import {Textfit} from "react-textfit";

const Wrapper = styled.div`
  position: absolute;
  left: -100%;
  top: -100%;
  user-select: none;
  width: 56px; // chip image height 14px, 4 cols => 14 * 4
  height: 42px;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  
  @media ${MEDIA_DESKTOP} {
    width: 80px; // chip image height 80px, 4 cols => 14 * 4
    height: 54px;
  }

  img {
    &[data-amount="0"] {
      opacity: 0;
    }
  }
  .amount {
    display: inline-block;
    color: white;
    font-weight: 500;
    font-size: 10px;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.50);

    @media ${MEDIA_DESKTOP} {
      font-weight: 600;
      font-size: 12px;
    }
  }
`;

function PlayerPot(
  {
    amount,
    userId,
    bb,
    roomType
  }: {
    amount: number,
    userId: number,
    bb: number,
    roomType: ROOM_TYPE
  }
): JSX.Element {
  const orientation = useScreenOrientation();
  const [setting, setSetting] = useRecoilState(gameOptionState);
  return <Wrapper className={'game-player-pot'} data-amount={amount}>
    <Chips className={`chips pot-${userId}`} amount={amount} />
    {
      amount > 0 && (
        <Textfit mode="single" max={orientation === 'landscape' ? 12 : 10}>
          <div className='amount'>
          {/* {
            setting.alwaysBB ?
              <div className='amount'>{fixedNumber(amount / bb, 2).toLocaleString()} BB</div> :
              <div className='amount'>{amount.toLocaleString()}</div>
          } */}
          {PrintAlwaysBB(roomType, setting.alwaysBB, amount, bb)}
          </div>
        </Textfit>
      )
    }
  </Wrapper>;
}

export default PlayerPot;
