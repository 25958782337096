/* eslint-disable */
//auto gen
import {
    EXCHANGE_RATE,
    BET_TYPE,
    MyPlayStatus,
    ROOM_TYPE,
    RoomInfo,
    winnerModel,
    GroupInfo,
    emitAfterBetModel,
    requestBetModel,
    handHistoryModel,
    GamePlayer,
    userProfileModel,
    tournamentRankingModel,
    winnerListModel,
    CreateRingRoomInterface, CreateTournamentRoomInterface, SimpleTournamentUser, NoticeInfo, NoticeSimpleInfo,
    RingGameInfo
} from '../dataset/common'
import { client, SockClientOpt, OnRecvData } from "./index";
import { useEffect, useState, useLayoutEffect } from "react";
const hash = require('object-hash');

let listener:Function[] = [];
let ackListner:Function|null = null;

export const setAckListener = function(func:Function|null){
  ackListner = func;
}
export const addListener = function(func:Function){
  if(listener.indexOf(func) == -1){
    listener.push(func)
  }
  return func
}

export const removeListener = function(func:Function){
  let idx = listener.indexOf(func);
  if( idx >= 0){
    listener.splice(idx, 1);
  }
}

client.off("from:game:emitAfterBet");
client.on("from:game:emitAfterBet", async function(data:{ model: emitAfterBetModel; roomId: number; }, ack:Function){
  for(let f of listener){
    await f(data);
  }
  
  if(ackListner){
    let ret = await ackListner(data);
    await ack(ret)
  }else{
    await ack()
  }
})

export default function () : [{ model: emitAfterBetModel; roomId: number; } | null] {
  const [Data, setData] = useState<{ model: emitAfterBetModel; roomId: number; } | null>(null);

  useEffect(() => {
    const listener = async(data:{ model: emitAfterBetModel; roomId: number; })=>{
      setData(data);
    }

    client.on("from:game:emitAfterBet",listener)
    return () => {
      client.off("from:game:emitAfterBet",listener)
    };
  },[]);

  return [Data]
}